export const paymentcard = [
    { value: "0", label: "Cash" },
    { value: "1", label: "Card" },
    { value: "2", label: "Coupon" },
    { value: "3", label: "Redeem" },
    { value: "4", label: "Credit" },
    { value: "5", label: "Complementary" },
    { value: "6", label: "Multipayment" },
  ];
export const salestatus = [
    { value: "0", label: "Edited" },
    { value: "1", label: "Deleted" },
    { value: "2", label: "Added" },
  ];
export const editType = [
    { value: "0", label: "Running Order" },
    { value: "1", label: "Settled Order" },
  ];