import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AccGroup } from "../../core/json/AccGroup";
import {
  addAccountgroup,
  getAccountgroup,
} from "../../services/AccountApiServices";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

const AddGroup = ({ mode, data, handleClose, handleGroupList, GroupData }) => {
  const { userId } = useSelector((state) => state.userauth);

  const { register, handleSubmit, setValue, reset, setFocus } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      group: "",
    },
  });

  useEffect(() => {
    if (mode == "Edit") {
      setValue("name", data.name);
      setValue("group", data.parentGroupID);
    }
  }, [data]);
  useEffect(() => {
    const timeout = setTimeout(() => setFocus("name"), 0);
    return () => clearTimeout(timeout);
  }, []);
  const handleFormSubmit = async (formdata) => {
    const objgroup = new AccGroup();
    objgroup.id = 1;
    objgroup.guid = mode == "Edit" ? data.guid : "";
    objgroup.Name = formdata.name;
    objgroup.ParentGroupID = formdata.group;
    objgroup.ShowDetailsInFinalAccounts = false;
    objgroup.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}";

    try {
      const response = await addAccountgroup(objgroup);

      if (response.data == true) {
        toast.success("Success");
        handleGroupList();
        handleClose();
      } else if (response.data == false) {
        toast.error("false");
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message || "something went wrong");
      console.error("Error handling category:", error);
    }
  };

  return (
    <div>
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog  stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{mode} group</h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Group Name</label>
                          <input
                            type="text"
                            placeholder="Name"
                            {...register("name")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label> Under which group</label>
                          <select
                            {...register("group", { required: true })}
                            className="form-select"
                            required
                          >
                            <option value="" disabled>
                              Select Group
                            </option>
                            {GroupData?.map((category) => (
                              <option key={category.guid} value={category.guid}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="form-check">
  <input className="form-check-input" type="checkbox" value="" />
  <label className="form-check-label" >
    Show in Report
  </label>
</div> */}
                    </div>

                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => reset()}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit">
                          {mode == "Add" ? "Submit" : "Update"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddGroup;
