import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  editCustomer,
  addCustomer,
  getstates,
  getstatesByGuid,
} from "../../../services/MasterApiServices";
import { CustomerClass } from "../../../core/json/Customer";
import { CustomerReceiptType } from "../../../core/json/CustomerReceiptType";
// import {Country} from "../../../core/json/Country";
import { LedgerClass } from "../../../core/json/Ledger";
import { fetchCustomer } from "../../../redux/userSetting";

const CustomerModal = ({ mode, data, handleClose, handleRefresh }) => {
  // const { userId } =useSelector((state)=>state.userauth)
  const dispatch = useDispatch();
  //get branch from redux
  const { branch } = useSelector((state) => state.branchlist);

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  const [states, setStates] = useState([]);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  function resetmodal() {
    if (mode === "edit" && data) {
      // Populate form with data for editing
      setValue("name", data.name);
      setValue("fullName", data.fullName);
      setValue("mobile", data.mobile);
      setValue("email", data.email);
      setValue("address1", data.address1);
      setValue("address2", data.address2);
      setValue("address3", data.address3);
      setValue("discountPercentage", data.discountPercentage || 0.0);
      setValue("city", data.city);
      setValue("pin", data.pin);
      setValue("phone", data.phone);
      setValue("fax", data.fax);
      setValue("landMark", data.landMark);
      setValue("gstNo", data.gstNo);
      setValue("state", data.state.guid);
    } else {
      reset();
    }
  }

  useEffect(() => {
    resetmodal();
    fetchStates();
  }, [data, mode, setValue, branch]);

  // Fetch states on component mount
  const fetchStates = async () => {
    try {
      const response = await getstates();
      setStates(response);
    } catch (error) {
      console.error("Error fetching states:", error?.response?.data?.Message);
    }
  };

  const handleFormSubmit = async (formData) => {
    const objCustomer = new CustomerClass();
    const objCustRecptTYpe = new CustomerReceiptType();
    const objLedger = new LedgerClass();

    objCustomer.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}" //  userId
    objCustomer.name = formData.name;
    objCustomer.fullName = formData.fullName;
    objCustomer.mobile = formData.mobile;
    objCustomer.email = formData.email;
    objCustomer.address1 = formData.address1;
    objCustomer.address2 = formData.address2;
    objCustomer.address3 = formData.address3;
    // objCustomer.discountPercentage = parseFloat(formData.discountPercentage);
    objCustomer.discountPercentage = 0;
    objCustomer.city = formData.city;
    objCustomer.pin = formData.pin;
    objCustomer.phone = formData.phone;
    objCustomer.fax = formData.fax;
    objCustomer.landMark = formData.landMark;
    objCustomer.gstNo = formData.gstNo;

    //classes needed fields
    objCustomer.state = await getstatesByGuid(formData.state);

    objCustomer.customerReceiptType = objCustRecptTYpe;
    objCustomer.country = null;
    objCustomer.ledger = objLedger;

    objCustomer.branch = branch;
    objCustomer.guid = null;

    if (mode === "edit") {
      objCustomer.guid = data.guid;
      objCustomer.id = data.id;
    }

    try {
      const response =
        mode === "edit"
          ? await editCustomer(objCustomer)
          : await addCustomer(objCustomer);

      if (response) {
        toast.success("Success");
        dispatch(fetchCustomer(branch));
        handleRefresh(); // Refresh the list
        handleClose(); // Close the modal
      } else if (response == false) {
        toast.error("false");
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling:", error?.response?.data?.Message);
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {mode === "edit" ? "Edit Customer" : "Add Customer"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>TaxNo (VAT/GST)</label>
                          <input
                            type="text"
                            placeholder="gstNo"
                            {...register("gstNo")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Name"
                            {...register("name")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Full Name</label>
                          <input
                            type="text"
                            placeholder="Full Name"
                            {...register("fullName")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Mobile<span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="tel"
                            placeholder="1234567890"
                            {...register("mobile", {
                              pattern: {
                                value: /^\+?[1-9]\d{1,14}$/,
                                message: "Enter a valid phone number",
                              },
                            })}
                            required
                          />

                          {errors.mobile && (
                            <p className="text-danger">
                              {errors.mobile.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Email</label>
                          <input
                            type="email"
                            placeholder="Email"
                            {...register("email")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Address 1</label>
                          <input
                            type="text"
                            placeholder="Address"
                            {...register("address1")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Address 2</label>
                          <input
                            type="text"
                            placeholder="Address"
                            {...register("address2")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Address 3</label>
                          <input
                            type="text"
                            placeholder="Address"
                            {...register("address3")}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            State<span className="text-danger">*</span>
                          </label>
                          <select
                            {...register("state")}
                            defaultValue={data?.state?.guid || ""}
                            className=" form-control"
                            required
                          >
                            <option value="" disabled>
                              Select State
                            </option>
                            {states?.map((state) => (
                              <option key={state.guid} value={state.guid}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Discount Percentage</label>
                          <input
                            className="form-control"
                            type="type"
                            placeholder="0.00"
                            {...register("discountPercentage", {
                              min: {
                                value: 0,
                                message: "Enter a valid number",
                              },
                            })}
                          />
                          {errors.discountPercentage && (
                            <p className="text-danger">
                              {errors.discountPercentage.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>City</label>
                          <input
                            type="text"
                            placeholder="City"
                            {...register("city")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Pin</label>
                          <input
                            type="text"
                            placeholder="Pin"
                            {...register("pin")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Phone</label>
                          <input
                            type="text"
                            placeholder="Phone"
                            {...register("phone")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Fax</label>
                          <input
                            type="text"
                            placeholder="Fax"
                            {...register("fax")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Landmark</label>
                          <input
                            type="text"
                            placeholder="Landmark"
                            {...register("landMark")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="modal-footer-btn">
                          <button
                            type="button"
                            className="btn btn-cancel me-2"
                            onClick={() => resetmodal()}
                          >
                            Reset
                          </button>
                          <button type="submit" className="btn btn-submit">
                            {mode === "edit" ? "Save Changes" : "Save"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerModal;
