import { Table } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  AddProductBOQ,
  getProductBOQIngredients,
} from "../../../services/MasterApiServices";
import { ProductBOQ } from "../../../core/json/ProductBOQ";
import { ProductBOQDetail } from "../../../core/json/ProductBOQDetail";
import toast from "react-hot-toast";
import { map } from "jquery";
import dayjs from "dayjs";
import { id } from "date-fns/locale";
import { ProductClass } from "../../../core/json/Product";

const ProductBOQmodal = ({ data, productboq }) => {
  const [selectedCostType, setSelectedCostType] = useState("LastNetCost");
  const [productionQuantity, setProductionQuantity] = useState(1);
  const [ingredients, setIngredients] = useState([]);
  const { deliveyTypes, unitList, productList } = useSelector(
    (state) => state.usersetting
  );
  const [isRowAdded, setIsRowAdded] = useState(false);
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    control,
    setFocus,
    formState: { errors },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "row",
  });

  // if (!isRowAdded && fields?.length === 0) {
  //   addRow();
  //   setIsRowAdded(true);
  // }

  useEffect(() => {
    // Clear all fields at once
    remove();

    addRow();
    setIsRowAdded(true);

    if (productboq?.productBOQDetails?.length > 0) {
      // Map the productBOQDetails if available
      const boq = productboq.productBOQDetails?.map((e) => {
        let total =
          e.quantity * e.conversionFactor * e.cost +
          (e.quantity * e.conversionFactor * e.cost * e.wastagePercentage) /
            100;

        // Return the object properly
        return {
          id: e.id,
          product: e.product.guid,
          unit: e.unit.guid,
          quantity: e.quantity,
          wastage: e.wastagePercentage,
          Cfactor: e.conversionFactor,
          RndTo: false, // Assuming this flag is set to false by default
          cost: e.cost,
          tcost: total?.toFixed(2), // Rounds total cost to 2 decimal places
        };
      });

      append(boq);
      setValue("row", boq);
    } else {
      if (!isRowAdded && fields?.length === 0) {
        addRow();
        setIsRowAdded(true);
      }
    }

    setValue("guid", productboq ? productboq.guid : "");
    setValue("id", productboq ? productboq.id : 0);
    setProductionQuantity(productboq ? productboq.totalqty : 1);
    setSelectedCostType(
      productboq?.averageCost == 0 ? "LastNetCost" : "AverageCost"
    );
  }, [productboq, append, remove]);
  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };

  const handlesave = async (formData) => {
    const objproduct = new ProductBOQ();
    const objproductclass = new ProductClass();
    objproductclass.guid = data.guid;
    objproductclass.name = data.name;
    objproductclass.id = data.id;
    objproduct.Product = objproductclass;
    objproduct.Branch = branch;
    objproduct.Totalqty = Number(productionQuantity);
    objproduct.guid = formData.guid;
    objproduct.id = Number(formData.id);
    objproduct.BoqDate = dayjs().format("YYYY-MM-DD");
    objproduct.AverageCost = selectedCostType == "AverageCost" ? 1 : 0;
    objproduct.LastNetCost = selectedCostType == "LastNetCost" ? 1 : 0;
    objproduct.updatedUser = `{${userId?.id}}`;
    objproduct.createdUser = `{${userId?.id}}`;

    for (const row of formData.row) {
      const objproductdetail = new ProductBOQDetail();

      objproductdetail.Type = null;
      objproductdetail.Product = productList?.find(
        (e) => e.guid == row.product
      );
      objproductdetail.Unit = unitList?.find((e) => e.guid == row.unit);
      objproductdetail.Quantity = Number(row.quantity);
      objproductdetail.WastagePercentage = Number(row.wastage);
      objproductdetail.ConversionFactor = Number(row.Cfactor);
      objproductdetail.RoundToNextHigher = row.RndTo;
      objproductdetail.Cost = Number(row.cost);
      objproductdetail.TotalCost = Number(row.tcost);

      objproduct.ProductBOQDetails.push(objproductdetail);
    }

    try {
      const response = await AddProductBOQ(objproduct);
      if (response == true) {
        // fields.forEach((_, index) => remove(index))
        toast.success("success");
        closemodal();
      } else {
        toast.success(response.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling category:", error?.response?.data?.Message);
    }
  };

  const addRow = () => {
    append({
      id: "",
      product: "", // Empty product
      unit: "", // Empty unit
      quantity: 0,
      wastage: 0,
      Cfactor: 1,
      RndTo: false,
      cost: 0,
      tcost: 0,
    });
  };

  useEffect(() => {
    handleIngredients();
  }, [branch]);

  const handleIngredients = async () => {
    try {
      const response = await getProductBOQIngredients(branch);
      if (Array.isArray(response)) {
        setIngredients(response);
      } else {
        setIngredients([]); // If response is not an array, reset to an empty array
      }
    } catch (error) {
      setIngredients([]);
    }
  };
  const handleproductChange = (val, index) => {
    setValue(`row[${index}].product`, val.target.value);

    const prod = productList?.find((obj) => obj.guid == val.target.value);

    setValue(`row[${index}].unit`, prod?.unit?.guid);
    setValue(`row[${index}].cost`, prod?.cost);
    handlecost(index);
  };
  const handlecost = (index) => {
    const rowValues = getValues(`row[${index}]`);
    const { quantity = 0, Cfactor = 0, cost = 0, wastage = 0 } = rowValues; // Default to 0 if undefined or null

    if (quantity && Cfactor && cost) {
      const productcost = quantity * Cfactor * cost; // calculate product cost

      if (wastage) {
        const total = productcost + (productcost * wastage) / 100; // include wastage if available

        setValue(`row[${index}].tcost`, total?.toFixed(2)); // set total cost
      } else {
        setValue(`row[${index}].tcost`, productcost?.toFixed(2)); // if no wastage, set product cost
      }
    }
  };
  function handleRemove(index) {
    if (fields.length > 1) {
      remove(index);
    } else {
      toast.error("At least one row is required to save.");
    }
  }

  return (
    <div>
      <div className="modal fade" id="add-boq">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title"></div>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                    // onClick={()=> fields.forEach((_, index) => remove(index))}
                    ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <h4>{data?.name}</h4>
                  <form onSubmit={handleSubmit(handlesave)}>
                    <div className="float-right">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="costType"
                          id="averageCost"
                          value="AverageCost"
                          checked={selectedCostType === "AverageCost"}
                          onChange={(e) => setSelectedCostType(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="averageCost"
                        >
                          Average Cost
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="costType"
                          id="lastNetCost"
                          value="LastNetCost"
                          checked={selectedCostType === "LastNetCost"}
                          onChange={(e) => setSelectedCostType(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="lastNetCost"
                        >
                          Last Net Cost
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label htmlFor="productionQuantity">
                            Production Quantity :
                          </label>
                          <input
                            className="form-control w-50"
                            type="number"
                            min={1}
                            step="any"
                            id="productionQuantity"
                            value={productionQuantity}
                            placeholder="1"
                            onChange={(e) =>
                              setProductionQuantity(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* Table */}
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Unit</th>
                          <th>Quantity</th>
                          <th>Wastage %</th>
                          <th>C.factor</th>
                          {/* <th>Rnd.To.Next</th> */}
                          <th>Cost</th>
                          <th>T.cost</th>
                          <th>Actions</th> {/* Add actions column */}
                        </tr>
                      </thead>
                      <tbody>
                        {fields?.map((row, index) => (
                          <tr key={row.id}>
                            <td>
                              <Controller
                                control={control}
                                name={`row[${index}].product`}
                                rules={{
                                  required: true,
                                  validate: (value) => {
                                    const selectedProducts = watch("row")?.map(
                                      (row) => row.product
                                    ); // Get all selected products
                                    const isDuplicate =
                                      selectedProducts?.filter(
                                        (prod) => prod === value
                                      )?.length > 1;
                                    return (
                                      !isDuplicate ||
                                      "Duplicate product selected!"
                                    );
                                  },
                                }}
                                render={({ field }) => (
                                  <>
                                    <select
                                      {...field}
                                      className="form-control"
                                      onChange={(val) => {
                                        field.onChange(val); // ensure the field value is updated
                                        handleproductChange(val, index); // handle custom logic
                                      }}
                                    >
                                      <option value="">Select product</option>
                                      {ingredients?.map((product) => (
                                        <option
                                          key={product.guid}
                                          value={product.guid}
                                        >
                                          {product.name}
                                        </option>
                                      ))}
                                    </select>

                                    {/* Display error message for required validation */}
                                    {errors?.row?.[index]?.product?.type ===
                                      "required" && (
                                      <span className="text-danger">
                                        Product is required
                                      </span>
                                    )}

                                    {/* Display error message for duplicate validation */}
                                    {errors?.row?.[index]?.product?.message && (
                                      <span className="text-danger">
                                        {errors.row[index].product.message}
                                      </span>
                                    )}
                                  </>
                                )}
                              />
                            </td>
                            <td>
                              <Controller
                                control={control}
                                name={`row[${index}].unit`}
                                render={({ field }) => (
                                  <select
                                    {...field}
                                    className="form-control"
                                    disabled
                                  >
                                    <option value="">Select unit</option>
                                    {unitList &&
                                      unitList?.map((unit) => (
                                        <option
                                          key={unit.guid}
                                          value={unit.guid}
                                        >
                                          {unit.name}
                                        </option>
                                      ))}
                                  </select>
                                )}
                              />
                            </td>

                            <td>
                              <input
                                type="number"
                                min={0}
                                maxLength={7}
                                step="any"
                                className="form-control"
                                onChange={(e) => {
                                  setValue(
                                    `row[${index}].quantity`,
                                    e.target.value
                                  ); // Update the value
                                  handlecost(index); // Trigger the cost calculation
                                }}
                                value={watch(`row[${index}].quantity`) || ""} // Bind the value with watch
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                min={0}
                                max={100}
                                step="any"
                                maxLength={3}
                                className="form-control"
                                onChange={(e) => {
                                  setValue(
                                    `row[${index}].wastage`,
                                    e.target.value
                                  ); // Update the value
                                  handlecost(index); // Trigger the cost calculation
                                }}
                                value={watch(`row[${index}].wastage`) || ""} // Bind the value with watch
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                min={0}
                                className="form-control"
                                maxLength={7}
                                step="any"
                                onChange={(e) => {
                                  setValue(
                                    `row[${index}].Cfactor`,
                                    e.target.value
                                  ); // Update the value
                                  handlecost(index); // Trigger the cost calculation
                                }}
                                value={watch(`row[${index}].Cfactor`) || ""} // Bind the value with watch
                              />
                            </td>
                            {/* <td>
                              <input
                                type="checkbox"
                                {...register(`row[${index}].RndTo`)}

                              />
                            </td> */}
                            <td>
                              <input
                                type="number"
                                min={0}
                                step="any"
                                className="form-control"
                                {...register(`row[${index}].cost`)}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                min={0}
                                step="any"
                                className="form-control"
                                {...register(`row[${index}].tcost`)}
                                disabled
                              />
                            </td>
                            <td>
                              <Link
                                className="confirm-text p-2"
                                onClick={() => handleRemove(index)}
                              >
                                <i
                                  data-feather="trash-2"
                                  className="feather-trash-2"
                                ></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="col-lg-2 float-right">
                      {watch()?.row && (
                        <>
                          {/* Calculate the total sum of tcost across all rows */}
                          {(() => {
                            let totalSum = watch().row.reduce(
                              (acc, e) =>
                                parseFloat(acc) + parseFloat(e.tcost || 0),
                              0
                            );
                            return (
                              <div className="col-12">
                                {/* Render the total sum */}
                                <p>
                                  Total cost: <b>{totalSum}</b>{" "}
                                </p>
                              </div>
                            );
                          })()}
                        </>
                      )}
                    </div>

                    <div className="col-lg-2">
                      <div className="input-blocks">
                        <button
                          className="btn btn-submit add-sale mt-3"
                          type="button"
                          onClick={addRow}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button type="submit" className="btn btn-save">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductBOQmodal;
