export class DatatablePagingParameter {
  constructor() {
    this.PageNumber = 1;
    this.PageSize = 20;
    this.SearchValue = null;
    // this.SortingColumn = '[TransactionDate]';
    this.SortingColumn = null;
    this.SortingDirection = "";
  }
}

export class Filterclass {
  constructor() {
    this.FromDate = null;
    this.ToDate = null;
    this.Section = "";
    this.Counter = "";
    this.PaymentType = "";
    this.PaymentCardType = "";
    this.DayClosed = false;
    this.User = "";
    this.SaleStatus = "";
    this.ItemName = "";
    this.ShiftNumber = "";
    this.Waiter = "";
    this.IsLiveSale = false;
    this.Product = "";
    this.Category = "";
    this.Group = "";
    this.Customer = null;
    this.AccountType = "";
    this.Type = "";
    this.SalesAuditStatus = "";
    this.EditType = "";
    this.Branch = null;
  }
}
export class AccountFilterclass {
  constructor() {
    this.FromDate = null;
    this.ToDate = null;
    this.Section = "";
    this.Counter = "";
    this.PaymentType = "";
    this.PaymentCardType = "";
    this.DayClosed = false;
    this.User = "";
    this.SaleStatus = "";
    this.ItemName = "";
    this.ShiftNumber = "";
    this.Waiter = "";
    this.IsLiveSale = false;
    this.Product = "";
    this.Category = "";
    this.Group = "";
    this.Customer = "";
    this.AccountType = "";
    this.Type = "";
    this.SalesAuditStatus = "";
    this.EditType = "";
    this.Branch = null;
    this.LedgerID = "00000000-0000-0000-0000-000000000000";
    this.FinancialYearID = 1;
  }
}
export class TrialBalanceFilterclass {
  constructor() {
    this.FromDate = null;
    this.ToDate = null;
    this.Section = null;
    this.Counter = null;
    this.PaymentType = null;
    this.PaymentCardType = null;
    this.DayClosed = 0;
    this.User = null;
    this.SaleStatus = null;
    this.ItemName = null;
    this.ShiftNumber = null;
    this.Waiter = null;
    this.IsLiveSale = 0;
    this.Product = null;
    this.Category = null;
    this.Group = null;
    this.Customer = null;
    this.AccountType = null;
    this.Type = null;
    this.SalesAuditStatus = null;
    this.EditType = null;
    this.Branch = null;
    (this.LedgerID = "00000000-0000-0000-0000-000000000000"),
      (this.FinancialYearID = 1);
  }
}
