import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  RotateCcw,
  ChevronUp,
  PlusCircle,
  RefreshCw,
  Calendar,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import { DatePicker, Pagination, Select, Tabs } from "antd";
import { OverlayTrigger, TabPane, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import { renderTooltip } from "../../redux/helper";
import {
  printReport,
  exportExcel,
  exportPDF,
} from "../../core/utils/reportUtils";
import {
  deleteReceipt,
  getAccountledger,
  getcashorbank,
  getReceiptByGuid,
} from "../../services/AccountApiServices";
import { PulseLoader } from "react-spinners";
import dayjs from "dayjs";
import Receipt from "../../components/accounting/RecieptModal";
import { ReceiptEntry } from "../../core/json/ReceiptEntry";

function ReceiptMaster() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [receiptData, setReceiptData] = useState();
  const [itemwiseData, setitemwiseData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredItemData, setFilteredItemData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [selectedLedger, setSelectedLedger] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const [updateData, setUpdateData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [ledgerData, setLedgerData] = useState(null);
  const [modalMode, setModalMode] = useState("add");
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [permit, setpermit] = useState();
  const { menuItems } = useSelector((state) => state.amc);
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  useEffect(() => {
    handleLedgerList();
    setpermit(
      menuItems?.menuPermissions?.find((e) => e.menu.name == "Receipt")
    );
    handleReceiptList();
  }, []);
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const modifiedList = Array.isArray(receiptData)
    ? receiptData?.filter((value) =>
        value.name?.toLowerCase().includes(searchText?.toLowerCase())
      )
    : [];

  const handleTabChange = (key) => {
    setActiveTab(key);
    console.log(key, "activeTab");
    if (key == 4) {
      setIsFilterVisible(false);
    } else {
      setIsFilterVisible(true);
    }
  };

  const handleReceiptList = async () => {
    setLoading(true);
    try {
      const response = await getReceiptByGuid(branch);

      if (Array.isArray(response)) {
        setReceiptData(response);
        setFilteredData(response);
        const allItemwiseData = response.flatMap((invoice) =>
          itemwiseDataFn(invoice)
        );

        setitemwiseData(allItemwiseData);
        setFilteredItemData(allItemwiseData);
        setLoading(false);
      } else {
        setReceiptData([]);
        setLoading(false); // If response is not an array, reset to an empty array
      }
    } catch (error) {
      console.log(error?.response?.data?.Message || "something went wrong");
      setReceiptData([]);
      setLoading(false);
    }
  };

  const handleLedgerList = async () => {
    try {
      const response = await getcashorbank();
      if (Array.isArray(response?.data)) {
        setLedgerData(
          response?.data?.map((e) => ({
            value: e.guid,
            label: e.name,
          }))
        );
      } else {
        setLedgerData([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message || "Something went wrong");
      setLedgerData([]);
    }
  };

  const handleEditClick = async (guid) => {
    try {
      const ReceiptByGuid = receiptData.find((data) => data.guid === guid);

      setUpdateData(ReceiptByGuid); // Set the data for editing
      setModalMode("edit");

      setModalOpen(true);
    } catch (error) {
      console.error(
        "Error fetching modifier reason type by GUID:",
        error?.response?.data?.Message
      );
    }
  };
  const handleDeleteClick = (obj) => {
    const objpayment = new ReceiptEntry();
    objpayment.Branch = branch;
    objpayment.guid = obj.guid;
    objpayment.id = obj.id;
    objpayment.updatedUser = userId?.id;
    objpayment.createdUser = userId?.id;
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteReceipt(objpayment);
          if (response.data == true) {
            MySwal.fire({
              title: "Deleted!",
              text: "The receipt has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handleReceiptList();
              setSearchText("");
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting sales quotation:", error);
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
  useEffect(() => {
    handleFilter();
  }, [searchText]);

  function handleFilter() {
    console.log(receiptData, "receiptData");
    console.log(new Date(fromDate), "new Date(fromDate)");
    console.log(new Date(toDate), "new Date(toDate)");

    const filteredData = receiptData?.filter((entry) => {
      const ledgerMatch = !selectedLedger || entry.ledgerID === selectedLedger;

      const fromDateMatch =
        !fromDate || new Date(entry.date) >= new Date(fromDate);
      const toDateMatch = !toDate || new Date(entry.date) <= new Date(toDate);
      if (fromDateMatch) {
        console.log("----------------inside formdate match");
        if (new Date(entry.date) >= new Date(fromDate)) {
          console.log(new Date(entry.date), "matched date");
          console.log(new Date(fromDate), "FROM date");
        }
      }
      if (toDateMatch) {
        console.log("-inside todate match----------------------");
        if (new Date(entry.date) <= new Date(toDate)) {
          console.log(new Date(entry.date), "matched entry date");
          console.log(new Date(toDate), "to date");
        }
      }
      const searchTextL = searchText?.toLowerCase() || "";
      const searchTextMatch =
        dayjs(entry?.date)?.format("YYYY-MM-DD")?.includes(searchTextL) ||
        entry?.no?.toString().includes(searchTextL) ||
        entry?.ledgerName?.toLowerCase().includes(searchTextL) ||
        entry?.narration?.toString().includes(searchTextL) ||
        entry?.totalAmt?.toString().includes(searchTextL);

      return fromDateMatch && toDateMatch && searchTextMatch && ledgerMatch;
    });

    setFilteredData(filteredData);

    handleFilteritem();
  }
  function handleFilteritem() {
    const filteredData = itemwiseData?.filter((entry) => {
      const ledgerMatch = !selectedLedger || entry.ledgerID === selectedLedger;
      const fromDateMatch =
        !fromDate || new Date(entry.date) >= new Date(fromDate);
      const toDateMatch = !toDate || new Date(entry.date) <= new Date(toDate);
      const searchTextL = searchText?.toLowerCase() || "";

      const searchTextMatch =
        dayjs(entry?.date)?.format("YYYY-MM-DD")?.includes(searchTextL) ||
        entry?.no?.toString().includes(searchTextL) ||
        entry?.ledgerName?.toLowerCase().includes(searchTextL) ||
        entry?.ledger?.toLowerCase().includes(searchTextL) ||
        entry?.amount?.toString().includes(searchTextL);

      return fromDateMatch && toDateMatch && searchTextMatch && ledgerMatch;
    });

    setFilteredItemData(filteredData);
  }

  //--------------------------------
  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create a column mapping (column title to dataIndex)
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map column title to its dataIndex
      });
      return mappings;
    };
    switch (activeTab) {
      case "1":
        fieldMapping = createfieldMapping(columns);

        return {
          data: filteredData,
          columns: columns,
          fieldMapping,
          filename: "Receipt Report",
        };
      case "2":
        fieldMapping = createfieldMapping(Summarycolumns);

        return {
          data: filteredData,
          columns: Summarycolumns,
          fieldMapping,
          filename: "Receipt Summary Report",
        };
      case "3":
        fieldMapping = createfieldMapping(DetailedColumns);

        return {
          data: filteredItemData,
          columns: DetailedColumns,
          fieldMapping,
          filename: "Receipt Detailed Report",
        };
      default:
        return { data: [], columns: [], filename: [] };
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      defaultSortOrder: "descend",
      render: (date) => dayjs(date)?.format("DD-MM-YY"),
    },
    {
      title: "Cash/Bank",
      dataIndex: "ledgerName",
      sorter: (a, b) => a.ledgerName.localeCompare(b.ledgerName),
    },
    {
      title: "Remarks",
      dataIndex: "narration",
      sorter: (a, b) => a.narration.localeCompare(b.narration),
    },
    {
      title: "Amount",
      dataIndex: "totalAmt",
      sorter: (a, b) => a.totalAmt - b.totalAmt,
    },
    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                  {permit?.edit && (
                    <Link
                      className="me-2 p-2"
                      to="#"
                      onClick={() => handleEditClick(obj.guid)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                  {permit?.deletion && (
                    <Link
                      className="confirm-text p-2"
                      to="#"
                      onClick={() => handleDeleteClick(obj)}
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];

  const handleFromDateChange = (date) => {
    setFromDate(date);
    setToDate(null);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleLedgerChange = (selectedOption) => {
    setSelectedLedger(selectedOption);
  };
  const Summarycolumns = [
    {
      title: "No",
      dataIndex: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      defaultSortOrder: "descend",
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Cash/Bank",
      dataIndex: "ledgerName",
      sorter: (a, b) => a.ledgerName.localeCompare(b.ledgerName),
    },
    {
      title: "Remark",
      dataIndex: "narration",
      sorter: (a, b) => a.narration?.length - b.narration?.length,
    },
    {
      title: "Total",
      dataIndex: "totalAmt",
      sorter: (a, b) => a.grossTotal - b.grossTotal,
    },
  ];
  const DetailedColumns = [
    {
      title: " No",
      dataIndex: "no",
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      defaultSortOrder: "descend",
      render: (date) => dayjs(date)?.format("DD-MM-YY"),
    },
    {
      title: "Cash/Bank",
      dataIndex: "ledgerName",
      sorter: (a, b) => a.ledgerName?.length - b.ledgerName?.length,
    },

    {
      title: "Ledger",
      dataIndex: "ledger",
      sorter: (a, b) => a.ledger?.name?.localeCompare(b.ledger?.name),
    },
    {
      title: "Total",
      dataIndex: "amount",
      sorter: (a, b) => a.invoiceNo?.length - b.invoiceNo?.length,
    },
  ];
  const itemwiseDataFn = (invoice) => {
    return invoice.receiptDetails?.map((item, index) => {
      return {
        no: invoice.no,
        date: invoice.date,
        ledgerName: invoice.ledgerName,
        ledger: item.ledgerName || "Unknown ledger",
        amount: item.amount,
        ledgerID: item.ledgerID,
      };
    });
  };
  function handleReset() {
    setFromDate(null);
    setToDate(null);
    setSelectedLedger(null);
    setSearchText("");
    setFilteredData(receiptData);
    setFilteredItemData(itemwiseData);
  }

  const MySwal = withReactContent(Swal);
  return (
    <div>
      <div>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header transfer">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4>Receipt</h4>
                  <h6>Manage Receipt Type here</h6>
                </div>
              </div>
              <ul className="table-top-head">
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("PDF")}
                  >
                    <Link
                      onClick={() =>
                        exportPDF(getActiveTabData, "Receipt Master")
                      }
                    >
                      <ImageWithBasePath
                        src="assets/img/icons/pdf.svg"
                        alt="img"
                      />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Excel")}
                  >
                    <Link
                      onClick={() =>
                        exportExcel(getActiveTabData, "Receipt Master")
                      }
                    >
                      <ImageWithBasePath
                        src="assets/img/icons/excel.svg"
                        alt="img"
                      />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Print")}
                  >
                    <Link
                      onClick={() =>
                        printReport(getActiveTabData, "Receipt Master")
                      }
                    >
                      <i data-feather="printer" className="feather-printer" />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Refresh")}
                  >
                    <Link
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      onClick={() => handleReceiptList()}
                    >
                      <RotateCcw />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Collapse")}
                  >
                    <Link
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      id="collapse-header"
                      className={togglehead ? "active" : ""}
                      onClick={handletogglehead}
                    >
                      <ChevronUp />
                    </Link>
                  </OverlayTrigger>
                </li>
              </ul>
              {permit?.add && (
                <div className="page-btn">
                  <Link
                    to="#"
                    className="btn btn-added"
                    onClick={() => {
                      setModalMode("add"); // Set the modal mode to 'add'
                      setModalOpen(true); // Open the modal
                    }}
                  >
                    <PlusCircle className="me-2" />
                    New
                  </Link>
                </div>
              )}
            </div>
            {/* /product list */}
            <div className="card table-list-card">
              <div className="card-body">
                <div className="d-flex flex-wrap align-items-center gap-3 m-2">
                  {/* Search Input */}
                  <div className="flex-grow-1">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm"
                      value={searchText}
                      onChange={handleSearch}
                      style={{ height: "38px" }}
                    />
                  </div>

                  {/* From Date */}
                  <div className="flex-grow-1">
                    <div className="input-blocks ms-4">
                      <label>From Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                          style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                        />
                      </div>
                    </div>
                  </div>

                  {/* To Date */}
                  <div className="flex-grow-1">
                    <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon calender-input">
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate}
                          dateFormat="dd/MM/yyyy"
                          style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                        />
                      </div>
                    </div>
                  </div>

                  {/* Product Dropdown */}
                  <div className="flex-grow-1">
                    <div className="input-blocks">
                      <label>
                        {" "}
                        {activeTab === "3" ? "Ledger" : "Cash/Bank"}
                      </label>

                      <div className="input-groupicon ">
                        <Select
                          className="img-select"
                          options={ledgerData}
                          onChange={handleLedgerChange}
                          allowClear
                          value={selectedLedger}
                          classNamePrefix="react-select"
                          placeholder="Select "
                          style={{ height: "38px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Supplier Dropdown */}

                  {/* Buttons */}
                  <div className="d-flex gap-2">
                    <button className="btn btn-primary" onClick={handleFilter}>
                      Search
                    </button>
                    <button className="btn btn-secondary" onClick={handleReset}>
                      Reset
                    </button>
                  </div>
                </div>
                {/* <div className="table-top">
                  <div className="search-set">
                    <div className="search-input">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control form-control-sm me-2 formsearch "
                        aria-controls="DataTables_Table_0"
                        value={searchText}
                        onChange={handleSearch}
                      />
                      <Link to className="btn btn-searchset btn-sm rounded-2">
                        <i data-feather="search" className="feather-search" />
                      </Link>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    {/* {isFilterVisible && ( */}
                {/* <div className="input-blocks me-2">
                      <button
                        className="btn btn-filters btn-sm rounded-2 h-100"
                        onClick={handleFilter}
                      >
                        <i data-feather="search" className="feather-search" />{" "}
                        Search
                      </button>
                    </div>
                    )} */}

                {/* <div className="input-blocks">
                      <button
                        className="btn btn-reset  rounded-2 "
                        onClick={handleReset}
                      >
                        <i
                          data-feather="refresh-cw"
                          className="feather-refresh-cw"
                        />{" "}
                        Reset
                      </button>
                    </div>
                  </div>
                </div> */}

                {/* <div className="card-body">
                  <div className="row">
                    {/* From Date Filter */}
                {/* <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks ms-4">
                        <label>From Date</label>
                        <div className="input-groupicon ">
                          <Calendar className="info-img" />
                          <DatePicker
                            className="datetimepicker"
                            id="from-date"
                            placeholderText="From Date"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            startDate={fromDate}
                            selectsStart
                            dateFormat="dd/MM/yyyy"
                            style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                          />
                        </div>
                      </div>
                    </div>  */}

                {/* To Date Filter */}
                {/* <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>To Date</label>
                        <div className="input-groupicon ">
                          <Calendar className="info-img" />
                          <DatePicker
                            id="to-date"
                            placeholderText="To Date"
                            value={toDate}
                            onChange={handleToDateChange}
                            minDate={fromDate}
                            startDate={fromDate}
                            selectsEnd
                            disabled={!fromDate}
                            dateFormat="dd/MM/yyyy"
                            style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                          />
                        </div>
                      </div>
                    </div> */}

                {/* <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>
                          {" "}
                          {activeTab === "3" ? "Ledger" : "Cash/Bank"}
                        </label>

                        <div className="input-groupicon ">
                          <Select
                            className="img-select"
                            options={ledgerData}
                            onChange={handleLedgerChange}
                            allowClear
                            value={selectedLedger}
                            classNamePrefix="react-select"
                            placeholder="Select "
                            style={{ height: "38px", width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="table-responsive">
                  {loading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "50vh" }}
                    >
                      <PulseLoader
                        color="#ff9e42"
                        size={12}
                        speedMultiplier={1}
                      />
                    </div>
                  ) : (
                    <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                      <TabPane tab="Receipt" key="1">
                        <Table columns={columns} dataSource={filteredData} />
                      </TabPane>
                      <TabPane tab="Summary Reports" key="2">
                        <Table
                          columns={Summarycolumns}
                          dataSource={filteredData}
                        />
                      </TabPane>
                      <TabPane tab="Detailed Reports" key="3">
                        <Table
                          columns={DetailedColumns}
                          dataSource={filteredItemData}
                        />
                      </TabPane>
                    </Tabs>
                  )}
                </div>
              </div>
            </div>
            {/* /product list */}
          </div>
        </div>
        <Pagination />
        {modalOpen && (
          <Receipt
            mode={modalMode}
            data={modalMode === "edit" ? updateData : {}}
            handleClose={() => setModalOpen(false)}
            handleRefresh={() => {
              handleReceiptList();
              setSearchText("");
            }}
          />
        )}
        {/* <Reciept /> */}
      </div>
    </div>
  );
}

export default ReceiptMaster;
