import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

const generateTableHeaders = (columns) => {
  if (!Array.isArray(columns)) return [];
  const headers = [];

  columns.forEach((col) => {
    if (col?.children?.length > 0) {
      col.children.forEach((child) => {
        headers.push(`${col.title} - ${child.title}`);
      });
    } else if (col?.title) {
      headers.push(col.title);
    }
  });

  return headers;
};

// Utility function to generate table body rows
const generateTableBody = (data, tableHeaders, fieldMapping) => {
  if (!Array.isArray(data)) return [];
  return data.map((item) => {
    return tableHeaders.map((header) => {
      let value = "";

      if (header.includes(" - Quantity") || header.includes(" - Total")) {
        const [branch, subField] = header.split(" - ");
        const field = subField === "Quantity" ? "netQuantity" : "netTotal";
        value =
          item?.[branch]?.[field] !== undefined
            ? item[branch][field].toFixed(2)
            : "0.00";
      } else {
        const field = fieldMapping?.[header];
        value = field?.includes(".")
          ? field.split(".").reduce((obj, key) => obj?.[key], item)
          : item?.[field] ?? "";

        if (header.toLowerCase()?.includes("date") && value) {
          value = dayjs(value).format("DD-MM-YY");
        } else if (field === "type") {
          switch (value) {
            case 0:
              value = "Stock Adjustment";
              break;
            case 1:
              value = "Damage";
              break;
            case 2:
              value = "Wastage";
              break;
            default:
              value = "Unknown";
          }
        }
      }

      return value;
    });
  });
};

// Error-safe data fetching utility
const safeData = (getterFn, fallback = {}) => {
  try {
    return getterFn();
  } catch (error) {
    console.error("Data fetching error:", error);
    return fallback;
  }
};

// Excel Export
export const exportnestedExcel = (getActiveTabData) => {
  const { data, columns, fieldMapping } = safeData(getActiveTabData, {
    data: [],
    columns: [],
    fieldMapping: {},
  });

  const tableHeaders = generateTableHeaders(columns);
  const tableBody = generateTableBody(data, tableHeaders, fieldMapping);

  // Calculate footer totals dynamically
  const footerTotals = tableHeaders.map((header, index) => {
    if (header.includes("Quantity") || header.includes("Total")) {
      const sum = tableBody.reduce((acc, row) => {
        const value = parseFloat(row[index]);
        return acc + (isNaN(value) ? 0 : value);
      }, 0);
      return sum.toFixed(2);
    }
    return ""; // Leave non-numeric columns blank
  });

  // Create worksheet
  const worksheet = XLSX.utils.aoa_to_sheet([
    ["Itemwise Quantity Report"], // Add title as the first row
    tableHeaders,
    ...tableBody,
    footerTotals, // Add footer totals as the last row
  ]);

  // Create and save workbook
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

  XLSX.writeFile(workbook, "ItemwiseQuantityReport.xlsx");
};

// Printable Format
export const printnestedReport = (getActiveTabData) => {
  const { data, columns, fieldMapping } = safeData(getActiveTabData, {
    data: [],
    columns: [],
    fieldMapping: {},
  });

  const printWindow = window.open("", "_blank");
  const tableHeaders = generateTableHeaders(columns);
  const tableBody = generateTableBody(data, tableHeaders, fieldMapping);

  // Dynamically calculate footer totals
  const footerTotals = tableHeaders.map((header, index) => {
    if (header.includes("Quantity") || header.includes("Total")) {
      const sum = tableBody.reduce((acc, row) => {
        const value = parseFloat(row[index]);
        return acc + (isNaN(value) ? 0 : value);
      }, 0);
      return sum.toFixed(2);
    }
    return ""; // Leave non-numeric columns blank
  });

  printWindow.document.write("<html><head><title>Print Report</title>");
  printWindow.document.write(`
    <style>
      table { width: 100%; border-collapse: collapse; }
      th, td { border: 1px solid black; padding: 8px; text-align: left; }
      th { background-color: #f2f2f2; }
    </style></head><body>
  `);
  printWindow.document.write("<h1>Itemwise Quantity Report</h1>"); // Add title

  let tableHTML = "<table><thead><tr>";
  tableHeaders.forEach((header) => {
    tableHTML += `<th>${header}</th>`;
  });
  tableHTML += "</tr></thead><tbody>";

  tableBody.forEach((row) => {
    tableHTML += "<tr>";
    row.forEach((cell) => {
      tableHTML += `<td>${cell ?? ""}</td>`;
    });
    tableHTML += "</tr>";
  });

  // Add footer totals
  tableHTML += "<tfoot><tr>";
  footerTotals.forEach((footer) => {
    tableHTML += `<td>${footer}</td>`;
  });
  tableHTML += "</tr></tfoot>";

  tableHTML += "</tbody></table>";
  printWindow.document.write(tableHTML);
  printWindow.document.write("</body></html>");

  printWindow.document.close();
  printWindow.print();
};

// PDF Export
export const exportnestedPDF = (getActiveTabData) => {
  const { data, columns, fieldMapping } = safeData(getActiveTabData, {
    data: [],
    columns: [],
    fieldMapping: {},
  });

  const orientation = columns?.length > 9 ? "landscape" : "portrait";
  const doc = new jsPDF({ orientation });

  doc.text("Itemwise Quantity Report", 10, 10); // Add title to PDF

  const tableHeaders = generateTableHeaders(columns);
  const tableBody = generateTableBody(data, tableHeaders, fieldMapping);

  // Calculate footer totals dynamically
  const footerTotals = tableHeaders.map((header, index) => {
    if (header.includes("Quantity") || header.includes("Total")) {
      const sum = tableBody.reduce((acc, row) => {
        const value = parseFloat(row[index]);
        return acc + (isNaN(value) ? 0 : value);
      }, 0);
      return sum.toFixed(2);
    }
    return ""; // Leave non-numeric columns blank
  });

  autoTable(doc, {
    head: [tableHeaders],
    body: [...tableBody, footerTotals], // Add footer totals as the last row
    startY: 20,
  });

  doc.save("ItemwiseQuantityReport.pdf");
};
