import { DomainBase } from "./DomainBase";

export  class sectionTable extends DomainBase {
    constructor() {
        super();
        this.Name = "";
        this.Capacity = 0;
        this.IsInUse = false;
        this.Branch = null;
    }
}