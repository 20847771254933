import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDailySale } from "../services/DashBoardApiService";

export const fetchDailySale = createAsyncThunk(
  "filters/fetchDailySale",
  async ({ branch, StartingDate, EndingDate }) => {
    const data = await getDailySale(branch, StartingDate, EndingDate);
    return data;
  }
);
const listDailySale = createSlice({
  name: "DailySales",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailySale.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDailySale.fulfilled, (state, action) => {
        state.DailySales = action.payload;
        state.loading = false;
      })
      .addCase(fetchDailySale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default listDailySale.reducer;
