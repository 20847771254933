// export default Customer;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  PlusCircle,
  ChevronUp,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Table from "../../core/pagination/datatable";
import {
  getCustomersList,
  deleteCustomer,
  getstatesByGuid,
} from "../../services/MasterApiServices";
import { useDispatch, useSelector } from "react-redux";
import { CustomerClass } from "../../core/json/Customer";
import CustomerModal from "../../components/master/modal/CustomerModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import {
  renderTooltip,
  exportExcel,
  exportPDF,
  HandlePrint,
} from "../../redux/helper.js";
import { CustomerReceiptType } from "../../core/json/CustomerReceiptType.js";
import { LedgerClass } from "../../core/json/Ledger.js";
import {
  exportMasterExcel,
  exportMasterPDF,
  printMasterReport,
} from "../../core/utils/MasterReportUtils.js";

const Customer = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [updateData, setUpdateData] = useState([]);
  const [customerData, setCustomerData] = useState([]); // Ensure it's an array initially

  const { togglehead } = useSelector((state) => state.headerToggle);
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const { menuItems } = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();

  // Fetch customer list when component mounts
  useEffect(() => {
    setpermit(
      menuItems?.menuPermissions?.find((e) => e.menu.name == "Customer")
    );
    handleCustomersList();
  }, []);

  const handleCustomersList = async () => {
    try {
      const response = await getCustomersList(branch);

      if (Array.isArray(response)) {
        setCustomerData(response);
      } else {
        setCustomerData([]);
      }
    } catch (error) {
      console.log(
        error?.response?.data?.Message || "Unexpected error occurred."
      );
      setCustomerData([]);
    }
  };
  console.log(customerData, "customerdata");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const modifiedList = Array.isArray(customerData)
    ? customerData?.filter((value) =>
        value.name?.toLowerCase().includes(searchText?.toLowerCase())
      )
    : [];

  const handleEditClick = (guid) => {
    const customer = getCustomer(guid);
    if (customer) {
      setUpdateData(customer); // Set the data for editing
      setModalMode("edit"); // Set modal mode to 'edit'
      setModalOpen(true); // Open modal
    }
  };

  const getCustomer = (guid) => {
    const filteredCustomers = customerData.filter(
      (customer) => customer.guid === guid
    );
    return filteredCustomers[0];
  };

  const MySwal = withReactContent(Swal);

  const handleDeleteClick = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      console.log(result);
      if (result.isConfirmed) {
        try {
          const response = await deleteCustomer(id);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "Customer has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handleCustomersList(); // Refresh the list
              setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting discount:",
            error?.response?.data?.Message || "something went wrong"
          );
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name?.length - b.name?.length,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile - b.mobile,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email?.length - b.email?.length,
    },
    {
      title: "Address 1",
      dataIndex: "address1",
      sorter: (a, b) => a.address1?.length - b.address1?.length,
    },
    {
      title: "Discount%",
      dataIndex: "discountPercentage",
      sorter: (a, b) => a.discountPercentage - b.discountPercentage,
    },
    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                  {permit?.edit && (
                    <Link
                      className="me-2 p-2"
                      to="#"
                      onClick={() => handleEditClick(obj.guid)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                  {permit?.deletion && (
                    <Link
                      className="confirm-text p-2"
                      to="#"
                      onClick={() => handleDeleteClick(obj.id)}
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];
  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create the field mapping between column titles and dataIndex values
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map title to dataIndex
      });
      return mappings;
    };

    // Generate the field mapping for the current columns
    fieldMapping = createfieldMapping(columns);

    // Return the filtered data, columns, and field mapping
    return { data: modifiedList, columns: columns, fieldMapping };
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Customer</h4>
              <h6>Manage your Customers</h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                <Link
                  onClick={() => exportMasterPDF(getActiveTabData, "Customer")}
                >
                  <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip("Excel")}>
                <Link
                  onClick={() =>
                    exportMasterExcel(getActiveTabData, "Customer")
                  }
                >
                  <ImageWithBasePath
                    src="assets/img/icons/excel.svg"
                    alt="img"
                  />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip("Print")}>
                <Link
                  onClick={() =>
                    printMasterReport(getActiveTabData, "Customer")
                  }
                >
                  <i data-feather="printer" className="feather-printer" />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger
                placement="top"
                overlay={renderTooltip("Refresh")}
              >
                <Link onClick={() => handleCustomersList()}>
                  <RotateCcw />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger
                placement="top"
                overlay={renderTooltip("Collapse")}
              >
                <Link
                  className={togglehead ? "active" : ""}
                  onClick={() => dispatch(setheadertoggle(!togglehead))}
                >
                  <ChevronUp />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>

          {permit?.add && (
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add"); // Set the modal mode to 'add'
                  setModalOpen(true); // Open the modal
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>
          )}
        </div>

        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                    value={searchText}
                    onChange={handleSearch}
                  />
                  <Link to="#" className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <Table columns={columns} dataSource={modifiedList} />
            </div>
          </div>
        </div>
      </div>

      {modalOpen && (
        <CustomerModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handleCustomersList();
            setSearchText(""); // Reset search input
          }}
        />
      )}
    </div>
  );
};

export default Customer;
