import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWaiterWiseSale } from "../services/DashBoardApiService";

export const fetchWaiterWiseSale = createAsyncThunk(
  "filters/fetchWaiterWiseSale",
  async ({ branch, StartingDate, EndingDate }) => {
    const data = await getWaiterWiseSale(branch, StartingDate, EndingDate);
    return data;
  }
);
const listWaiterSale = createSlice({
  name: "WaiterSale",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWaiterWiseSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWaiterWiseSale.fulfilled, (state, action) => {
        state.WaiterSale = action.payload;
        state.loading = false;
      })
      .addCase(fetchWaiterWiseSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default listWaiterSale.reducer;
