import React, { useCallback, useEffect, useRef, useState } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ChevronUp, RotateCcw } from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import {
  fetchCategory,
  fetchGroup,
  fetchProduct,
  fetchSection,
  fetchType,
  fetchUnit,
} from "../../redux/userSetting";

import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { Table } from "antd";
import { ProductImportEntry } from "../../core/json/ProductImportEntry";
import {
  productSave,
  productValidation,
} from "../../services/ProductImportServices";
import SectionWiseProductDetail from "../../core/json/SectionWiseProductDetail";
import { UnitClass } from "../../core/json/UnitClass";
import { Type } from "../../core/json/Type";
import { CategoryClass } from "../../core/json/Category";
import { GroupEntryClass } from "../../core/json/GroupEntry";
import { SectionClass } from "../../core/json/SectionClass";
import toast from "react-hot-toast";
import LoadingUtils from "../../core/utils/LoadingUtils";

const ProductImport = () => {
  const [values, setvalues] = useState([]);
  const { userId } = useSelector((state) => state.userauth);
  const [sectionArray, setSectionArray] = useState([]);
  const [loadedTable, setloadedTable] = useState(false);
  const [loading, setloading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { branch } = useSelector((state) => state.branchlist);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const mandatoryHeaders = [
    "SI.NO",
    "Name",
    "ShortName",
    "ArabicDescription",
    "Code",
    "BarCode",
    "Category",
    "ItemType",
    "Unit",
    "Group",
    "Cost",
    "BasePrice",
    "OtherDescription",
  ];
  useEffect(() => {
    handleReset();
  }, [branch]);

  useEffect(() => {
    dispatch(fetchCategory(branch.guid));
    dispatch(fetchSection(branch.guid));
    dispatch(fetchGroup(branch.guid));
    dispatch(fetchProduct(branch));
    // dispatch(fetchBranchSettings(branch.guid));
  }, [branch]);
  useEffect(() => {
    dispatch(fetchType());
    dispatch(fetchUnit());
    // dispatch(fetchTax());
  }, []);
  const {
    formattedTypeList,
    formattedCategoryList,
    formatedproductList,
    sectionList,
    productList,
    categoryList,
    unitList,
    deliveyTypes,
    groupList,
  } = useSelector((state) => state.usersetting);
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const onDrop = useCallback((acceptedFiles) => {
    // Filter out non-xlsx/xls files
    const excelFiles = acceptedFiles.filter(
      (file) =>
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
    );
    if (excelFiles?.length > 0) {
      setFiles(excelFiles);
      const excelFile = excelFiles[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          defval: "", // Default value for missing cells
        });

        const header = jsonData[0].filter((col) => col.trim() !== "");
        const validColumnIndices = jsonData[0]
          .map((col, index) => (col.trim() !== "" ? index : -1))
          .filter((index) => index !== -1);

        const tableData = jsonData
          .slice(1) // Skip the header row
          .map((row) => validColumnIndices.map((index) => row[index])) // Filter columns
          .filter(
            (row) => row.some((cell) => String(cell).trim() !== "") // Ensure at least one non-empty cell
          );

        // Validate headers
        const missingHeaders = mandatoryHeaders.filter(
          (mandHdr) => !header.includes(mandHdr)
        );

        if (missingHeaders.length > 0) {
          setErrorMessage(
            `Missing mandatory headers: ${missingHeaders.join(", ")}`
          );
          return;
        }

        // Validate rows for missing values under mandatory headers
        const hasEmptyValues = tableData.some((row) =>
          row.some((cell, index) => {
            const columnName = header[index];
            return (
              mandatoryHeaders.includes(columnName) &&
              columnName !== "ArabicDescription" &&
              String(cell).trim() === ""
            );
          })
        );

        if (hasEmptyValues) {
          setErrorMessage(
            "File contains missing values under mandatory columns."
          );
          return;
        }

        if (header.length > 13) {
          const values = tableData.map((item, index) => {
            const row = { key: `${index}-${item[0]}` };
            let sectionArray = [];
            const totalLength = header.length;

            for (let i = 0; i < totalLength; i++) {
              const sections = header[i];
              row[sections] = item[i];
            }

            for (let i = 13; i < totalLength; i++) {
              const sectionObj = new SectionClass();
              sectionObj.name = header[i];
              const price = item[i];
              const isSectionActive =
                sectionList.filter(
                  (section) =>
                    section.name?.toString().trim().toLowerCase() ===
                    header[i]?.toString().trim().toLowerCase()
                ).length > 0;

              sectionArray.push({
                obj: sectionObj,
                price: price,
                isSectionActive: isSectionActive,
              });
            }

            setSectionArray(sectionArray);
            return {
              ...row,
              SectionArray: sectionArray,
            };
          });

          handleUploadClick(values);
        } else {
          setErrorMessage(
            "Please include mandatory column names and try again!"
          );
          return false;
        }
      };

      reader.readAsArrayBuffer(excelFile);
      setErrorMessage("");
    } else {
      setFiles([]);
      setErrorMessage("Please select only .xlsx or .xls files.");
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".xlsx, .xls",
  });
  function handleDeleteClick(rowId) {
    try {
      const deletedData = values.filter((product) => product.rowId !== rowId);

      setvalues(deletedData);
      toast.success("Deleted the row successfully");
    } catch (error) {
      toast.error("Failed to delete row!");
    }
  }
  const columns = [
    {
      title: "SL No.",
      dataIndex: "SI.NO", //"SI.NO"
      width: "5rem",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: "10rem",
      render: (value, record) => (
        <span
          style={{
            color: record?.isDuplicate
              ? "orange"
              : record?.isDuplicateName && "red",
          }}
        >
          {value}
        </span>
      ),
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      sorter: (a, b) => a.ShortName.localeCompare(b.ShortName),
      width: "10rem",
      render: (value, record) => (
        <span
          style={{
            color: record?.isDuplicate
              ? "orange"
              : record?.isDuplicateShortName && "red",
          }}
        >
          {value}
        </span>
      ),
    },
    {
      title: "Arabic Desc",
      dataIndex: "arabicDescription",
      sorter: (a, b) => a.arabicDescription.localeCompare(b.arabicDescription),
      width: "8rem",
      render: (value, record) => (
        <span
          style={{
            color: record?.isDuplicate && "orange",
          }}
        >
          {value}
        </span>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
      width: "5rem",
      render: (value, record) => (
        <span
          style={{
            color: record?.isDuplicate
              ? "orange"
              : record?.isDuplicateCode && "red",
          }}
        >
          {value}
        </span>
      ),
    },
    {
      title: "Bar Code",
      dataIndex: "barcode",
      sorter: (a, b) => a.barcode.localeCompare(b.barcode),
      width: "8rem",
      render: (value, record) => (
        <span
          style={{
            color: record?.isDuplicate
              ? "orange"
              : record?.isDuplicateBarcode && "red",
          }}
        >
          {value}
        </span>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      sorter: (a, b) => a.category.localeCompare(b.category),
      width: "5rem",
      render: (value, record) => (
        <span
          style={{
            color: record?.isDuplicate
              ? "orange"
              : !record?.isCategoryActive && "violet",
          }}
        >
          {value?.name}
        </span>
      ),
    },
    {
      title: "Item Type",
      dataIndex: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
      width: "8rem",
      render: (value, record) => (
        <span
          style={{
            color: record?.isDuplicate
              ? "orange"
              : !record?.isTypeActive && "violet",
          }}
        >
          {value?.name}
        </span>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      sorter: (a, b) => a.unit.localeCompare(b.unit),
      width: "5rem",
      render: (value, record) => (
        <span
          style={{
            color: record?.isDuplicate
              ? "orange"
              : !record?.isUnitActive && "violet",
          }}
        >
          {value?.name}
        </span>
      ),
    },
    {
      title: "Group",
      dataIndex: "group",
      sorter: (a, b) => a.group.localeCompare(b.group),
      width: "5rem",
      render: (value, record) => (
        <span
          style={{
            color: record?.isDuplicate
              ? "orange"
              : !record?.isGroupActive && "violet",
          }}
        >
          {value?.name}
        </span>
      ),
    },
    {
      title: "Cost",
      dataIndex: "cost",
      sorter: (a, b) => a.cost - b.cost,
      width: "5rem",
      render: (value, record) => (
        <span style={{ color: record?.isDuplicate && "orange" }}>{value}</span>
      ),
    },
    {
      title: "Base Price",
      dataIndex: "basePrice",
      sorter: (a, b) => a.basePrice - b.basePrice,
      width: "8rem",
      render: (value, record) => (
        <span style={{ color: record?.isDuplicate && "orange" }}>{value}</span>
      ),
    },
    {
      title: "Other Desc",
      dataIndex: "otherDescription",
      sorter: (a, b) => a.otherDescription.localeCompare(b.otherDescription),
      width: "8rem",
      render: (value, record) => (
        <span style={{ color: record?.isDuplicate && "orange" }}>{value}</span>
      ),
    },
    ...sectionArray.map((section) => ({
      title: section.obj.name,
      dataIndex: "sectionWiseProductDetailList", // Use section name for dataIndex
      key: section.obj.name, // Use section name for key
      width: "8rem",
      align: "center",
      render: (value, record) => {
        const sectionDetail = record.sectionWiseProductDetailList.find(
          (detail) => detail.section.name === section.obj.name
        );
        return (
          <div
            style={{
              backgroundColor: !section?.isSectionActive && "red",
              color: !section?.isSectionActive && "white",
            }}
          >
            {sectionDetail ? sectionDetail.price : 0}
          </div>
        );
      },
    })),

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "5rem",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj.rowId)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  async function handleUploadClick(values) {
    try {
      const ProductImportEntryobj = new ProductImportEntry();
      ProductImportEntryobj.Branch = branch;

      const product = values.map((item) => {
        const unitobj = new UnitClass();
        unitobj.name = item.Unit;
        const typeobj = new Type();
        typeobj.name = item.ItemType;
        const groupobj = new GroupEntryClass();
        groupobj.name = item.Group;
        const categoryobj = new CategoryClass();
        categoryobj.name = item.Category;

        const sections = item.SectionArray.map((e, index) => {
          const SectionWiseProductDetailobj = new SectionWiseProductDetail();
          SectionWiseProductDetailobj.IsActive = false;
          SectionWiseProductDetailobj.IsTaxIncludedInPrice = false;
          SectionWiseProductDetailobj.Price = e.price;
          SectionWiseProductDetailobj.Tax = null;
          SectionWiseProductDetailobj.Section = e.obj;
          return SectionWiseProductDetailobj;
        });

        return {
          Name: item.Name,
          IsDuplicateName: false,
          ShortName: item.ShortName,
          IsDuplicateShortName: false,
          Code: item.Code.toString(),
          IsDuplicateCode: false,
          Barcode: item.BarCode.toString(),
          IsDuplicateBarcode: false,
          ArabicDescription: item.ArabicDescription ?? "",
          OtherDescription: item.OtherDescription,
          Cost: item.Cost,
          IsActive: true,
          Category: categoryobj,
          IsCategoryActive: false,
          Group: groupobj,
          IsGroupActive: false,
          Type: typeobj,
          IsTypeActive: false,
          Unit: unitobj,
          IsUnitActive: false,
          SectionWiseProductDetailList: sections,
          BasePrice: item.BasePrice,
        };
      });
      ProductImportEntryobj.ProductImportItems = product;

      const response = await productValidation(ProductImportEntryobj);
      handleValidation(response);
    } catch (error) {
      toast.error("Failed to save data!");
      console.error(
        "Error fetching product by GUID:",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  }

  function handleValidation(response) {
    try {
      const products = response.data.productImportItems;

      products.filter((product, index, selfproducts) => {
        const isDuplicate = selfproducts.findIndex(
          (productIndex) =>
            productIndex.name === product.name &&
            (productIndex.barcode === product.barcode) !== index
        );

        product.isDuplicate = false;
        if (isDuplicate != index) {
          product.isDuplicate = true;
        }
        product.rowId = index + 1;
        return true;
      });
      setvalues(products);
      setloadedTable(true);
    } catch (error) {
      toast.error("Failed to save data!");
    }
  }
  async function handleSave() {
    setIsSaving((p) => true);
    console.log("isSaving inside save", isSaving);
    try {
      setIsSaving((p) => true);
      setloading(true);
      let hasValidationErrors = false;
      const errorMessages = new Set();

      // Check for validation errors in the `values` array
      for (const item of values) {
        if (!item.isGroupActive) {
          errorMessages.add("Some groups are inactive.");
        }
        if (!item.isTypeActive) {
          errorMessages.add("Some types are inactive.");
        }
        if (!item.isUnitActive) {
          errorMessages.add("Some units are inactive.");
        }
        if (!item.isCategoryActive) {
          errorMessages.add("Some categories are inactive.");
        }
        if (item.isDuplicateBarcode) {
          errorMessages.add("There are duplicate barcodes.");
        }
        if (item.isDuplicateCode) {
          errorMessages.add("There are duplicate codes.");
        }
        if (item.isDuplicateName) {
          errorMessages.add("There are duplicate names.");
        }
        if (item.isDuplicateShortName) {
          errorMessages.add("There are duplicate short names.");
        }
        if (item.isDuplicate) {
          errorMessages.add("There are duplicate entries.");
        }
      }

      // Check for validation errors in the `sectionArray`
      const sectionNotActive = sectionArray.some(
        (section) => !section.isSectionActive
      );
      if (sectionNotActive) {
        errorMessages.add("Some sections are inactive.");
      }

      // If there are validation errors, show the errors and stop execution
      if (errorMessages.size > 0) {
        hasValidationErrors = true;
        const errorMessageString = Array.from(errorMessages).join(" ");
        toast.error(errorMessageString);
        // setIsSaving((p) => false);
        return;
      }

      const ProductImportEntryobj = new ProductImportEntry();
      ProductImportEntryobj.Branch = branch;
      ProductImportEntryobj.ProductImportItems = values;
      ProductImportEntryobj.updatedUser = `{${userId?.id}}`;

      const response = await productSave(ProductImportEntryobj);
      if (response) {
        setloading(false);
        toast.success("Successfully saved the products.");
        handleReset();
      }
    } catch (error) {
      setloading(false);
      toast.error("Failed to save: " + error.message);
      setIsSaving((p) => false);
    } finally {
      // Delay the state update to reset `isSaving` after a short delay
      setTimeout(() => {
        setIsSaving(false);
      }, 2000); // Adjust the delay time (in milliseconds) as needed
    }
  }
  console.log("isSaving", isSaving);
  async function handleReset() {
    setvalues("");
    setloadedTable(false);
  }
  return (
    <div>
      {loading && <LoadingUtils />}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Import Product</h4>
              </div>
            </div>
            <div className="row align-items-center table-top-head">
              {loadedTable && (
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn btn-submit"
                    onClick={handleSave}
                    disabled={isSaving}
                  >
                    {!isSaving ? "Save" : "Saving..."}
                  </button>
                </div>
              )}

              <div className="col-auto mx-0">
                <button
                  type="button"
                  className="btn btn-reset"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="flex-container">
                {!loadedTable && (
                  <div
                    className="dropzone-container col-lg-12"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} ref={fileInputRef} />
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="Excel Icon"
                    />
                    {isDragActive ? (
                      <p>Drop the files here...</p>
                    ) : (
                      <p>Drop your .xlsx or .xls file here or browse</p>
                    )}
                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                  </div>
                )}
                {loadedTable && (
                  <>
                    <div className="ms-2 my-2">
                      <p style={{ color: "red" }} className="my-0">
                        * Red text Indicates duplicates from database
                      </p>
                      <p style={{ color: "red" }} className="my-0">
                        * Red column indicates missing section
                      </p>
                      <p style={{ color: "orange" }} className="my-0">
                        * Orange text Indicates duplicates from excel
                      </p>
                      <p style={{ color: "violet" }} className="my-0">
                        * Violet text Indicates missing masters
                      </p>
                    </div>
                    <div className="table-responsive">
                      <Table
                        columns={columns}
                        dataSource={values}
                        tableLayout="fixed"
                        scroll={{ x: "max-content" }}
                        sticky={{ offsetHeader: 0 }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default ProductImport;
