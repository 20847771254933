import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  PlusCircle,
  RotateCcw,
  ChevronUp,
  Calendar,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import { getStockByBranch, deleteStock } from "../../services/StockApiServices";
import { OverlayTrigger, TabPane, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import dayjs from "dayjs";
import { renderTooltip } from "../../redux/helper";
import { getJSON } from "jquery";
import { InvStockAdjustment } from "../../core/json/invStockAdjust";
import StockAdjustmentModal from "../../components/transactions/modal/StockAdjustmentModal";
import { DatePicker, Tabs } from "antd";
import {
  printReport,
  exportExcel,
  exportPDF,
} from "../../core/utils/reportUtils";

const StockAdjustment = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [updateData, setUpdateData] = useState([]);
  const [modalMode, setModalMode] = useState("add");
  const [modalOpen, setModalOpen] = useState(false);
  //
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  //
  //
  const [stockList, setStockList] = useState([]); //direct set
  const [detailedStockData, setDetailedStockData] = useState([]); //detailed tables set
  const [detailedStockAdjData, setDetailedAdjStockData] = useState([]); // detailed stockadj data
  const [detailedWastageData, setDetailedWastageData] = useState([]); // stock data
  const [detailedDamageData, setDetailedDamageData] = useState([]); // stock data
  const [summaryStockData, setSummaryStockData] = useState([]); //summary stock data
  const [summStockAdjData, setsummAdjStockData] = useState([]); // sum stockadj data
  const [summWastageData, setsummWastageData] = useState([]); // sum wastage data
  const [summDamageData, setsummDamageData] = useState([]); // sum damage
  const [filteredSummData, setFilteredSummData] = useState([]);
  const [filteredDetData, setFilteredDetData] = useState([]);
  const [filteredStockList, setFilteredStockList] = useState([]);
  //
  const { TabPane } = Tabs;
  const { togglehead } = useSelector((state) => state.headerToggle);

  // Get branch from redux
  const { branch } = useSelector((state) => state.branchlist);
  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);
  const { menuItems } = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  useEffect(() => {
    setpermit(
      menuItems?.menuPermissions?.find((e) => e.menu.name == "Stock Adjustment")
    );
  }, []);
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  useEffect(() => {
    handlestockData();
  }, [branch]);

  useEffect(() => {
    handledetailedTables(detailedStockData);
  }, [detailedStockData]);
  useEffect(() => {
    handlesummaryTables(summaryStockData);
  }, [summaryStockData]);
  function handlesummaryTables(data) {
    let summData;
    summData = data?.filter((data) => data.type === 0);
    setsummAdjStockData(summData);
    summData = data?.filter((data) => data.type === 2);
    setsummWastageData(summData);
    summData = data?.filter((data) => data.type === 1);
    setsummDamageData(summData);
  }

  function handledetailedTables(data) {
    let DetailedData;
    DetailedData = data?.filter((data) => data.type === 0);
    setDetailedAdjStockData(DetailedData);
    console.log(DetailedData, "DetailedData");
    DetailedData = data?.filter((data) => data.type === 1);
    setDetailedDamageData(DetailedData);
    DetailedData = data?.filter((data) => data.type === 2);
    setDetailedWastageData(DetailedData);
  }
  const handleFromDateChange = (date) => {
    setFromDate(date);
    setToDate(null);
    console.log(date, "from date");
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    console.log(date, "to date");
  };
  const handleTabChange = (key) => {
    console.log("Active Tab:", key);
    setActiveTab(key);
    if (key == 1) {
      setIsFilterVisible(false);
    } else {
      setIsFilterVisible(true);
    }
  };
  const getActiveTabData = () => {
    let fieldMapping = {};

    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex;
      });
      return mappings;
    };
    switch (activeTab) {
      case "1":
        fieldMapping = createfieldMapping(columns);

        return {
          data: filteredStockList?.length > 0 ? filteredStockList : stockList,
          columns: columns,
          fieldMapping,
          filename: "Stock Adjustment",
        };
      case "2":
        fieldMapping = createfieldMapping(summaryColumns);

        return {
          data: summDamageData,
          columns: summaryColumns,
          fieldMapping,
          filename: "SA Damage Reports",
        };
      case "2-1":
        fieldMapping = createfieldMapping(summaryColumns);

        return {
          data: summDamageData,
          columns: summaryColumns,
          fieldMapping,
          filename: "SA Damage Summary Reports",
        };
      case "2-2":
        fieldMapping = createfieldMapping(detailedColumns);

        return {
          data: detailedDamageData,
          columns: detailedColumns,
          fieldMapping,
          filename: "SA Damage Detailed Report",
        };
      case "3":
        fieldMapping = createfieldMapping(summaryColumns);

        return {
          data: summWastageData,
          columns: summaryColumns,
          fieldMapping,
          filename: "SA Wastage Reports",
        };
      case "3-1":
        fieldMapping = createfieldMapping(summaryColumns);

        return {
          data: summWastageData,
          columns: summaryColumns,
          fieldMapping,
          filename: "SA Wastage Summary Reports",
        };
      case "3-2":
        fieldMapping = createfieldMapping(detailedColumns);

        return {
          data: detailedWastageData,
          columns: detailedColumns,
          fieldMapping,
          filename: "SA Wastage Detailed Reports",
        };
      case "4":
        fieldMapping = createfieldMapping(summaryColumns);

        return {
          data: summStockAdjData,
          columns: summaryColumns,
          fieldMapping,
        };
      case "4-1":
        fieldMapping = createfieldMapping(summaryColumns);

        return {
          data: summStockAdjData,
          columns: summaryColumns,
          fieldMapping,
          filename: "SA Summary Reports",
        };
      case "4-2":
        fieldMapping = createfieldMapping(detailedColumns);

        return {
          data: detailedStockAdjData,
          columns: detailedColumns,
          fieldMapping,
          filename: "SA Detailed Reports",
        };
      default:
        return { data: [], columns: [] };
    }
  };

  console.log(summDamageData, "--------------------summDamagedata");
  function handleReset() {
    setFromDate(null);
    setToDate(null);
    setSearchText("");
    handlestockData();
  }

  const handlestockData = async () => {
    try {
      const response = await getStockByBranch(branch);
      const stockData = Array.isArray(response.data) ? response.data : [];

      setDetailedStockData(stockData);
      setStockList(stockData);
      const modifiedSummaryData = stockData?.map((entry, index) => {
        return {
          key: index,
          date: entry?.date,
          no: entry?.no || 0,
          remarks: entry?.remarks || "",
          type: entry?.type || 0,
        };
      });

      setSummaryStockData(modifiedSummaryData);

      const modifiedData = (stockData) => {
        return stockData.stockAdjustmentDetails?.map((detailData, index) => {
          console.log(detailData, "detailData");
          console.log(stockData, "stockData");
          return {
            key: index,
            date: stockData?.date,
            no: stockData?.no || 0,
            product: detailData?.product?.name || "",
            type: stockData?.type || 0,
            remarks: stockData?.remarks || "",
            baseQuantity: detailData?.baseQuantity || 0,
            adjQuantity: detailData?.adjQuantity || 0,
            unit: detailData?.unit?.name ? detailData?.unit?.name : "",
            guid: stockData?.guid,
          };
        });
      };
      const finalData = stockData.flatMap((stockdata) =>
        modifiedData(stockdata)
      );

      setDetailedStockData(finalData);
    } catch (error) {
      console.error(
        "Failed to fetch Stock adjustments",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Entry No",
      dataIndex: "no",
      sorter: (a, b) => a.entryNumber - b.entryNumber,
    },

    {
      title: "Type",
      dataIndex: "type",
      render: (type) => {
        let badgeClass = "";
        let text = "";

        switch (type) {
          case 0:
            text = "Stock Adjustment";
            badgeClass = "bg-lightyellow";
            break;
          case 1:
            text = "Damage";
            badgeClass = "bg-lightpurple";
            break;
          case 2:
            text = "Wastage";
            badgeClass = "bg-lightblue";
            break;
          case 3:
            text = "Credit";
            badgeClass = "bg-lightred";
            break;
          case 4:
            text = "UPI";
            badgeClass = "bg-lightgreen";
            break;
          default:
            text = "Unknown";
            badgeClass = "";
        }

        return <span className={`badges ${badgeClass}`}>{text}</span>;
      },
      sorter: (a, b) => a.type?.localeCompare(b?.type),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: (a, b) => a.remarks?.localeCompare(b?.remarks),
    },
    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                  {permit?.edit && (
                    <Link
                      className="me-2 p-2"
                      to="#"
                      onClick={() => handleEditClick(obj.guid)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                  {permit?.deletion && (
                    <Link
                      className="confirm-text p-2"
                      to="#"
                      onClick={() => handleDeleteClick(obj)}
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];
  const detailedColumns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Entry No",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Product",
      dataIndex: "product",
      sorter: (a, b) => a.product?.localeCompare(b?.product),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      sorter: (a, b) => a.unit?.localeCompare(b?.unit),
    },
    {
      title: "Batch",
      dataIndex: "batch",
      sorter: (a, b) => a.batch?.localeCompare(b?.batch),
    },
    {
      title: "Quantity",
      dataIndex: "baseQuantity",
      sorter: (a, b) => a.baseQuantity - b.baseQuantity,
    },
    {
      title: "Adj Quantity",
      dataIndex: "adjQuantity",
      sorter: (a, b) => a.adjQuantity - b.adjQuantity,
    },
  ];
  const summaryColumns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Entry No",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },

    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: (a, b) => a.remarks?.localeCompare(b?.remarks),
    },
  ];
  const MySwal = withReactContent(Swal);

  // Handle the click event to delete a Stock adjustments
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objStockAdj = new InvStockAdjustment();
          objStockAdj.id = obj.id;
          objStockAdj.guid = obj.guid;
          objStockAdj.branch = branch;
          objStockAdj.type = 0;
          // objStockAdj.isDeleted = true;
          objStockAdj.updatedUser = `${userId?.id}`;
          const response = await deleteStock(objStockAdj);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The Stock Adjustment has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handlestockData();
              setSearchText("");
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting reason:",
            error?.response?.data?.Message || "something went wrong"
          );
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const handleEditClick = async (guid) => {
    try {
      const response = getStockAdjByGuid(guid);

      setUpdateData(response);
      setModalMode("edit");
      setModalOpen(true);
    } catch (error) {
      console.error(
        "Error fetching Stock adjustment by GUID",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  function getStockAdjByGuid(guid) {
    const filteredStock = stockList?.filter(
      (stockAdj) => stockAdj.guid === guid
    );

    return filteredStock[0];
  }
  function handleFilter() {
    const filteredDetData = detailedStockData?.filter((entry) => {
      const fromDateMatch =
        !fromDate || new Date(entry.date) >= new Date(fromDate);
      const toDateMatch = !toDate || new Date(entry.date) <= new Date(toDate);
      const searchTextMatch =
        entry?.date?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.no?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.adjQuantity?.toString().includes(searchText);
      entry?.baseQuantity?.toString().includes(searchText);
      entry?.product?.toLowerCase().includes(searchText?.toLowerCase());

      return fromDateMatch && toDateMatch && searchTextMatch;
    });

    const filteredSummData = summaryStockData?.filter((entry) => {
      const fromDateMatch =
        !fromDate || new Date(entry.date) >= new Date(fromDate);
      const toDateMatch = !toDate || new Date(entry.date) <= new Date(toDate);

      const searchTextMatch =
        entry?.date?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.no?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.remarks?.toLowerCase().includes(searchText?.toLowerCase());
      return fromDateMatch && toDateMatch && searchTextMatch;
    });
    setFilteredDetData(filteredDetData);
    setFilteredSummData(filteredSummData);
    setFilteredStockList(filteredSummData);
    handlesummaryTables(filteredSummData);
    handledetailedTables(filteredDetData); //---------------------------
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Stock Adjustment</h4>
                <h6>Manage Stock Adjustments here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() =>
                      exportPDF(getActiveTabData, "Stock adjustment")
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() =>
                      exportExcel(getActiveTabData, "Stock adjustment")
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() =>
                      printReport(getActiveTabData, "Stock adjustment")
                    }
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handlestockData()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            {permit?.add && (
              <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  onClick={() => {
                    setModalMode("add"); // Set the modal mode to 'add'
                    setModalOpen(true); // Open the modal
                  }}
                >
                  <PlusCircle className="me-2" />
                  New
                </Link>
              </div>
            )}
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-blocks">
                      <label htmlFor="from-date">From Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          // selected={fromDate}
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          allowClear
                          // dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-blocks mr-2">
                      <label htmlFor="to-date">To Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          // selected={toDate}
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          allowClear
                          disabled={!fromDate} // disable until from date is selected
                          // dateFormat="dd/MM/yyyy"
                          // className='form-control'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <div className="input-blocks me-2">
                    <Link
                      className="btn btn-filters ms-auto"
                      // onClick={() => {
                      //   setCurrentPage(1);
                      //   setPageSize(20);
                      //   handleStockInfullReport();
                      //   handleStockInReport(currentPage, pageSize);
                      // }}
                      onClick={handleFilter}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      search{" "}
                    </Link>
                  </div>
                  <div className="input-blocks">
                    <button
                      className="btn btn-reset rounded-2"
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
                {/* <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset btn-sm rounded-2">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div> */}

                {/* <div className="d-flex justify-content-end"> */}
                {/* <div className="input-blocks me-2">
                    <button
                      className="btn btn-filters btn-sm rounded-2"
                      onClick={handleFilter}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search
                    </button>
                  </div> */}

                {/* <div className="input-blocks">
                    <button
                      className="btn btn-reset btn-sm rounded-2 "
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div> */}
                {/* </div>
              </div> */}

                {/* <div className="card-body ms-4"> */}
                {/* <div className="row"> */}
                {/* From Date Filter */}
                {/* <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>From Date</label>
                      <div className="input-groupicon ">
                        <Calendar className="info-img" />
                        <DatePicker
                          className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                          style={{ height: "38px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div> */}

                {/* To Date Filter */}
                {/* <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon ">
                        <Calendar className="info-img" />
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate}
                          dateFormat="dd/MM/yyyy"
                          style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                        />
                      </div>
                    </div>
                  </div> */}
                {/* </div> */}
              </div>
              {/* </div> */}
              <div className="table-responsive">
                <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                  <TabPane tab="Stock Adjustment" key="1">
                    <Table
                      columns={columns}
                      dataSource={
                        filteredStockList?.length > 0
                          ? filteredStockList
                          : stockList
                      }
                    />
                  </TabPane>

                  <TabPane tab="Damage Reports" key="2">
                    <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                      <TabPane tab="Summary" key="2-1">
                        <Table
                          columns={summaryColumns}
                          dataSource={summDamageData}
                        />
                      </TabPane>
                      <TabPane tab="Detailed" key="2-2">
                        <Table
                          columns={detailedColumns}
                          dataSource={detailedDamageData}
                        />
                      </TabPane>
                    </Tabs>
                  </TabPane>

                  <TabPane tab="Wastage Reports" key="3">
                    <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                      <TabPane tab="Summary" key="3-1">
                        <Table
                          columns={summaryColumns}
                          dataSource={summWastageData}
                        />
                      </TabPane>
                      <TabPane tab="Detailed" key="3-2">
                        <Table
                          columns={detailedColumns}
                          dataSource={detailedWastageData}
                        />
                      </TabPane>
                    </Tabs>
                  </TabPane>

                  <TabPane tab="StockAdjustment Reports" key="4">
                    <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                      <TabPane tab="Summary" key="4-1">
                        <Table
                          columns={summaryColumns}
                          dataSource={summStockAdjData}
                        />
                      </TabPane>
                      <TabPane tab="Detailed" key="4-2">
                        <Table
                          columns={detailedColumns}
                          dataSource={detailedStockAdjData}
                        />
                      </TabPane>
                    </Tabs>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalOpen && (
        <StockAdjustmentModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handlestockData();
            setSearchText("");
          }}
        />
      )}
    </div>
  );
};

export default StockAdjustment;
