import React, { useState, useMemo } from "react";

function Test() {
  const [number, setNumber] = useState(5);
  const [increment, setIncrement] = useState(0);

  // Memoized factorial calculation
  const factorial = useMemo(() => {
    const calculateFactorial = (n) => {
      return n <= 1 ? 1 : n * calculateFactorial(n - 1);
    };
    return calculateFactorial(number);
  }, [number]); // Recalculate only when 'number' changes

  return (
    <div>
      <h2>
        Factorial of {number} is: {factorial}
      </h2>
      <input
        type="number"
        value={number}
        onChange={(e) => setNumber(parseInt(e.target.value) || 1)}
      />
      <button onClick={() => setIncrement(increment + 1)}>
        Click to Re-render (Increment: {increment})
      </button>
    </div>
  );
}

export default Test;
