import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { updateCompany } from "../../services/AdminApiServices";
import toast from "react-hot-toast";
import { Company } from "../../core/json/Company";
import { CompanyType } from "../../core/json/CompanyType";

const CompanyUpdate = () => {
  const { companyInfolist } = useSelector((state) => state.companyInfo);
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    setValue,
    setError,
    values,
    reset,
    watch,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  useEffect(() => {
    setValue("company", companyInfolist[0]);
  }, [companyInfolist]);
  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  const handleupdateCompany = async (formData) => {
    const company = new Company();
    const companyType = new CompanyType();
    company.Name = formData?.company?.name;
    company.CompanyType = companyType;
    company.FullName = formData?.company?.fullName;
    company.Address1 = formData?.company?.address1;
    company.Address2 = formData?.company?.address2;
    company.Address3 = formData?.company?.address3;
    company.Phone = formData?.company?.phone;
    company.Email = formData?.company?.email;
    company.Website = formData?.company?.website;
    company.TIN = formData?.company?.tin;
    company.CST = formData?.company?.cst;
    company.LocationName = formData?.company?.locationName;
    company.IsMultiBranchEnabled = formData?.company?.isMultiBranchEnabled;
    company.Branch = branch;
    company.guid = formData?.company?.guid;
    company.id = formData?.company?.id;
    company.createdUser = `{${userId?.id}}`;
    company.updatedUser = `{${userId?.id}}`;
    try {
      const response = await updateCompany(company);
      if (response == true) {
        toast.success("success");
        closemodal();
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error fetching states:", error);
    }
  };

  return (
    <div className="modal fade" id="edit-company">
      <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
        <div className="modal-content">
          <div className="page-wrapper-new p-0">
            <div className="content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>Update Company</h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom-modal-body">
                <form onSubmit={handleSubmit(handleupdateCompany)}>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="input-blocks">
                        <label>
                          Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          {...register("company.name")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="input-blocks">
                        <label>
                          Full Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          {...register("company.fullName")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="input-blocks d-flex mt-lg-4">
                        <input
                          type="checkbox"
                          id="name"
                          {...register("company.isMultiBranchEnabled")}
                        />
                        <label className="m-2">Multi Branch Enable</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="input-blocks">
                        <label>
                          Address 1 <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          {...register("company.address1")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="input-blocks">
                        <label>
                          Address 2 <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          {...register("company.address2")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="input-blocks">
                        <label>Address 3</label>
                        <input
                          type="text"
                          id="name"
                          {...register("company.address3")}
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="input-blocks">
                        <label>
                          Phone <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          {...register("company.phone")}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="input-blocks">
                        <label>Email</label>
                        <input
                          type="text"
                          id="name"
                          {...register("company.email", {
                            required: false,
                            pattern:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                        />
                        {errors?.company?.email?.type === "pattern" && (
                          <span className="text-danger">Enter valid email</span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="input-blocks">
                        <label>
                          Website <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          required
                          id="name"
                          {...register("company.website", {
                            pattern:
                              /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/,
                          })}
                        />
                        {errors?.company?.website?.type === "pattern" && (
                          <span className="text-danger">
                            Enter valid website
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="input-blocks">
                        <label>Tax No</label>
                        <input
                          type="text"
                          id="name"
                          {...register("company.tin")}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="input-blocks">
                        <label>Sales Tax No</label>
                        <input
                          type="text"
                          id="name"
                          {...register("company.cst")}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="input-blocks">
                        <label>
                          Location <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          {...register("company.locationName")}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        ref={closeButtonRef}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyUpdate;
