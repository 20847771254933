import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  RotateCcw,
  ChevronUp,
  PlusCircle,
} from "feather-icons-react/build/IconComponents";

import Table from "../../core/pagination/datatable";
import { Pagination } from "antd";
import Select from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import { renderTooltip } from "../../redux/helper";
import ProductBOQmodal from "../../components/master/modal/ProductBOQmodal";
import {
  getProductBOQlist,
  getProductBoqListView,
} from "../../services/MasterApiServices";
import { fetchProduct, fetchUnit } from "../../redux/userSetting";
import {
  exportMasterExcel,
  exportMasterPDF,
  printMasterReport,
} from "../../core/utils/MasterReportUtils";

function Productboq() {
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branchlist);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { formatedproductList } = useSelector((state) => state.usersetting);
  const { menuItems } = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const [ProductBOQData, setProductBOQData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [Productboq, setProductboq] = useState([]);
  const [productId, setProductId] = useState();
  const [selectedProduct, setselectedProduct] = useState();
  const [selectProduct, setselectProduct] = useState("");
  const [searchText, setSearchText] = useState("");
  console.log(ProductBOQData, "BOQQQ");

  // Get user from redux

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  useEffect(() => {
    handleProductBoqList();

    dispatch(fetchUnit());
    dispatch(fetchProduct(branch));
  }, [branch]);
  useEffect(() => {
    setpermit(
      menuItems?.menuPermissions?.find((e) => e.menu.name == "Product BOQ")
    );
  }, []);
  const handleProductBoqList = async () => {
    try {
      const response = await getProductBOQlist(branch);
      console.log(response.data);

      if (Array.isArray(response)) {
        setProductBOQData(response);
      } else {
        setProductBOQData([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message);
      setProductBOQData([]);
    }
  };
  const handleinvProductBoqList = async (id) => {
    try {
      const response = await getProductBoqListView(branch);

      if (Array.isArray(response)) {
        const boq = response?.find((e) => e.product.guid == id);
        console.log(boq);
        setProductboq(boq);
      } else {
        setProductboq([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message);
      setProductboq([]);
    }
  };

  const handleSelectProduct = (id) => {
    if (productId == id) {
      setProductId();
    } else {
      handleinvProductBoqList(id);
      setProductId(id);
    }
  };

  const addProductBOQ = () => {
    const selected = ProductBOQData?.find((e) => e.guid == productId);
    setselectedProduct(selected);
  };
  const columns = [
    {
      title: "",
      dataIndex: "",
      render: (_, record) => (
        <div key={record.id}>
          <input
            type="checkbox"
            onChange={() => handleSelectProduct(record.guid)}
            checked={record.guid == productId}
          />
        </div>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: (a, b) => a.code?.length - b.code?.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name?.length - b.name?.length,
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      sorter: (a, b) => a.shortName?.length - b.shortName?.length,
    },

    {
      title: "Description",
      dataIndex: "otherDescription",
      sorter: (a, b) => a.otherDescription?.length - b.otherDescription?.length,
    },
  ];
  const modifiedList = ProductBOQData?.filter((product) => {
    const searchMatch =
      product.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
      product.shortName?.toLowerCase().includes(searchText?.toLowerCase()) ||
      product.otherDescription
        ?.toLowerCase()
        .includes(searchText?.toLowerCase()) ||
      product.code?.toLowerCase().includes(searchText?.toLowerCase());

    const itemMatch = selectProduct
      ? product?.name == selectProduct.label
      : true;

    return searchMatch && itemMatch;
  });
  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create the field mapping between column titles and dataIndex values
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map title to dataIndex
      });
      return mappings;
    };

    // Generate the field mapping for the current columns
    fieldMapping = createfieldMapping(columns);

    // Return the filtered data, columns, and field mapping
    return { data: modifiedList, columns: columns, fieldMapping };
  };
  function handleReset() {
    setselectProduct(null);
    setSearchText("");
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Product BOQ</h4>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() =>
                      exportMasterPDF(getActiveTabData, "Product BOQ")
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() =>
                      exportMasterExcel(getActiveTabData, "Product BOQ")
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() =>
                      printMasterReport(getActiveTabData, "Product BOQ")
                    }
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={handleProductBoqList}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <ul className="table-top-head"></ul>
            {permit?.add && (
              <div className="d-flex purchase-pg-btn">
                <div className="page-btn">
                  <button
                    disabled={!productId}
                    to="#"
                    className="btn btn-added"
                    data-bs-toggle="modal"
                    data-bs-target="#add-boq"
                    onClick={addProductBOQ}
                  >
                    BOQ
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div
                className="row gx-2 gy-2 align-items-center"
                style={{ padding: "1rem" }}
              >
                {/* Search Input and Time Pickers */}
                <div className="col-lg-3 col-md-6 col-12 mr-4 ">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm me-2 formsearch "
                    aria-controls="DataTables_Table_0"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-12 d-flex ">
                  <Select
                    className="img-select me-2"
                    options={formatedproductList}
                    value={selectProduct}
                    onChange={setselectProduct}
                    classNamePrefix="react-select"
                    placeholder="Select Product"
                    style={{ height: "38px", width: "100%" }}
                  />
                </div>

                {/* Search and Reset Buttons Positioned on the Right */}
                <div className="col-lg-3 col-md-6 col-12  d-flex justify-content-end">
                  <button
                    className="btn btn-reset rounded-2 "
                    onClick={handleReset}
                  >
                    <i
                      data-feather="refresh-cw"
                      className="feather-refresh-cw"
                    />{" "}
                    Reset
                  </button>
                </div>
              </div>
              <div className="table-responsive product-list">
                <Table columns={columns} dataSource={modifiedList} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <Pagination />
      <ProductBOQmodal data={selectedProduct} productboq={Productboq} />
    </div>
  );
}

export default Productboq;
