import React, { useEffect, useRef, useState } from "react";
import ImageWithBasePath from "../../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Box,
  FileText,
  Filter,
  Package,
  PlusCircle,
  RotateCcw,
  Sliders,
  List,
  User,
  Calendar,
} from "feather-icons-react/build/IconComponents";

import Select from "react-select";
import { Table } from "antd";
// import Table from "../../../../core/pagination/datatableReports";
import { setheadertoggle } from "../../../../redux/toggle";
import { useDispatch, useSelector } from "react-redux";
import { getstockout, getstockin } from "../../../../services/ReportApiService";
import {
  DatatablePagingParameter,
  Filterclass,
} from "../../../../core/json/DatatablePagingParameters";
import { renderTooltip } from "../../../../redux/helper";
import { Checkbox } from "antd";
import { DatePicker } from "antd";
// import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import {
  fetchCategory,
  fetchCounter,
  fetchProduct,
  fetchSection,
  fetchType,
} from "../../../../redux/userSetting";
import { fetchUser } from "../../../../redux/userServices";
import {
  exportExcel,
  exportPDF,
  printReport,
} from "../../../../core/utils/StockReportUtils";
// import {
//   exportreportExcel,
//   exportreportPDF,
//   printreportReport,
// } from "../../../../core/utils/MainReportUtils";
import LoadingUtils from "../../../../core/utils/LoadingUtils";
import { label } from "yet-another-react-lightbox";

const StockSummary = () => {
  const [searchText, setSearchText] = useState("");
  const [stockData, setstockData] = useState();

  const [totalStockFilter, setTotalStockFilter] = useState("");
  //   const [stockOutData, setstockOutData] = useState();
  const [allData, setallData] = useState();
  const [Productfilter, setProductfilter] = useState();
  const [CategoryFilter, setCategoryFilter] = useState();
  const [TypeFilter, setTypeFilter] = useState();
  const [sectionval, setsectionval] = useState();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [totalcount, settotalcount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const title = "Stock Summary Reports";
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branchlist);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { counterList, sectionList } = useSelector(
    (state) => state.usersetting
  );
  const { formatedproductList, formattedCategoryList, formattedTypeList } =
    useSelector((state) => state.usersetting);
  const { UserList } = useSelector((state) => state.userservice);
  const tableRef = useRef(null);

  useEffect(() => {
    handleStockInReport(currentPage, pageSize);
    // handleStockInfullReport();
    dispatch(fetchCategory(branch.guid));
    dispatch(fetchType());
    dispatch(fetchProduct(branch));
  }, [branch, fromDate, toDate]);
  const totalStockOptions = [
    { value: "all", label: "All Stock" },
    { value: "positive", label: "Positive Stock" },
    { value: "negetive", label: "Negetive Stock" },
    { value: "zero", label: "Zero Stock" },
  ];
  const handleStockInReport = async (page, size) => {
    const DatatablePagingParameters = new DatatablePagingParameter();
    DatatablePagingParameters.PageNumber = 1;
    DatatablePagingParameters.PageSize = -1;
    const formattedfromDate = dayjs(fromDate).format("YYYY-MM-DD");
    const formattedtoDate = dayjs(toDate).format("YYYY-MM-DD");
    const Filters = new Filterclass();
    Filters.Branch = branch;
    Filters.FromDate = formattedfromDate;
    Filters.ToDate = formattedtoDate;
    Filters.Productfilter = Productfilter?.value;
    Filters.CategoryFilter = CategoryFilter?.value;
    Filters.Counter = TypeFilter?.value;
    Filters.Section = sectionval?.value;
    try {
      // Fetch stock-out data
      const responseStockOut = await getstockout({
        DatatablePagingParameters,
        Filters,
      });
      const stockOutData =
        responseStockOut?.data?.stockInReportDetail?.filter(
          (e) => e.branch.name === branch.name
        ) || [];
      // Fetch stock-in data
      const responseStockIn = await getstockin({
        DatatablePagingParameters,
        Filters,
      });
      const stockInData =
        responseStockIn?.data?.stockInReportDetail?.filter(
          (e) => e.branch.name === branch.name
        ) || [];

      const data = stockInData?.map((stockIn) => {
        let totalStock,
          totalStOut = 0;
        const product = stockIn.product.name;
        const totalStockIn = stockIn.totalStockIn;
        if (stockOutData.length > 0) {
          stockOutData?.map((stockout) => {
            if (stockout?.product?.name === product)
              totalStOut = stockout?.totalStockOut;
            totalStock = Number(totalStockIn) - Number(totalStOut);
          });
        } else {
          stockInData.map((stockout) => {
            if (stockout?.product?.name === product)
              totalStOut = stockout?.totalStockOut;
            totalStock = Number(totalStockIn);
          });
        }

        return {
          product: stockIn.product,
          type: stockIn?.type,
          category: stockIn?.category,
          totalStockIn,
          totalStockOut: totalStOut || 0,
          totalStock,
        };
      });

      setstockData(data);
      settotalcount(data.length || 0);
      setloading(false);
    } catch (error) {
      console.error("Error Fetching report!", error?.response?.data?.Message);
    }
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const menuItems = [
    { value: "1", label: "Product", checked: true },
    { value: "2", label: "Category", checked: false },
    { value: "3", label: "Type", checked: false },
    { value: "4", label: "Total Stock In", checked: true },
    { value: "5", label: "Total Stock Out", checked: true },
    { value: "6", label: "Total Stock", checked: true },
  ];

  const columns = [
    {
      title: "Product",
      key: "1",
      dataIndex: "product",
      render: (text) => <span>{text.name}</span>,
    },
    {
      title: "Category",
      key: "2",
      dataIndex: "category",
      render: (text) => <span>{text.name}</span>,
    },

    {
      title: "Type",
      key: "3",
      dataIndex: "type",
      render: (text) => <span>{text.name}</span>,
    },

    {
      title: "Total Stock In",
      key: "4",
      dataIndex: "totalStockIn",
    },
    {
      title: "Total Stock Out",
      key: "5",
      dataIndex: "totalStockOut",
    },
    {
      title: "Total Stock",
      key: "6",
      dataIndex: "totalStock",
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState();

  useEffect(() => {
    // Set default selected columns based on menuItems' checked status
    const initialSelectedColumns = menuItems
      ?.filter((item) => item.checked)
      ?.map((item) => columns.find((col) => col.key === item.value));

    setSelectedColumns(initialSelectedColumns);
  }, []);

  const handleCheckboxChange = (columnKey, checked) => {
    if (checked) {
      // Add column if checkbox is checked
      setSelectedColumns((prevColumns) => {
        const updatedColumns = [
          ...prevColumns,
          columns.find((col) => col.key === columnKey),
        ];

        // Sort the array by col.key in ascending order
        return updatedColumns.sort((a, b) => (a.key > b.key ? 1 : -1));
      });
    } else {
      // Remove column if checkbox is unchecked
      setSelectedColumns((prevColumns) =>
        prevColumns?.filter((col) => col.key !== columnKey)
      );
    }
  };

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);

    setToDate(null); // reset to date when from date changes
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const filteredData = stockData?.filter((obj) => {
    const searchMatch =
      obj.product.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
      obj.category.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
      obj.type.name?.toLowerCase().includes(searchText?.toLowerCase());

    const categoryMatch = CategoryFilter
      ? obj?.category?.name === CategoryFilter.label
      : true;
    const itemMatch = Productfilter
      ? obj?.product?.name === Productfilter.label
      : true;
    const typeMatch = TypeFilter ? obj?.type?.name === TypeFilter.label : true;

    let stockMatch = true;

    if (totalStockFilter) {
      if (totalStockFilter.value === "negetive") {
        stockMatch = obj?.totalStock < 0;
      } else if (totalStockFilter.value === "positive") {
        stockMatch = obj?.totalStock > 0;
      } else if (totalStockFilter.value === "zero") {
        stockMatch = obj?.totalStock === 0;
      } else {
        stockMatch = true;
      }
    }

    //

    return searchMatch && categoryMatch && itemMatch && typeMatch && stockMatch;
  });
  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create a column mapping (column title to dataIndex)
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map column title to its dataIndex
      });
      return mappings;
    };
    fieldMapping = createfieldMapping(columns);

    // Return the filtered data, columns, and field mapping
    return { data: filteredData, columns: selectedColumns, fieldMapping };
  };

  const grandStockOut = filteredData?.reduce(
    (sum, record) => sum + record.totalStockOut,
    0
  );
  const grandStockIn = filteredData?.reduce(
    (sum, record) => sum + record.totalStockIn,
    0
  );
  const grandStock = filteredData?.reduce(
    (sum, record) => sum + record.totalStock,
    0
  );
  const footerTotals = {
    totalStockOut: grandStockOut?.toFixed(2) || 0,
    totalStockIn: grandStockIn?.toFixed(2) || 0,
    totalStock: grandStock?.toFixed(2) || 0,
  };

  const handleReset = () => {
    setFromDate((prev) => dayjs());
    setToDate((prev) => dayjs());
    setSearchText("");
    setProductfilter("");
    setCategoryFilter("");
    setTypeFilter("");
    setsectionval("");
    setTotalStockFilter("");
    // handleStockInfullReport();
    handleStockInReport(currentPage, pageSize);
  };

  return (
    <div>
      <LoadingUtils loading={loading} />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Stock Summary Report</h4>
                <h6>Manage Stock Summary Report here</h6>
              </div>
            </div>

            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <button
                    onClick={() =>
                      exportPDF(getActiveTabData, footerTotals, title)
                    }
                    disabled={loading}
                    className="btn btn-link bg-white rounded-lg border border-silver"
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <button
                    onClick={() =>
                      exportExcel(getActiveTabData, footerTotals, title)
                    }
                    disabled={loading}
                    className="btn btn-link bg-white rounded-lg border border-silver"
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="excel-icon"
                    />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <button
                    onClick={() =>
                      printReport(getActiveTabData, footerTotals, title)
                    }
                    disabled={loading}
                    className="btn btn-link bg-white rounded-lg border border-silver"
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                  <div className="search-set">
                                                                <div className="search-input">
                                                                  <Link to="#" className="btn btn-searchset">
                                                                    <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      width={24}
                                                                      height={24}
                                                                      viewBox="0 0 24 24"
                                                                      fill="none"
                                                                      stroke="currentColor"
                                                                      strokeWidth={2}
                                                                      strokeLinecap="round"
                                                                      strokeLinejoin="round"
                                                                      className="feather feather-search"
                                                                    >
                                                                      <circle cx={11} cy={11} r={8} />
                                                                      <line x1={21} y1={21} x2="16.65" y2="16.65" />
                                                                    </svg>
                                                                  </Link>
                                                                  <div
                                                                    id="DataTables_Table_0_filter"
                                                                    className="dataTables_filter"
                                                                  >
                                                                    <label>
                                                                      {" "}
                                                                      <input
                                                                        type="search"
                                                                        className="form-control form-control-sm"
                                                                        placeholder="Search"
                                                                        aria-controls="DataTables_Table_0"
                                                                        value={searchText}
                                                                        onChange={(e) => setSearchText(e.target.value)}
                                                                      />
                                                                    </label>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                                   <div className="row">
                                                                <div className="col-md-6">
                                                                  <div className="input-blocks">
                                                                    <label htmlFor="from-date">From Date</label>
                                                                    <div className="input-groupicon calender-input">
                                                                      <Calendar className="info-img" />
                                                                      <DatePicker
                                                            className="datetimepicker"
                                                            id="from-date"
                                                            placeholderText="From Date"
                                                            // selected={fromDate}
                                                            value={fromDate}
                                                            onChange={handleFromDateChange}
                                                            startDate={fromDate}
                                                            selectsStart
                                                            allowClear
                                                            // dateFormat="dd/MM/yyyy"
                                                          />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                  <div className="input-blocks mr-2">
                                                                    <label htmlFor="to-date">To Date</label>
                                                                    <div className="input-groupicon calender-input">
                                                                      <Calendar className="info-img" />
                                                                      <DatePicker
                                                            id="to-date"
                                                            placeholderText="To Date"
                                                            // selected={toDate}
                                                            value={toDate}
                                                            onChange={handleToDateChange}
                                                            minDate={fromDate}
                                                            startDate={fromDate}
                                                            selectsEnd
                                                            allowClear
                                                            disabled={!fromDate} // disable until from date is selected
                                                            // dateFormat="dd/MM/yyyy"
                                                            // className='form-control'
                                                          />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="d-flex justify-content-end mt-3">
                                                                <div className="input-blocks me-2">
                                                                <Link
                                                                        className="btn btn-filters ms-auto"
                                                                        onClick={() => {
                                                                          setCurrentPage(1);
                                                                          setPageSize(20);
                                                                          // handleStockInfullReport();
                                                                          handleStockInReport(currentPage, pageSize);
                                                                        }}
                                                                      >
                                                                        <i data-feather="search" className="feather-search" />{" "}
                                                                        search{" "}
                                                                      </Link>
                                                                </div>
                                                                <div className="input-blocks">
                                                                  <button
                                                                    className="btn btn-reset rounded-2"
                                                                    onClick={handleReset}
                                                                  >
                                                                    <i data-feather="refresh-cw" className="feather-refresh-cw" /> Reset
                                                                  </button>
                                                                </div>
                                                              </div>
                {/* <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <div className="input-blocks me-2">
                    <button
                      className="btn btn-filters rounded-2 h-100 "
                      onClick={() => {
                        setCurrentPage(1);
                        setPageSize(20);
                        //  handleStockInfullReport();
                        handleStockInReport(currentPage, pageSize);
                      }}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search
                    </button>
                  </div>

                  <div className="input-blocks">
                    <button
                      className="btn btn-reset  rounded-2 "
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div> */}
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${
                      isFilterVisible ? "setclose" : ""
                    }`}
                    onClick={toggleFilterVisibility}
                    id="filter_search"
                  >
                    <Filter className="filter-icon" />
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>

                <div className="dropdown">
                  <button
                    className="btn  dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <Sliders className="info-img" />
                  </button>
                  <ul className="dropdown-menu p-2">
                    {menuItems
                      ?.filter((menu) => menu.label !== "Product")
                      ?.map((obj, i) => {
                        return (
                          <li key={i}>
                            <Checkbox
                              onChange={(e) =>
                                handleCheckboxChange(
                                  obj.value,
                                  e.target.checked
                                )
                              }
                              defaultChecked={obj.checked}
                            >
                              <div style={{ color: "black" }}> {obj.label}</div>
                            </Checkbox>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>

              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  {/* <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks ">
                        <label>From Date</label>
                        <div className="input-groupicon calender-input">
                          <Calendar className="info-img" />
                          <DatePicker
                            className="datetimepicker"
                            id="from-date"
                            placeholderText="From Date"
                            // selected={fromDate}
                            value={fromDate}
                            onChange={handleFromDateChange}
                            startDate={fromDate}
                            selectsStart
                            allowClear
                            // dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>To Date</label>
                        <div className="input-groupicon calender-input">
                          <Calendar className="info-img" />
                          <DatePicker
                            id="to-date"
                            placeholderText="To Date"
                            // selected={toDate}
                            value={toDate}
                            onChange={handleToDateChange}
                            minDate={fromDate}
                            startDate={fromDate}
                            selectsEnd
                            allowClear
                            disabled={!fromDate} // disable until from date is selected
                            // dateFormat="dd/MM/yyyy"
                            // className='form-control'
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <label>Total Stock</label>
                      <div className="input-blocks">
                        <Package className="info-img" />
                        <Select
                          className="img-select"
                          options={totalStockOptions}
                          value={totalStockFilter}
                          onChange={setTotalStockFilter}
                          classNamePrefix="react-select"
                          placeholder="Select"
                          isClearable
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 1050,
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Box className="info-img" />
                        <Select
                          className="img-select"
                          options={formatedproductList}
                          value={Productfilter}
                          onChange={setProductfilter}
                          classNamePrefix="react-select"
                          placeholder="Product"
                          isClearable
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 1050 }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <FileText className="info-img" />
                        <Select
                          className="img-select"
                          options={formattedCategoryList}
                          value={CategoryFilter}
                          onChange={setCategoryFilter}
                          classNamePrefix="react-select"
                          placeholder="Category"
                          isClearable
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 1050 }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <List className="info-img" />
                        <Select
                          className="img-select"
                          options={formattedTypeList}
                          value={TypeFilter}
                          onChange={setTypeFilter}
                          classNamePrefix="react-select"
                          placeholder="Type"
                          isClearable
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 1050 }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              {selectedColumns && (
                <div className="table-responsive product-list">
                  <Table
                    columns={selectedColumns}
                    dataSource={filteredData}
                    ref={tableRef}
                    id="table-to-export"
                    className="table datanew dataTable no-footer custom-table"
                    pagination={{
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${filteredData?.length} items`,
                      current: currentPage,
                      pageSize: pageSize,
                      total: filteredData?.length, // Total records count
                      // onChange: (page, size) => {
                      //   setCurrentPage(page);
                      //   setPageSize(size);
                      //   handleStockInReport(page, size);
                      // },
                      showSizeChanger: true, // To enable page size selection dropdown
                      pageSizeOptions: ["10", "20", "50", "100"], // Different page size options
                    }}
                    summary={() => (
                      <Table.Summary.Row>
                        {selectedColumns?.map((col) => (
                          <Table.Summary.Cell key={col.dataIndex}>
                            <b>{footerTotals[col.dataIndex] || ""}</b>
                          </Table.Summary.Cell>
                        ))}
                      </Table.Summary.Row>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};
export default StockSummary;
