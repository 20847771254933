import React, { useEffect, useRef, useState } from "react";
import { fetchUser, fetchUserByBranch } from "../../redux/userServices";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategorybyBranch,
  getCounterbyBranch,
  getGroupEntrybyBranch,
  getProductbyBranch,
  getSectionbyBranch,
  getUserbyBranch,
} from "../../services/CoreApiServices";
import { Trash2 } from "feather-icons-react/build/IconComponents";
import toast from "react-hot-toast";
import {
  branchTransfer,
  getAllEntityTypes,
} from "../../services/AdminApiServices";
import { BranchMapper } from "../../core/json/BranchMapper";
import {
  fetchCategory,
  fetchCounter,
  fetchGroup,
  fetchProduct,
  fetchSection,
} from "../../redux/userSetting";
import {
  fetchAllCategory,
  fetchAllCounter,
  fetchAllGroup,
  fetchAllSection,
} from "../../redux/getallmatser";

const AllBranchTransfer = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [desEntity, setdesEntity] = useState([]);
  const [entityList, setentityList] = useState([]);
  const [selectEntity, setselectEntity] = useState([]);
  const [desBranch, setdesBranch] = useState("");
  const [isSourceBranch, setisSourceBranch] = useState(true);
  const [sourceBranch, setsourceBranch] = useState("");
  let [loadingdes, setLoadingdes] = useState(false);
  let [loadingsrc, setLoadingsrc] = useState(false);
  const [entity, setentity] = useState("");
  const { branch } = useSelector((state) => state.branchlist);
  const { BranchByUser } = useSelector((state) => state.userBranch);
  const { UserList } = useSelector((state) => state.userservice);
  const { userId } = useSelector((state) => state.userauth);
  const { specialpermission } = useSelector((state) => state.amc);
  useEffect(() => {
    dispatch(fetchProduct(branch));
    handleEntity();
  }, [branch]);
  useEffect(() => {
    dispatch(fetchAllCategory());
    dispatch(fetchAllGroup());
    dispatch(fetchAllSection());
    dispatch(fetchAllCounter());
    dispatch(fetchUser());
  }, []);

  const { productList } = useSelector((state) => state.usersetting);
  const { categorygetall, countergetall, groupgetall, sectiongetall } =
    useSelector((state) => state.getallmaster);

  const handleEntityChange = async (e) => {
    setentity(e.target.value);
    setdesBranch("");
    setdesEntity();

    const permissionMap = {
      1: { name: "Category Branch Transfer", setEntity: categorygetall },
      2: { name: "Group Entry Branch Transfer", setEntity: groupgetall },
      3: { name: "Section Branch Transfer", setEntity: sectiongetall },
      4: { name: "Counter Branch Transfer", setEntity: countergetall },
      5: {
        name: "Product Branch Transfer",
        setEntity: [],
        isSourceBranch: false,
      },
      6: { name: "User Branch Transfer", setEntity: UserList },
    };

    const permission = permissionMap[e.target.value];

    if (permission) {
      const permit = specialpermission?.userSpecialPermissionList?.find(
        (perm) => perm.specialPermission.name === permission.name
      );

      if (permit?.userRole.id !== 0) {
        setselectEntity(permission.setEntity);
        setisSourceBranch(
          permission.isSourceBranch !== undefined
            ? permission.isSourceBranch
            : true
        );
      } else {
        toast(`No permission for ${permission.name}`);
      }
    } else {
      alert("Unknown option");
      setselectEntity();
      setisSourceBranch(true);
    }
  };

  // Call this function with the event object

  const handleAddSelect = (id) => {
    if (selectedData.includes(id)) {
      setSelectedData(selectedData?.filter((item) => item !== id));
    } else {
      setSelectedData([...selectedData, id]);
    }
  };
  const handleDestination = (e) => {
    setdesEntity();
    setdesBranch(e.target.value);
    switch (entity) {
      case "1":
        handleDescategory(e.target.value);

        break;
      case "2":
        handleDesgroup(e.target.value);
        break;
      case "3":
        handleDessection(e.target.value);

        break;
      case "4":
        handleDescounter(e.target.value);
        break;
      case "5":
        handleDesProduct(e.target.value);
        break;
      case "6":
        handleDesUser(e.target.value);
        break;
      default:
        toast.error("Please choose an Entity");

        break;
    }
  };
  const handleDescategory = async (id) => {
    try {
      const response = await getCategorybyBranch({ id });
      setdesEntity(response);
      setSelectedList();
    } catch (error) {
      //
    }
  };
  const handleDesgroup = async (id) => {
    try {
      const response = await getGroupEntrybyBranch({ id });
      setdesEntity(response);
      setSelectedList();
    } catch (error) {
      //
    }
  };
  const handleDessection = async (id) => {
    try {
      const response = await getSectionbyBranch({ id });
      setdesEntity(response);
      setSelectedList();
    } catch (error) {
      //
    }
  };
  const handleDescounter = async (id) => {
    try {
      const response = await getCounterbyBranch({ id });
      setdesEntity(response);
      setSelectedList();
    } catch (error) {
      //
    }
  };
  const handleDesProduct = async (id) => {
    try {
      const response = await getProductbyBranch({ id });
      setdesEntity(response);
      setSelectedList();
    } catch (error) {
      //
    }
  };
  const handleSourceProduct = async (id) => {
    setsourceBranch(id);
    try {
      const response = await getProductbyBranch({ id });
      setselectEntity(response);
      setSelectedList();
    } catch (error) {
      //
    }
  };
  const handleDesUser = async (id) => {
    try {
      const response = await getUserbyBranch({ id });
      setdesEntity(response);
      setSelectedList();
    } catch (error) {
      //
    }
  };
  const handleaddSelected = () => {
    if (!desBranch) {
      toast.error("please select destination");
    } else {
      // const newSelected = selectedData?.filter(e =>!desEntity?.some(obj =>obj.guid === e));
      const newSelected = selectedData?.filter((e) =>
        desEntity && desEntity?.length > 0
          ? !desEntity.some((obj) => obj.guid === e)
          : true
      );
      const newSelectedlist = selectEntity?.filter((user) =>
        newSelected?.includes(user.guid)
      );
      setSelectedList(newSelectedlist);
    }
  };
  const handleAddAll = () => {
    if (!desBranch) {
      toast.error("Please select a destination");
    } else {
      const newSelected = selectEntity?.filter((e) =>
        desEntity?.length > 0
          ? !desEntity.some((obj) => obj.guid === e.guid)
          : true
      );
      setSelectedList(newSelected);
    }
  };
  const handleDelete = (id) => {
    const updatedList = selectedList?.filter((e) => e.guid !== id);
    setSelectedList(updatedList);
  };

  const handleEntity = async () => {
    try {
      const response = await getAllEntityTypes();
      setentityList(response);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const handleBranchTranfer = async () => {
    const destinationBranch = BranchByUser?.find(
      (e) => e.branch?.guid == desBranch
    ).branch;
    const SelectedEntity = selectedList?.map((e) => e.guid);

    const mapperobj = new BranchMapper();

    const SourceBranch =
      entity == 5
        ? BranchByUser?.find((e) => e.branch?.guid === sourceBranch)?.branch
        : branch;
    mapperobj.EntityID = entity;
    mapperobj.EntityType = entityList?.find(
      (e) => e.entityID == entity
    ).entityType;
    mapperobj.SelectedEntities = SelectedEntity;
    mapperobj.SourceBranch = SourceBranch;
    mapperobj.DestinationBranch = destinationBranch;
    mapperobj.updatedUser = `{${userId?.id}}`;
    mapperobj.createdUser = `{${userId?.id}}`;
    try {
      const response = await branchTransfer(mapperobj);
      if (response == true) {
        toast.success("success");
        handleClose();
        switch (entity) {
          case "1":
            dispatch(fetchCategory(branch.guid));
            break;
          case "2":
            dispatch(fetchGroup(branch.guid));
            break;
          case "3":
            dispatch(fetchSection(branch.guid));
            break;
          case "4":
            dispatch(fetchCounter(branch.guid));
            break;
          case "5":
            dispatch(fetchProduct(branch));
            break;
          case "6":
            dispatch(fetchUserByBranch(branch.guid));
            break;
          default:
            toast.error("Nothing Transfered");

            break;
        }
      } else if (response == false) {
        toast.error("false");
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Branch Transfer</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="col-12 align-items-center justify-content-between">
                  <div className="modal-body custom-modal-body d-block">
                    <div className="row mb-4">
                      <label htmlFor="">Entity</label>
                      <select
                        name=""
                        id=""
                        className="form-control"
                        value={entity}
                        onChange={handleEntityChange}
                      >
                        <option value="" disabled key="">
                          ---Select Entity---
                        </option>
                        {entityList &&
                          entityList?.map((obj) => {
                            return (
                              <option value={obj.entityID} key={obj.entityID}>
                                {obj?.entityType}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-12 col-lg-4">
                        <label htmlFor="">Source Branch</label>
                        <select
                          name=""
                          id=""
                          className="form-control"
                          disabled={isSourceBranch}
                          onChange={(e) => handleSourceProduct(e.target.value)}
                          value={sourceBranch}
                        >
                          <option value="" disabled key="">
                            ---Select Source Branch---
                          </option>
                          {BranchByUser?.map((obj) => {
                            return (
                              <option value={obj.branch.guid} key={obj.id}>
                                {obj?.branch.name}
                              </option>
                            );
                          })}
                        </select>
                        <table className="table">
                          <thead>
                            <th>Entity</th>
                          </thead>
                          <div
                            style={{ maxHeight: "200px", overflow: "scroll" }}
                          >
                            <tbody className="col-12">
                              {selectEntity &&
                                selectEntity?.map((obj) => {
                                  return (
                                    <tr key={obj?.guid}>
                                      <td
                                        className={
                                          selectedData.includes(obj?.guid)
                                            ? "branch-transfer-active"
                                            : "branch-transfer"
                                        }
                                        onClick={() =>
                                          handleAddSelect(obj?.guid)
                                        }
                                      >
                                        {obj.name}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </div>
                        </table>
                      </div>

                      <div className="col-md-6 col-sm-12 col-lg-4">
                        <div>
                          <button
                            className="btn btn-submit"
                            onClick={handleaddSelected}
                          >
                            Add Selected
                          </button>
                        </div>
                        <div className="mt-3">
                          <button
                            className="btn btn-submit"
                            onClick={handleAddAll}
                          >
                            Add All
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 col-lg-4">
                        <label htmlFor="">Destination Branch</label>
                        <select
                          name=""
                          id=""
                          className="form-control"
                          onChange={handleDestination}
                          value={desBranch}
                        >
                          <option value="" disabled key="">
                            ---Select Destination Branch---
                          </option>
                          {BranchByUser?.map((obj) => {
                            return (
                              <option value={obj?.branch?.guid} key={obj?.id}>
                                {obj?.branch?.name}
                              </option>
                            );
                          })}
                        </select>

                        <table className="table">
                          <thead>
                            <th>Entity</th>
                          </thead>
                          <div
                            style={{ maxHeight: "200px", overflow: "scroll" }}
                          >
                            <tbody className="col-12">
                              {selectedList?.map((obj) => {
                                return (
                                  <tr
                                    key={obj?.guid}
                                    className="branch-transfer"
                                  >
                                    <td>{obj.name}</td>
                                    <td
                                      style={{ fontSize: "10px", color: "red" }}
                                      onClick={() => handleDelete(obj?.guid)}
                                    >
                                      <Trash2
                                        style={{
                                          fontSize: "inherit",
                                          color: "inherit",
                                        }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                              {desEntity &&
                                desEntity?.map((obj) => {
                                  return (
                                    <tr
                                      key={obj?.guid}
                                      className="branch-transfer"
                                    >
                                      <td>{obj?.name}</td>
                                      <td> </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </div>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn mb-4">
                      {entity == 5 && sourceBranch === desBranch && (
                        <div className="alert alert-warning mt-3 h1">
                          Source and destination branches cannot be the same.
                        </div>
                      )}
                      {!selectedList?.length && sourceBranch !== desBranch && (
                        <div className="alert alert-warning mt-3 h1">
                          Please select item to transfer.
                        </div>
                      )}
                      <button
                        type="submit"
                        className="btn btn-submit"
                        onClick={handleBranchTranfer}
                        disabled={
                          sourceBranch === desBranch || !selectedList?.length
                        }
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllBranchTransfer;
