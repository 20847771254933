import { Table } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  AddProductBOQ,
  getProductBOQIngredients,
  getProductBoqListView,
} from "../../../services/MasterApiServices";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { InvProduction } from "../../../core/json/invProduction";
import { InvProductionDetail } from "../../../core/json/invProductionDetail";
import { saveproduction } from "../../../services/StockApiServices";

const AddProduction = ({
  mode,
  updateData,
  productionData,
  ProductBOQData,
  handleClose,
  onsuccess,
}) => {
  const [ingredients, setIngredients] = useState([]);
  const { unitList, productList } = useSelector((state) => state.usersetting);
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  console.log(updateData);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    control,
    reset,
    setFocus,
    formState: { errors },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "row",
  });

  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };

  const handlesave = async (formData) => {
    console.log(formData); // Handle form submission
    const objproduct = new InvProduction();
    objproduct.Date = dayjs(formData.date).format("YYYY-MM-DD");
    objproduct.Cost = ProductBOQData?.find(
      (e) => e.guid == formData.product
    )?.cost;
    objproduct.Branch = branch;
    objproduct.Quanitity = Number(formData?.quantity);
    objproduct.Entryno = formData?.entrynumber;
    objproduct.Unit = unitList?.find((e) => e.guid == formData.unit);
    objproduct.Product = ProductBOQData?.find(
      (e) => e.guid == formData.product
    );
    objproduct.updatedUser = `{${userId?.id}}`;
    objproduct.createdUser = `{${userId?.id}}`;
    objproduct.id = mode == "Edit" ? updateData?.id : 0;
    objproduct.guid = mode == "Edit" ? updateData?.guid : "";

    for (const row of formData.row) {
      const objproductdetail = new InvProductionDetail();

      objproductdetail.Product = productList?.find(
        (e) => e.guid == row.product
      );
      objproductdetail.ProductQty = Number(row.totalquantity);
      objproductdetail.Unit = unitList?.find((e) => e.guid == row.unit);
      objproductdetail.Cost = Number(row.cost);
      objproductdetail.id = Number(row.id);
      objproductdetail.guid = row.guid;
      objproductdetail.updatedUser = `{${userId?.id}}`;
      objproductdetail.createdUser = `{${userId?.id}}`;
      objproduct.invProductionDetails.push(objproductdetail);
    }

    try {
      const response = await saveproduction(objproduct);
      if (response == true) {
        onsuccess();
        if (mode == "Add") {
          toast.success("success");
          handlenew(formData?.entrynumber);
          reset();
        } else {
          handleClose();
          toast.success("Updated successfuly");
        }
      } else {
        toast.success(response.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling category:", error?.response?.data?.Message);
    } finally {
      handlenew(formData?.entrynumber);
    }
  };
  const handlenew = (num) => {
    let counter = parseInt(num.replace(/\D/g, "")) + 1;
    setValue(`entrynumber`, `WP${counter}`);
    setValue(`product`, "");
    setValue("unit", "");
    setValue("quantity", "");
    remove();
  };
  useEffect(() => {
    if (mode == "Add") {
      handleEntryNo();
    } else {
      handleEdit();
      setValue(`product`, updateData?.product.guid);
      handleinvProductBoqList(updateData?.product.guid);
      const unitdata = ProductBOQData?.find(
        (e) => e.guid == updateData?.product.guid
      );
      setValue("unit", unitdata?.unit?.guid);
      setValue("quantity", updateData?.quanitity);
      setValue("date", updateData?.date);
    }
    handleIngredients();
  }, [mode, updateData]);
  const handleEdit = () => {
    setValue(`entrynumber`, updateData.entryno);
  };
  useEffect(() => {
    const timeout = setTimeout(() => setFocus("product"), 100);
    return () => clearTimeout(timeout);
  }, []);

  const handleIngredients = async () => {
    try {
      const response = await getProductBOQIngredients(branch);
      if (Array.isArray(response)) {
        setIngredients(response);
      } else {
        setIngredients([]); // If response is not an array, reset to an empty array
      }
    } catch (error) {
      console.log(error?.response?.data?.Message || "something went wrong");
      setIngredients([]);
    }
  };

  const handleproduct = (val) => {
    setValue(`product`, val.target.value);
    const data = ProductBOQData?.find((e) => e.guid == val.target.value);
    setValue("unit", data?.unit?.guid);
    handleinvProductBoqList(val.target.value);
    console.log(updateData?.product.guid, val.target.value);

    if (mode == "Edit" && updateData?.product.guid == val.target.value) {
      setValue("quantity", updateData?.quanitity);
    } else {
      setValue("quantity", "");
    }
  };
  const handleinvProductBoqList = async (id) => {
    try {
      const response = await getProductBoqListView(branch);
      if (Array.isArray(response)) {
        const boq = response?.find((e) => e.product.guid == id);

        if (boq?.productBOQDetails?.length > 0) {
          // Map the productBOQDetails if available
          const boqdata = boq?.productBOQDetails?.map((e, i) => ({
            id: e.id,
            product: e.product.guid,
            unit: e.unit.guid,
            quantity: (e.quantity / boq.totalqty || 1)?.toFixed(2),
            wastage: e.wastagePercentage,
            Cfactor: e.conversionFactor,
            RndTo: false, // Assuming this flag is set to false by default
            cost: e.cost,
            tcost:
              e.quantity * e.conversionFactor * e.cost +
              (e.quantity * e.conversionFactor * e.cost * e.wastagePercentage) /
                100,
            totalquantity:
              mode == "Edit"
                ? updateData?.invProductionDetails[i]?.productQty
                : 0,
          }));

          append(boqdata);
          setValue("row", boqdata);
        } else {
          remove();
        }
      }
    } catch (error) {
      console.log(error?.response?.data?.Message);
    }
  };

  const handleEntryNo = async () => {
    try {
      const maxEntryNumber =
        productionData?.length > 0
          ? productionData
              .map((data) => parseInt(data.entryno.replace(/\D/g, ""))) // Extract and parse numbers
              .reduce((max, num) => Math.max(max, num), 0) // Find the maximum number
          : 0;

      let counter = maxEntryNumber + 1;
      setValue(`entrynumber`, `WP${counter}`);
      counter++;
    } catch (error) {
      console.error("Failed to fetch purchase invoices", error);
    }
  };

  const handlequantity = (val) => {
    setValue("quantity", val);
    fields?.map((field, i) => {
      const fieldQuantity = Number(field.quantity) || 1; // Ensure quantity exists in the field
      const q = val * fieldQuantity;

      setValue(`row[${i}].totalquantity`, Number(q)?.toFixed(2));
    });
  };

  return (
    <div>
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title"></div>
                  <button
                    type="button"
                    className="close"
                    // aria-label="Close"
                    // data-bs-dismiss="modal"
                    // ref={closeButtonRef}
                    onClick={handleClose}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <h4 className="mb-2">{mode} Production</h4>
                  <form onSubmit={handleSubmit(handlesave)}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Entry Number<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="entrynumber"
                            disabled
                            {...register("entrynumber")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Date<span className="text-danger">*</span>
                          </label>
                          <Controller
                            control={control}
                            name={`date`}
                            render={({ field: { onChange, value } }) => (
                              <DatePicker
                                disabled={mode == "View"}
                                className="datetimepicker"
                                placeholder={value ? dayjs(value) : dayjs()}
                                value={value ? dayjs(value) : dayjs()}
                                maxDate={dayjs()}
                                format="DD-MM-YYYY"
                                onChange={(date) => onChange(date)}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Product <span className="text-danger">*</span>
                          </label>

                          <select
                            {...register("product", { required: true })}
                            className="form-control"
                            disabled={mode == "View"}
                            onChange={(val) => {
                              // ensure the field value is updated
                              handleproduct(val); // handle custom logic
                            }}
                          >
                            <option value="">Select product</option>
                            {ProductBOQData?.map((product) => (
                              <option key={product.guid} value={product.guid}>
                                {product.name}
                              </option>
                            ))}
                          </select>

                          {/* Display error message for required validation */}
                          {errors?.product?.type === "required" && (
                            <span className="text-danger">
                              Product is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Quantity<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="quantity"
                            disabled={mode == "View"}
                            {...register("quantity", {
                              required: "Quantity is required",
                            })}
                            onChange={(e) => handlequantity(e.target.value)}
                          />
                          {errors.quantity && (
                            <span className="text-danger">
                              {errors.quantity.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Unit<span className="text-danger">*</span>
                          </label>
                          <Controller
                            control={control}
                            name={`unit`}
                            render={({ field }) => (
                              <select
                                {...field}
                                className="form-control"
                                disabled
                              >
                                <option value="">Select unit</option>
                                {unitList &&
                                  unitList?.map((unit) => (
                                    <option key={unit.guid} value={unit.guid}>
                                      {unit.name}
                                    </option>
                                  ))}
                              </select>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Table */}
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Unit</th>

                          <th>Base Quantity</th>
                          <th>Wastage %</th>
                          <th>C.factor</th>

                          <th>Cost</th>
                          <th>T.cost</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields?.map((row, index) => (
                          <tr key={row.id}>
                            <td>
                              <Controller
                                control={control}
                                name={`row[${index}].product`}
                                rules={{
                                  required: true,
                                  validate: (value) => {
                                    const selectedProducts = watch("row")?.map(
                                      (row) => row.product
                                    ); // Get all selected products
                                    const isDuplicate =
                                      selectedProducts.filter(
                                        (prod) => prod === value
                                      )?.length > 1;
                                    return (
                                      !isDuplicate ||
                                      "Duplicate product selected!"
                                    );
                                  },
                                }}
                                render={({ field }) => (
                                  <>
                                    <select
                                      {...field}
                                      className="form-control"
                                      disabled
                                    >
                                      <option value="">Select product</option>
                                      {ingredients?.map((product) => (
                                        <option
                                          key={product.guid}
                                          value={product.guid}
                                        >
                                          {product.name}
                                        </option>
                                      ))}
                                    </select>

                                    {errors?.row?.[index]?.product?.message && (
                                      <span className="text-danger">
                                        {errors.row[index].product.message}
                                      </span>
                                    )}
                                  </>
                                )}
                              />
                            </td>
                            <td>
                              <Controller
                                control={control}
                                name={`row[${index}].unit`}
                                render={({ field }) => (
                                  <select
                                    {...field}
                                    className="form-control"
                                    disabled
                                  >
                                    <option value="">Select unit</option>
                                    {unitList &&
                                      unitList?.map((unit) => (
                                        <option
                                          key={unit.guid}
                                          value={unit.guid}
                                        >
                                          {unit.name}
                                        </option>
                                      ))}
                                  </select>
                                )}
                              />
                            </td>

                            <td>
                              <input
                                type="number"
                                min={0}
                                step="any"
                                maxLength={7}
                                className="form-control"
                                value={watch(`row[${index}].quantity`) || ""} // Bind the value with watch
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                step="any"
                                min={0}
                                max={100}
                                maxLength={3}
                                className="form-control"
                                value={watch(`row[${index}].wastage`)} // Bind the value with watch
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                min={0}
                                step="any"
                                className="form-control"
                                maxLength={7}
                                value={watch(`row[${index}].Cfactor`) || ""} // Bind the value with watch
                                disabled
                              />
                            </td>

                            <td>
                              <input
                                type="number"
                                step="any"
                                min={0}
                                className="form-control"
                                {...register(`row[${index}].cost`)}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                step="any"
                                min={0}
                                className="form-control"
                                {...register(`row[${index}].tcost`)}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                step="any"
                                min={0}
                                className="form-control"
                                {...register(`row[${index}].totalquantity`)}
                                disabled
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {/* <div className="col-lg-2 float-right">
                      {watch()?.row && (
                        <>
                         
                          {(() => {
                            let totalSum = watch().row.reduce((acc, e) => acc + (e.tcost || 0), 0);
                            return (
                              <div className='col-12'>
                               
                                <p>Total cost: <b>{totalSum}</b> </p>
                              </div>
                            );
                          })()}
                        </>
                      )}
                    </div> */}
                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        {mode !== "View" && (
                          <button type="submit" className="btn btn-save">
                            Save
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddProduction;
