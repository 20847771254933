import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUser,
  getUserbyBranch,
  userService,
} from "../services/CoreApiServices";

export const fetchUserServices = createAsyncThunk(
  "filters/fetchUserServices",
  async (id) => {
    const data = await userService({ id });

    return data;
  }
);
export const fetchUserByBranch = createAsyncThunk(
  "filters/fetchUserByBranch",
  async (id) => {
    const data = await getUserbyBranch({ id });
    return data;
  }
);
export const fetchUser = createAsyncThunk("filters/fetchUser", async () => {
  const data = await getUser();
  return data;
});
const listUserService = createSlice({
  name: "UserService",
  initialState: {
    UserServicelist: [],
    UserListByBranch: [],
    UserList: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserServices.fulfilled, (state, action) => {
        state.UserServicelist = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserByBranch.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserByBranch.fulfilled, (state, action) => {
        state.UserListByBranch = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserByBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.UserList = action.payload;
        state.loading = false;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default listUserService.reducer;
