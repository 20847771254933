import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { UserRole } from "../../../core/json/UserRole";
import { useDispatch, useSelector } from "react-redux";
import {
  adduserRole,
  updateuserRole,
} from "../../../services/AdminApiServices";
import { fetchUserRole } from "../../../redux/userSetting";

const AdduserRole = ({ mode, data, handleClose, levels }) => {
  const { register, handleSubmit, setValue, setFocus } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.userauth);

  useEffect(() => {
    if (mode === "edit" && data) {
      setValue("name", data.name);
      setValue("level", data.level);
      setValue("active", data.active);
    }
  }, [data, mode]);
  useEffect(() => {
    const timeout = setTimeout(() => setFocus("name"), 0);
    return () => clearTimeout(timeout);
  }, []);
  const handleFormSubmit = async (formData) => {
    const objRole = new UserRole();
    objRole.name = formData.name;
    objRole.Level = formData.level;
    objRole.Active = formData.active;
    objRole.updatedUser = `{${userId?.id}}`;
    if (mode === "edit") {
      objRole.guid = data.guid;
      objRole.id = data.id;
    }
    try {
      const response =
        mode === "edit"
          ? await updateuserRole(objRole)
          : await adduserRole(objRole);

      if (response) {
        toast.success("Success");

        handleClose();
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling Account Type:", error);
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {mode === "edit" ? "Edit User Role" : "Add User Role"}
                    </h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleClose}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="name"
                            {...register("name")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Level <span className="text-danger">*</span>
                          </label>
                          <select
                            name=""
                            id=""
                            {...register("level")}
                            className="form-control"
                          >
                            <option value="">select</option>
                            {levels?.map((obj) => {
                              return (
                                <option value={obj} key={obj}>
                                  {obj}
                                </option>
                              );
                            })}
                            {mode == "edit" && (
                              <option value={data.level} key={data.level}>
                                {data.level}
                              </option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mt-lg-4">
                        <div className="input-blocks d-flex">
                          <input
                            type="checkbox"
                            id="name"
                            {...register("active")}
                          />
                          <label className="m-2">Is Active</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-submit">
                          {mode === "edit" ? "Save Changes" : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdduserRole;
