import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { SupplierClass } from "../../../core/json/SupplierClass";
import {
  addNewSupplier,
  editSupplier,
  getstates,
  getstatesByGuid,
} from "../../../services/MasterApiServices";
import { useDispatch, useSelector } from "react-redux";
import { Watch } from "feather-icons-react/build/IconComponents";
import { fetchSupplier } from "../../../redux/userSetting";

const SupplierModal = ({ mode, data, handleClose, handleRefresh }) => {
  //get branch from redux
  const { branch } = useSelector((state) => state.branchlist);

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  function resetmodal() {
    if (mode === "edit" && data) {
      setValue("fullName", data.fullName);

      setValue("name", data.name);

      setValue("code", data.code);

      setValue("ledger", data.ledger);

      setValue("address1", data.address1);

      setValue("address2", data.address2);

      setValue("address3", data.address3);

      setValue("mobile", data.mobile);

      setValue("phone", data.phone);

      setValue("fax", data.fax);

      setValue("email", data.email);

      setValue("webSite", data.webSite);

      setValue("tin", data.tin);

      setValue("creditDays", data.creditDays);

      setValue("state", data.state.guid);

      setValue("contactPerson1", data.contactPerson1);

      setValue("contactPerson2", data.contactPerson2);

      setValue("contactPerson3", data.contactPerson3);

      setValue("contactNumber1", data.contactNumber1);

      setValue("contactNumber2", data.contactNumber2);

      setValue("contactNumber3", data.contactNumber3);
    } else {
      reset();
    }

    fetchStates();
  }

  useEffect(() => {
    resetmodal();
  }, [data, mode, setValue]);

  const fetchStates = async () => {
    try {
      const response = await getstates();
      setStates(response);
    } catch (error) {
      console.error(
        "Error fetching states:",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  // Handle form submission for adding or editing a supplier
  const handleFormSubmit = async (formData) => {
    const objsupplier = new SupplierClass();
    objsupplier.code = formData.code;
    objsupplier.guid = mode == "edit" ? data.guid : "";
    objsupplier.id = mode == "edit" ? data.id : 0;
    objsupplier.code = formData.code;
    objsupplier.name = formData.name;
    objsupplier.fullName = formData.fullName;
    objsupplier.ledger = formData.name;
    objsupplier.address1 = formData.address1;
    objsupplier.address2 = formData.address2;
    objsupplier.address3 = formData.address3;
    objsupplier.mobile = formData.mobile;
    objsupplier.phone = formData.phone;
    objsupplier.fax = formData.fax;
    objsupplier.email = formData.email;
    objsupplier.webSite = formData.webSite;
    objsupplier.creditDays = Number(formData.creditDays);
    objsupplier.tin = formData.tin;
    objsupplier.contactPerson1 = formData.contactPerson1;
    objsupplier.contactPerson2 = formData.contactPerson2;
    objsupplier.contactPerson3 = formData.contactPerson3;
    objsupplier.contactNumber1 = formData.contactNumber1;
    objsupplier.contactNumber2 = formData.contactNumber2;
    objsupplier.contactNumber3 = formData.contactNumber3;
    objsupplier.state = states?.find((e) => e.guid == formData.state);
    objsupplier.updatedUser = `{${userId?.id}}`;
    objsupplier.createdUser = `{${userId?.id}}`;
    objsupplier.branch = branch;
    if (mode === "edit") {
      objsupplier.guid = data.guid;
      objsupplier.id = data.id;
    }

    try {
      const response = await addNewSupplier(objsupplier);

      if (response.data == true) {
        toast.success("Success");
        dispatch(fetchSupplier());
        handleRefresh(); // Refresh the list
        handleClose(); // Close the modal
      } else if (response.data == false) {
        toast.error("false");
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message || "something went wrong");
      console.error(
        "Error handling :",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog  modal-dialog-centered  ">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {mode === "edit" ? "Edit Supplier" : "Add Supplier"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>
                            Code<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="code"
                            {...register("code", { required: true })}
                          />
                          {errors.code?.type == "required" && (
                            <span className="text-danger">
                              Code is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 ">
                        <div className="input-blocks">
                          <label>
                            Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Name"
                            {...register("name", { required: true })}
                          />
                          {errors.name?.type == "required" && (
                            <span className="text-danger">
                              Name is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>FullName</label>
                          <input
                            type="text"
                            placeholder="Full Name"
                            {...register("fullName")}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Address 1</label>
                          <input
                            type="text"
                            placeholder="Address1"
                            {...register("address1")}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Address 2</label>
                          <input
                            type="text"
                            placeholder="Address 2"
                            {...register("address2")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Address 3</label>
                          <input
                            type="text"
                            placeholder="Address 3"
                            {...register("address3")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 ">
                        <div className="input-blocks">
                          <label>
                            State<span className="text-danger">*</span>
                          </label>
                          <select
                            {...register("state", { required: true })} // Register with validation rules
                            value={watch("state") || data?.state?.guid || ""} // `watch` is from react-hook-form to track the current form state
                            onChange={(e) => setValue("state", e.target.value)} // `setValue` updates the form state manually
                            className="form-control" // Bootstrap class for styling
                          >
                            <option value="">Select State</option>
                            {states.map((state) => (
                              <option key={state.guid} value={state.guid}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                          {errors.state?.type == "required" && (
                            <span className="text-danger">
                              State is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 ">
                        <div className="input-blocks">
                          <label>
                            Mobile<span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="123456789"
                            {...register("mobile", {
                              pattern: {
                                value: /^\+?[1-9]\d{1,14}$/,
                                message: "Enter a valid phone number",
                              },
                              required: true,
                            })}
                          />
                          {errors?.mobile?.type === "pattern" && (
                            <span className="text-danger">
                              Enter a valid phone number
                            </span>
                          )}
                          {errors?.mobile?.type === "required" && (
                            <span className="text-danger">
                              Mobile is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Phone</label>
                          <input
                            className="form-control"
                            type="tel"
                            placeholder="123456789"
                            {...register("phone", {
                              pattern: {
                                value: /^\+?[1-9]\d{1,14}$/,
                                message: "Enter a valid phone number",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Fax</label>
                          <input
                            type="text"
                            placeholder="Fax"
                            {...register("fax")}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Email</label>
                          <input
                            type="text"
                            id="name"
                            {...register("email", {
                              required: false,
                              pattern:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                          />
                          {errors?.email?.type === "pattern" && (
                            <span className="text-danger">
                              Email is not valid
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Website</label>

                          <input
                            type="text"
                            id="name"
                            {...register("webSite", {
                              pattern:
                                /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/,
                            })}
                          />
                          {errors?.webSite?.type === "pattern" && (
                            <span className="text-danger">
                              Enter valid website
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Credit Days</label>
                          <input
                            className="form-control"
                            type="number" // Change type to "number" for better UX with numeric input
                            placeholder="Credit Days"
                            defaultValue={0} // Set default value to 0
                            {...register("creditDays")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Tin</label>
                          <input
                            type="text"
                            placeholder="Tin No"
                            {...register("tin")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Contact Person 1</label>
                          <input
                            type="text"
                            placeholder="Contact Person 1"
                            {...register("contactPerson1")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Contact Number 1</label>
                          <input
                            className="form-control"
                            type="tel"
                            placeholder="123456789"
                            {...register("contactNumber1", {
                              pattern: {
                                value: /^\+?[1-9]\d{1,14}$/,
                                message: "Enter a valid phone number",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Contact Person 2</label>
                          <input
                            type="text"
                            placeholder="Contact Person 2"
                            {...register("contactPerson2")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Contact Number 2</label>
                          <input
                            className="form-control"
                            type="tel"
                            placeholder="123456789"
                            {...register("contactNumber2", {
                              pattern: {
                                value: /^\+?[1-9]\d{1,14}$/,
                                message: "Enter a valid phone number",
                              },
                            })}
                          />
                          {errors.contactNumber2 && (
                            <p className="text-danger">
                              {errors.contactNumber2.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Contact Person 3</label>
                          <input
                            type="text"
                            placeholder="Contact Person 3"
                            {...register("contactPerson3")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Contact Number 3</label>
                          <input
                            className="form-control"
                            type="tel"
                            placeholder="1234567890"
                            {...register("contactNumber3", {
                              pattern: {
                                value: /^\+?[1-9]\d{1,14}$/,
                                message: "Enter a valid phone number",
                              },
                            })}
                          />
                          {errors.contactNumber3 && (
                            <p className="text-danger">
                              {errors.contactNumber3.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="modal-footer-btn">
                          <button
                            type="button"
                            className="btn btn-cancel me-2"
                            onClick={() => resetmodal()}
                          >
                            Reset
                          </button>
                          <button type="submit" className="btn btn-submit">
                            {mode === "edit" ? "Save Changes" : "save"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierModal;
