import { RefreshCw } from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getSalesReport } from "../../services/ReportApiService";
import Table from "../../core/pagination/datatable";
import { Pagination } from "antd";

function StockOverReport() {
  const [stockOverData, setStockOverData] = useState([]);
  useEffect(() => {
    handleSaleReportList();
  }, []);

  const handleSaleReportList = async () => {
    try {
      const response = await getSalesReport();
      setStockOverData(response);
    } catch (error) {
      console.log();
    }
  };

  const columns = [
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      sorter: (a, b) => a.Name?.length - b.Name?.length,
    },
    {
      title: "Section",
      dataIndex: "name",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Bill No",
      dataIndex: "billNo",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },

    {
      title: "Gross Total",
      dataIndex: "grossTotal",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Net Total",
      dataIndex: "netTotal",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>StockOver Report</h4>
              </div>
            </div>
            <ul className="table-top-head"></ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn"></div>
              <div className="page-btn import">
                <Link
                  to="#"
                  className="btn btn-added color"
                  data-bs-toggle="modal"
                  data-bs-target="#view-notes"
                >
                  <RefreshCw className="me-2" />
                  Refresh
                </Link>
              </div>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-responsive product-list">
                <Table columns={columns} dataSource={stockOverData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination />
    </div>
  );
}

export default StockOverReport;
