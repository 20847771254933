import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import "bootstrap-daterangepicker/daterangepicker.css";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SectionWiseSale from "../../components/dashboard/SectionWiseSale";
import ProductWiseSale from "../../components/dashboard/ProductWiseSale";
import WaiterWiseSale from "../../components/dashboard/WaiterWiseSale";
import DailySale from "../../components/dashboard/DailySale";
import TopFiveSale from "../../components/dashboard/TopFiveSale";
import LeastFiveSale from "../../components/dashboard/LeastFiveSale";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSectionDateWiseSale,
  fetchSectionWiseSale,
} from "../../redux/sectionwisesale";
import { fetchProductWiseSale } from "../../redux/productwisesale";
import { fetchWaiterWiseSale } from "../../redux/waiterwisesale";
import { fetchDailySale } from "../../redux/dailysale";
import {
  fetchTop5CategoryWiseSale,
  fetchTop5ItemWiseSale,
  fetchTop5WaiterWiseSale,
} from "../../redux/topfivesale";
import {
  fetchLeast5CategoryWiseSale,
  fetchLeast5ItemWiseSale,
  fetchLeast5WaiterWiseSale,
} from "../../redux/leastfivesale";
import $ from "jquery";
import "daterangepicker";
import "daterangepicker/daterangepicker.css";
import moment from "moment";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [dateFilter, setdateFilter] = useState(3);
  const [StartingDate, setStartingDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [EndingDate, setEndingDate] = useState(moment().format("YYYY-MM-DD"));

  const { branch } = useSelector((state) => state.branchlist);
  const { sectionSale, sectiondateSale } = useSelector(
    (state) => state.sectionSale
  );

  useEffect(() => {
    dispatch(fetchSectionWiseSale({ branch, StartingDate, EndingDate }));
    dispatch(fetchSectionDateWiseSale({ branch, StartingDate, EndingDate }));
    dispatch(fetchProductWiseSale({ branch, StartingDate, EndingDate }));
    dispatch(fetchWaiterWiseSale({ branch, StartingDate, EndingDate }));
    dispatch(fetchDailySale({ branch, StartingDate, EndingDate }));
    dispatch(fetchTop5WaiterWiseSale({ branch, StartingDate, EndingDate }));
    dispatch(fetchTop5ItemWiseSale({ branch, StartingDate, EndingDate }));
    dispatch(fetchTop5CategoryWiseSale({ branch, StartingDate, EndingDate }));
    dispatch(fetchLeast5WaiterWiseSale({ branch, StartingDate, EndingDate }));
    dispatch(fetchLeast5ItemWiseSale({ branch, StartingDate, EndingDate }));
    dispatch(fetchLeast5CategoryWiseSale({ branch, StartingDate, EndingDate }));
  }, [branch, StartingDate, EndingDate]);

  useEffect(() => {
    const start = moment();
    const end = moment();

    function cb(start, end) {
      $("#reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
      const formattedStartDate = start.format("YYYY-MM-DD");
      const formattedEndDate = end.format("YYYY-MM-DD");

      setStartingDate(formattedStartDate);
      setEndingDate(formattedEndDate);
      handleDateRange({ formattedStartDate, formattedEndDate });
    }

    $("#reportrange").daterangepicker(
      {
        startDate: start,
        endDate: end,
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
        },
      },
      cb
    );

    cb(start, end);
  }, []);

  const handleDateRange = ({ formattedStartDate, formattedEndDate }) => {
    const selecteddate = [formattedStartDate, formattedEndDate];
    const Today = [
      moment().format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    ];
    const Yesterday = [
      moment().subtract(1, "days").format("YYYY-MM-DD"),
      moment().subtract(1, "days").format("YYYY-MM-DD"),
    ];
    const Last7Days = [
      moment().subtract(6, "days").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    ];
    const Last30Days = [
      moment().subtract(29, "days").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    ];
    const ThisMonth = [
      moment().startOf("month").format("YYYY-MM-DD"),
      moment().endOf("month").format("YYYY-MM-DD"),
    ];
    const LastMonth = [
      moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
      moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
    ];

    if (selecteddate[0] == Today[0] && selecteddate[1] == Today[1]) {
      setdateFilter(0); // Today
    } else if (
      selecteddate[0] == Yesterday[0] &&
      selecteddate[1] == Yesterday[1]
    ) {
      setdateFilter(1); // Yesterday
    } else if (
      selecteddate[0] == Last7Days[0] &&
      selecteddate[1] == Last7Days[1]
    ) {
      setdateFilter(); // Last7Days
    } else if (
      selecteddate[0] == Last30Days[0] &&
      selecteddate[1] == Last30Days[1]
    ) {
      setdateFilter(); // Last30Days
    } else if (
      selecteddate[0] == ThisMonth[0] &&
      selecteddate[1] == ThisMonth[1]
    ) {
      setdateFilter(3); // ThisMonth
    } else if (
      selecteddate[0] == LastMonth[0] &&
      selecteddate[1] == LastMonth[1]
    ) {
      setdateFilter(); // LastMonth
    } else {
      setdateFilter(); // custom
    }
  };

  const Revenue = sectionSale?.reduce(
    (total, item) => total + item.billTotal,
    0
  );

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="welcome d-lg-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center welcome-text">
              <div
                className={`${dateFilter === 0 ? "select-date-active" : ""}`}
                onClick={() => {
                  setdateFilter(0);
                  setStartingDate(moment().format("YYYY-MM-DD"));
                  setEndingDate(moment().format("YYYY-MM-DD"));
                }}
              >
                Today
              </div>
              <div
                className={`${dateFilter === 1 ? "select-date-active" : ""}`}
                onClick={() => {
                  setdateFilter(1);
                  setStartingDate(
                    moment().subtract(1, "days").format("YYYY-MM-DD")
                  );
                  setEndingDate(
                    moment().subtract(1, "days").format("YYYY-MM-DD")
                  );
                }}
              >
                Yesterday
              </div>
              <div
                className={`${dateFilter === 2 ? "select-date-active" : ""}`}
                onClick={() => {
                  setdateFilter(2);
                  setStartingDate(
                    moment().startOf("week").format("YYYY-MM-DD")
                  );
                  setEndingDate(moment().endOf("week").format("YYYY-MM-DD"));
                }}
              >
                This Week
              </div>
              <div
                className={`${dateFilter === 3 ? "select-date-active" : ""}`}
                onClick={() => {
                  setdateFilter(3);
                  setStartingDate(
                    moment().startOf("month").format("YYYY-MM-DD")
                  );
                  setEndingDate(moment().endOf("month").format("YYYY-MM-DD"));
                }}
              >
                This Month
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div
                id="reportrange"
                style={{
                  cursor: "pointer",
                  padding: "5px 22px",
                  border: "none",
                  width: "100%",
                  height: "37px",
                  paddingLeft: "12px",
                }}
              >
                <i className="fa fa-calendar"></i>&nbsp;
                <span style={{ fontSize: 14 }}></span>{" "}
                <i className="fa fa-caret-down"></i>
              </div>
            </div>
          </div>
          <div className="row sales-cards">
            {sectionSale?.map((obj, i) => {
              return (
                <div className="col-xl-3 col-sm-12 col-md-6" key={i}>
                  <div className="card d-flex align-items-center justify-content-between default-cover mb-4">
                    <div>
                      <h6>{obj?.section?.name}</h6>
                      <h3>
                        <span
                          className="counters"
                          data-count={obj?.billTotal?.toFixed(2)}
                        >
                          <CountUp
                            end={obj?.billTotal?.toFixed(2)}
                            duration={4}
                          >
                            +
                          </CountUp>
                        </span>
                        <div
                          style={{
                            fontSize: "small",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <span>TOTAL REVENUE </span>
                          <span>{obj?.percentage?.toFixed(2)} %</span>
                        </div>
                      </h3>
                    </div>
                    {/* <ImageWithBasePath
                    src="assets/img/icons/purchased-earnings.svg"
                    alt="img"
                  /> */}
                  </div>
                </div>
              );
            })}

            <div className="col-xl-3 col-sm-12 col-md-6">
              <div className="card d-flex align-items-center justify-content-between default-cover mb-4">
                <div>
                  <h6>Total Revenue</h6>
                  <h3>
                    <span className="counters" data-count="95000.45">
                      <CountUp end={Revenue} duration={4}>
                        +
                      </CountUp>
                    </span>
                  </h3>
                </div>
                <ImageWithBasePath
                  src="assets/img/icons/weekly-earning.svg"
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div className="welcome col-12 align-items-center justify-content-between">
            <Tabs>
              <TabList>
                <Tab>Section Wise Sales</Tab>
                <Tab>Product Wise Sales</Tab>
                <Tab>Waiter Wise Sales</Tab>
                <Tab>Daily Sales</Tab>
                <Tab>Top 5</Tab>
                <Tab>Least 5</Tab>
              </TabList>

              <TabPanel>
                <SectionWiseSale />
              </TabPanel>
              <TabPanel>
                <ProductWiseSale />
              </TabPanel>
              <TabPanel>
                <WaiterWiseSale />
              </TabPanel>
              <TabPanel>
                <DailySale />
              </TabPanel>
              <TabPanel>
                <TopFiveSale />
              </TabPanel>
              <TabPanel>
                <LeastFiveSale />
              </TabPanel>
            </Tabs>
          </div>

          <div className="row"></div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
