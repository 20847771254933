import React, { useState, useEffect, useRef } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import Select from "react-select";
import { Calendar } from "feather-icons-react/build/IconComponents";
import toast from "react-hot-toast";

import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import {
  getStockTransferByBranch,
  addEditStockTransfer,
} from "../../../services/StockApiServices";

import { useSelector, useDispatch } from "react-redux";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import {
  fetchUnit,
  fetchProduct,
  fetchSettings,
} from "../../../redux/userSetting";
import { InvStockTransfer } from "../../../core/json/InvStockTransfer";

const StockTransferModal = ({ mode, data, handleClose, handleRefresh }) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: dayjs().format("YYYY-MM-DD"),
    },
  });

  const [unitData, setunitData] = useState([]);
  const [products, setProducts] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const dispatch = useDispatch();
  const [isRowAdded, setIsRowAdded] = useState(false);
  const [decimalpoints, setDecimalPoints] = useState(2);
  const { formatedproductList } = useSelector((state) => state.usersetting);
  const { BranchByUser } = useSelector((state) => state.userBranch);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    dispatch(fetchUnit());
    dispatch(fetchProduct(branch));
    dispatch(fetchSettings(branch));
    const match = settingList?.currencyFormat?.toString()?.match(/\.0+/);
    const currfmt = match ? match[0]?.length - 1 : 0;
    setDecimalPoints(currfmt);
  }, [branch]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });
  const { productList, unitList, settingList } = useSelector(
    (state) => state?.usersetting
  );
  const selectRef = useRef(null);
  const focusProduct = () => {
    setTimeout(() => {
      if (selectRef.current) {
        selectRef.current.focus();
      }
    }, 0);
  };
  useEffect(() => {
    focusProduct();
  }, []);

  const handleBranchList = async () => {
    try {
      const formattedBranch = BranchByUser?.map((obj) => ({
        label: obj?.branch?.name,
        value: obj?.branch?.guid,
      }));

      setBranchData(formattedBranch);
    } catch (error) {
      console.error(
        "Error fetching unit",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };
  const handleUnitList = async () => {
    try {
      const formattedUnit = unitList?.map((unit) => ({
        label: unit?.name,
        value: unit?.guid,
      }));
      setunitData(formattedUnit);
    } catch (error) {
      console.error(
        "Error fetching unit",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  const handleEachProductfetch = async (selectedOption, index) => {
    setValue(`items[${index}].product`, selectedOption.value);

    try {
      const productData = await getSingleProduct(selectedOption.value);

      setValue(`items[${index}].code`, productData?.code || 0);
      setValue(`items[${index}].batch`, productData?.batch || 0);
      setValue(`items[${index}].unit`, productData?.unit?.guid || 0);
      setValue(`items[${index}].price`, productData?.cost || 0);
    } catch (error) {
      console.error(
        "Error fetching product by GUID:",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  async function getSingleProduct(guid) {
    try {
      const filteredData = productList?.find(
        (product) => product.guid === guid
      );
      return filteredData;
    } catch (error) {
      console.error(
        "Error fetching products",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  }

  // Fetch purchase invoices data
  const handleEntryNo = async () => {
    try {
      const response = await getStockTransferByBranch(branch);
      const stockData = response || [];

      const maxno =
        stockData?.length > 0
          ? stockData
              .map((data) => parseInt(data?.invoiceNo.replace(/\D/g, "")))
              .reduce((max, num) => Math.max(max, num), 0)
          : 0;
      let counter = maxno + 1;

      setValue(`no`, counter);
    } catch (error) {
      console.error(
        "Failed to fetch stock adjustments",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };
  function handleRemove(index) {
    if (fields.length > 1) {
      remove(index);
    } else {
      toast.error("At least one row entry is required");
    }
  }
  const handleAddRow = () => {
    append({
      batch: "0",
      quantity: "0",
      unit: "",
      product: null,
    });
    focusProduct();
  };
  useEffect(() => {
    if (mode === "add") {
      handleEntryNo();
      if (!isRowAdded && fields?.length === 0) {
        handleAddRow();
        setIsRowAdded(true);
      }
    }
    handleBranchList();
    // handleProductsList();
    handleUnitList();
  }, [branch]);
  function resetmodal() {
    if (mode === "edit" && data) {
      const resetData = {
        no: data?.invoiceNo,
        date: dayjs(data?.date),
        tobranch: data?.toBranch?.guid,
        remarks: data?.remarks,
        items: data?.invStockTransferDetails?.map((detail) => ({
          product: detail?.product?.guid,
          batch: detail?.batch,
          unit: detail?.unit?.guid,
          quantity: detail?.quantity,
        })),
      };
      reset(resetData);
    } else {
      reset();
      handleEntryNo();
    }
  }

  useEffect(() => {
    resetmodal();
  }, []);

  // const handleProductsList = async () => {
  //   try {
  //     const response = productList || [];

  //     const formattedProducts = response?.map((product) => ({
  //       label: product.name,
  //       value: product.guid,
  //     }));
  //     setProducts(formattedProducts);
  //   } catch (error) {
  //     console.error(
  //       "Error fetching products",
  //       error?.response?.data?.Message || "something went wrong"
  //     );
  //   }
  // };

  const handleFormSubmit = async (formdata) => {
    setloading(true);
    const objStockTrn = new InvStockTransfer();
    if (mode === "edit") {
      objStockTrn.guid = data?.guid;
      objStockTrn.id = data?.id;
    }
    objStockTrn.branch = branch;
    objStockTrn.date = formdata.date
      ? dayjs(formdata.date).format("YYYY-MM-DDTHH:mm:ss")
      : null;

    const tobranch = BranchByUser?.find(
      (e) => e.branch.guid === formdata.tobranch
    );
    objStockTrn.tobranch = tobranch.branch;

    objStockTrn.updatedUser = `{${userId?.id}}`;
    // objStockTrn.createdUser = `{${userId?.id}}`;
    objStockTrn.remarks = formdata.remarks || "";

    objStockTrn.invoiceNo = formdata.no ? String(formdata.no) : "0";

    const detailObj = formdata?.items?.map((e) => {
      let product;
      const productValue = e.product || e.product.value;

      product = productList?.find((item) => item.guid == productValue);
      const unit = unitList.find((item) => item.guid == e.unit);

      const detail = {
        product,
        unit,
        batch: e.batch || "",
        quantity: e.quantity,
        fromBranch: branch,
        tobranch: tobranch.branch,
      };

      return detail;
    });
    objStockTrn.invStockTransferDetails = detailObj;
    try {
      const response = await addEditStockTransfer(objStockTrn);
      if (response) {
        toast.success("Success");
        handleRefresh();
        handleClose();
      }
      setloading(false);
    } catch (error) {
      toast.error(error?.response?.data?.Message || "something went wrong");
      console.error(
        "Error handling :",
        error?.response?.data?.Message || "something went wrong"
      );
      setloading(false);
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog add-centered">
          <div className="modal-content">
            <div className="page-wrapper p-0 m-0">
              <div className="content p-0">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {" "}
                      {mode === "edit"
                        ? "Edit Stock Transfer"
                        : "Add Stock Transfer"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Entry No.</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("no")}
                              required
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>
                              Date
                              <span className="text-danger">*</span>
                            </label>
                            <div className="input-groupicon calender-input ">
                              <Calendar className="info-img" />
                              <Controller
                                control={control}
                                name="date"
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    className="datetimepicker "
                                    value={value ? dayjs(value) : dayjs()}
                                    format="DD-MM-YYYY"
                                    onChange={(date) =>
                                      onChange(
                                        date ? date.format("YYYY-MM-DD") : null
                                      )
                                    }
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">
                              To Branch<span className="text-danger">*</span>
                            </label>

                            <div className="row">
                              <Controller
                                control={control}
                                name={`tobranch`}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    classNamePrefix="react-select"
                                    options={branchData}
                                    isClearable
                                    value={
                                      branchData?.find(
                                        (option) => option?.value === value
                                      ) || null
                                    }
                                    onChange={(selectedOption) =>
                                      onChange(selectedOption?.value || null)
                                    }
                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    required
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">
                              From Branch<span className="text-danger">*</span>
                            </label>
                            <div className="row">
                              <input
                                type="text"
                                className="form-control ms-2"
                                value={branch?.name}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Remarks</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("remarks")}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive  no-pagination table-container">
                        <table className="table tablezind">
                          <thead>
                            <tr>
                              <th className="sticky-header">
                                Description
                                <span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">
                                Unit<span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">Batch</th>
                              <th className="sticky-header">
                                Quantity<span className="text-danger">*</span>
                              </th>

                              <th className="sticky-header">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields?.map((obj, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].product`}
                                      rules={{
                                        required: "Product is required",
                                        validate: (value) => {
                                          const selectedProducts = watch(
                                            "items"
                                          )?.map((item) => item.product); // Get all selected products
                                          const isDuplicate =
                                            selectedProducts?.filter(
                                              (product) => product === value
                                            )?.length > 1;
                                          return (
                                            !isDuplicate ||
                                            "Duplicate product selected!"
                                          );
                                        },
                                      }}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          ref={selectRef}
                                          options={formatedproductList}
                                          value={formatedproductList?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                            handleEachProductfetch(
                                              selectedOption,
                                              index
                                            );
                                          }}
                                          styles={{
                                            menu: (provided) => ({
                                              ...provided,
                                              zIndex: 9999,
                                              position: "relative",
                                            }),
                                          }}
                                        />
                                      )}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.product && (
                                        <span className="text-danger small">
                                          {errors.items[index].product.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].unit`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={unitData}
                                          value={unitData?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                          }}
                                          isDisabled={true}
                                        />
                                      )}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].batch`)}
                                      disabled
                                    />
                                  </td>

                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].quantity`, {
                                        required: "quantity is required",
                                        validate: (value) =>
                                          (!isNaN(value) && value > 0) ||
                                          "quantity must be a positive number",
                                      })}
                                      required
                                    />
                                    {errors.items &&
                                      errors.items[index]?.quantity && (
                                        <span className="text-danger small">
                                          {errors.items[index].quantity.message}
                                        </span>
                                      )}
                                  </td>

                                  <td>
                                    <Link
                                      className="confirm-text p-2"
                                      onClick={(index) => handleRemove(index)}
                                    >
                                      <i
                                        data-feather="trash-2"
                                        className="feather-trash-2"
                                      ></i>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-2 col-sm-2 col-2 ps-0 ">
                        <button
                          className=" btn btn-dark add-icon tab  mt-3 "
                          type="button"
                          onClick={handleAddRow}
                        >
                          <PlusCircle className="feather-plus-circles" />
                        </button>
                      </div>
                      {!loading && (
                        <div className="row">
                          <div className="col-lg-12 text-end">
                            <button
                              type="button"
                              className="btn btn-cancel add-cancel me-3"
                              onClick={resetmodal}
                            >
                              Reset
                            </button>
                            <button
                              className="btn btn-submit add-sale"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StockTransferModal;
