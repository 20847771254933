import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

// Utility function to generate table headers
const generateTableHeaders = (columns) => {
  return columns
    ?.map((col) => col.title)
    .filter((header) => header !== "Actions" && header !== "Sl No");
};

// Utility function to generate table body rows
const generateTableBody = (data, tableHeaders, fieldMapping) => {
  return data?.map((item) => {
    return tableHeaders?.map((header) => {
      const field = fieldMapping[header];
      let value = item[field];

      if (field === "product") {
        value = item?.product?.name;
      }
      if (field === "category") {
        value = item?.category?.name;
      }
      if (field === "type") {
        value = item?.type?.name;
      }
      if (field === "ledger") {
        value = item?.ledger?.name;
      }
      if (field === "date") {
        value = dayjs(item?.date).format("DD-MM-YYYY");
      }
      //HOURLY REPORTS
      if (field === "billTime") {
        value = dayjs(item?.billTime).format("DD-MM-YYYY HH:mm");
      }
      if (field === "stockvalue") {
        value = item.wAvrgCost * item.totalCurStock; // Get unit name
      }
      return value;
    });
  });
};

// Excel Export
export const exportExcel = (getActiveTabData, footerTotals, title) => {
  const { data, columns, fieldMapping, filename } = getActiveTabData();
  const tableHeaders = generateTableHeaders(columns);
  const tableBody = generateTableBody(data, tableHeaders, fieldMapping);
  const footerRow = footerTotals
    ? tableHeaders.map((header) => footerTotals[fieldMapping[header]] || "")
    : null;
  const fullTable =
    footerRow && footerRow.some((cell) => cell)
      ? [...tableBody, footerRow]
      : tableBody;
  const worksheet = XLSX.utils.aoa_to_sheet([tableHeaders, ...fullTable]);
  //const worksheet = XLSX.utils.aoa_to_sheet([tableHeaders, ...tableBody]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

  XLSX.writeFile(workbook, `${filename || title}.xlsx`);
};

// Printable format
export const printReport = (getActiveTabData, footerTotals, title) => {
  const { data, columns, fieldMapping, filename } = getActiveTabData();

  const printWindow = window.open("", "_blank");

  // Prepare the document for printing
  printWindow.document.write(`<html><head><title>${title}</title>`);
  printWindow.document.write("<style>");
  printWindow.document.write(`
        table {
            width: 100%;
            border-collapse: collapse;
        }
        th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
        }
        th {
            background-color: #f2f2f2;
        }
    `);
  printWindow.document.write("</style></head><body>");
  printWindow.document.write(`<h1>${filename || title}</h1>`);

  const tableHeaders = generateTableHeaders(columns);
  let tableHTML = "<table><thead><tr>";
  tableHeaders.forEach((header) => {
    tableHTML += `<th>${header}</th>`;
  });
  tableHTML += "</tr></thead><tbody>";

  const tableBody = generateTableBody(data, tableHeaders, fieldMapping);
  tableBody.forEach((row) => {
    tableHTML += "<tr>";
    row.forEach((cell) => {
      tableHTML += `<td>${cell !== undefined ? cell : ""}</td>`;
    });
    tableHTML += "</tr>";
  });
  //footertotals
  if (footerTotals && Object.values(footerTotals).some((value) => value)) {
    const footerRowHTML = tableHeaders
      .map((header) => `<td>${footerTotals[fieldMapping[header]] || ""}</td>`)
      .join("");
    tableHTML += `<tr>${footerRowHTML}</tr>`;
  }
  //
  tableHTML += "</tbody></table>";

  printWindow.document.write(tableHTML);
  printWindow.document.write("</body></html>");

  printWindow.document.close();
  printWindow.print();
};

// PDF Export
export const exportPDF = (getActiveTabData, footerTotals, title) => {
  const { data, columns, fieldMapping, filename } = getActiveTabData();

  // Set orientation based on the number of columns (e.g., more than 5 columns -> landscape)
  const orientation = columns?.length > 9 ? "landscape" : "portrait";

  // Initialize jsPDF with the dynamic orientation
  const doc = new jsPDF({
    orientation: orientation,
  });

  // Generate table headers and body
  const tableHeaders = generateTableHeaders(columns);
  const tableBody = generateTableBody(data, tableHeaders, fieldMapping);

  const footerRow =
    footerTotals &&
    tableHeaders.some((header) => footerTotals[fieldMapping[header]])
      ? tableHeaders.map((header) => footerTotals[fieldMapping[header]] || "")
      : null;
  // Use autoTable to export the table
  autoTable(doc, {
    head: [tableHeaders],
    body: tableBody,
    ...(footerRow ? { foot: [footerRow] } : {}),
    styles: { fontStyle: "bold" },
  });

  // Save the PDF
  doc.save(`${filename || title}.pdf`);
};
