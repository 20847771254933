import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronUp, Filter, RotateCcw } from "feather-icons-react";
// import Table from "../../../core/pagination/datatable";
import { Table } from "antd";
import { useEffect } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../../redux/toggle";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../../redux/helper";
import { DatePicker, Select, Tabs } from "antd";
import { getConsolidatedReportSectionwise } from "../../../services/ReportApiService";
import {
  DatatablePagingParameter,
  Filterclass,
} from "../../../core/json/DatatablePagingParameters";
import dayjs from "dayjs";
import { useMemo } from "react";
import LoadingUtils from "../../../core/utils/LoadingUtils";
import {
  exportMasterExcel,
  exportMasterPDF,
  printMasterReport,
} from "../../../core/utils/ReportFooterUtils";
import { Calendar } from "feather-icons-react/build/IconComponents";

const ItemsReport = () => {
  const [searchText, setSearchText] = useState("");
  const [reportData, setreportData] = useState([]);
  const [loading, setloading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const { branch } = useSelector((state) => state.branchlist);
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("1");
  const { togglehead } = useSelector((state) => state.headerToggle);
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);

    setToDate(null); // reset to date when from date changes
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };
  useEffect(() => {
    //setreportData(salesReportConsol);
    handleReportList();
  }, []);
  const handleReportList = async () => {
    setloading(true);
    const DatatablePagingParameters = new DatatablePagingParameter();
    const formattedfromDate = dayjs(fromDate)
      .startOf("day")
      .format("YYYY-MM-DD");
    const formattedtoDate = dayjs(toDate).endOf("day").format("YYYY-MM-DD");
    // const formattedfromDate = "2024-08-04";
    // const formattedtoDate = "2024-08-05";
    DatatablePagingParameters.PageSize = -1;
    const Filters = new Filterclass();
    Filters.Branch = branch;
    Filters.FromDate = formattedfromDate;
    Filters.ToDate = formattedtoDate;
    Filters.PaymentType = "";
    Filters.Waiter = "";
    Filters.Counter = "";
    Filters.Section = "";
    try {
      const response = await getConsolidatedReportSectionwise({
        DatatablePagingParameters,
        Filters,
      });
      setloading(false);
      // Check if the response contains valid array data
      // Branch	Total	(-)Complementary	(-)Canceled	(-)Refund	Gross	(-)Discount	NetTotal	Cash	Card	Credit
      const salesReportConsol = response?.data?.salesReportConsol || [];

      if (Array.isArray(salesReportConsol)) {
        const dataArray = salesReportConsol?.map((item) => ({
          branch: item.branch?.name,
          trnDate: dayjs(item?.transactionDate).format("DD MMM YYYY"),
          complementary: item.paymentType == 5 ? item.netTotal : 0.0,
          cancelled: item.cancelled ? item.netTotal : 0.0,
          refund: item.refund ? item.netTotal : 0.0,
          gross: item.rateTotal,
          discount: item.discount,
          netTotal: item.netTotal,
          cash: item.cash,
          card: item.card,
          credit: item.paymentType == 4 ? item.netTotal : 0.0,
        }));

        setreportData(dataArray);
      } else {
        console.error("Data is not in the expected array format");
        setreportData([]);
      }
    } catch (error) {
      setloading(false);
      console.error(error?.response?.data?.Message || "Something went wrong");
      setreportData([]);
    }
  };

  const { branches, detailData } = useMemo(() => {
    const safeReportData = Array.isArray(reportData) ? reportData : [];

    const branches = [...new Set(safeReportData?.map((item) => item.branch))];
    const trnDates = [...new Set(safeReportData?.map((item) => item.trnDate))];

    const detailData =
      branches?.map((branch) => {
        const row = { key: branch, branch };

        safeReportData?.map((item) => {
          if (item.branch === branch) {
            row["complementary"] = item.complementary || 0;
            row["cancelled"] = item.cancelled || 0;
            row["refund"] = item.refund || 0;
            row["gross"] = item.gross || 0;
            row["discount"] = item.discount || 0;
            row["netTotal"] = item.netTotal || 0;
            row["cash"] = item.cash || 0;
            row["card"] = item.card || 0;
            row["credit"] = item.credit || 0;
          }
        });

        return row;
      }) || [];

    return {
      branches,

      detailData,
    };
  }, [reportData]);
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const detailColumns1 = [
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Total",
      dataIndex: "netTotal",
      key: "netTotal",
      render: (value) => (value !== undefined ? value : 0),
    },
    {
      title: "Complementary",
      dataIndex: "complementary",
      key: "complementary",
      render: (value) => (value !== undefined ? value : 0),
    },
    {
      title: "Cancelled",
      dataIndex: "cancelled",
      key: "cancelled",
      render: (value) => (value !== undefined ? value : 0),
    },
    {
      title: "Refund",
      dataIndex: "refund",
      key: "refund",
      render: (value) => (value !== undefined ? value : 0),
    },
    {
      title: "Gross",
      dataIndex: "gross",
      key: "gross",
      render: (value) => (value !== undefined ? value : 0),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (value) => (value !== undefined ? value : 0),
    },
    {
      title: "NetTotal",
      dataIndex: "netTotal",
      key: "netTotal",
      render: (value) => (value !== undefined ? value : 0),
    },
    {
      title: "Cash",
      dataIndex: "cash",
      key: "cash",
      render: (value) => (value !== undefined ? value : 0),
    },
    {
      title: "Card",
      dataIndex: "card",
      key: "card",
      render: (value) => (value !== undefined ? value : 0),
    },

    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (value) => (value !== undefined ? value : 0),
    },
  ];

  const detailColumns = [
    {
      title: "Date",
      dataIndex: "trnDate",
      key: "trnDate",
    },
    {
      title: "Total",
      dataIndex: "netTotal",
      key: "netTotal",
      render: (value) => (value !== undefined ? value?.toFixed(2) : 0),
    },
    {
      title: "Complementary",
      dataIndex: "complementary",
      key: "complementary",
      render: (value) => (value !== undefined ? value?.toFixed(2) : 0),
    },
    {
      title: "Cancelled",
      dataIndex: "cancelled",
      key: "cancelled",
      render: (value) => (value !== undefined ? value?.toFixed(2) : 0),
    },
    {
      title: "Refund",
      dataIndex: "refund",
      key: "refund",
      render: (value) => (value !== undefined ? value?.toFixed(2) : 0),
    },
    {
      title: "Gross",
      dataIndex: "gross",
      key: "gross",
      render: (value) => (value !== undefined ? value?.toFixed(2) : 0),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (value) => (value !== undefined ? value?.toFixed(2) : 0),
    },
    {
      title: "NetTotal",
      dataIndex: "netTotal",
      key: "netTotal",
      render: (value) => (value !== undefined ? value?.toFixed(2) : 0),
    },
    {
      title: "Cash",
      dataIndex: "cash",
      key: "cash",
      render: (value) => (value !== undefined ? value?.toFixed(2) : 0),
    },
    {
      title: "Card",
      dataIndex: "card",
      key: "card",
      render: (value) => (value !== undefined ? value?.toFixed(2) : 0),
    },

    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (value) => (value !== undefined ? value?.toFixed(2) : 0),
    },
  ];
  // const grandnetTotal = reportData?.reduce(
  //   (sum, record) => sum + record.netTotal,
  //   0
  // );
  const grandcomplementary = reportData?.reduce(
    (sum, record) => sum + record.complementary,
    0
  );
  const grandcancelled = reportData?.reduce(
    (sum, record) => sum + record.cancelled,
    0
  );
  const grandrefund = reportData?.reduce(
    (sum, record) => sum + record.refund,
    0
  );
  const grandgross = reportData?.reduce((sum, record) => sum + record.gross, 0);
  const granddiscount = reportData?.reduce(
    (sum, record) => sum + record.discount,
    0
  );
  const grandnetTotal = reportData?.reduce(
    (sum, record) => sum + record.netTotal,
    0
  );
  const grandcash = reportData?.reduce((sum, record) => sum + record.cash, 0);
  const grandcard = reportData?.reduce((sum, record) => sum + record.card, 0);
  const grandcredit = reportData?.reduce(
    (sum, record) => sum + record.credit,
    0
  );

  const footerTotals = {
    netTotal: grandnetTotal?.toFixed(2) || 0,
    complementary: grandcomplementary?.toFixed(2) || 0,
    cancelled: grandcancelled?.toFixed(2) || 0,
    refund: grandrefund?.toFixed(2) || 0,
    gross: grandgross?.toFixed(2) || 0,
    discount: granddiscount?.toFixed(2) || 0,
    cash: grandcash?.toFixed(2) || 0,
    card: grandcard?.toFixed(2) || 0,
    credit: grandcredit?.toFixed(2) || 0,
  };

  console.log("footerTotals", footerTotals);

  function handleReset() {
    setFromDate(null);
    setToDate(null);
    setSearchText("");
    setreportData([]);
    // setModifiedList([]);
    //detailData = [];
  }
  const getActiveTabData = () => {
    let fieldMapping = {};

    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map column title to its dataIndex
      });
      return mappings;
    };
    switch (activeTab) {
      case "1":
        fieldMapping = createfieldMapping(detailColumns);
        console.log("fieldMapping of first table:", fieldMapping);
        return { data: reportData, columns: detailColumns, fieldMapping };
      case "2":
        fieldMapping = createfieldMapping(detailColumns1);
        console.log("fieldMapping of second table:", fieldMapping);
        return { data: detailData, columns: detailColumns1, fieldMapping };
      default:
        return { data: [], columns: [] };
    }
  };
  return (
    <div>
      <LoadingUtils loading={loading} />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Consolidated Paymode Reports</h4>
                <h6>Manage paymode reports here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() =>
                      exportMasterPDF(
                        getActiveTabData,
                        footerTotals,
                        "Paymode wise Report"
                      )
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() =>
                      exportMasterExcel(
                        getActiveTabData,
                        footerTotals,
                        "Paymode wise Report"
                      )
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() =>
                      printMasterReport(
                        getActiveTabData,
                        footerTotals,
                        "Paymode wise Report"
                      )
                    }
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handleReportList()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top ">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="input-blocks">
                        <label>From Date</label>
                        {/* <Calendar className="info-img" /> */}
                        <DatePicker
                          className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          selected={fromDate}
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-blocks">
                        <label>To Date</label>
                        {/* <Calendar className="info-img" /> */}
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-blocks p-3 mt-3">
                    <Link
                      className="btn btn-filters rounded-2 "
                      onClick={handleReportList}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search{" "}
                    </Link>
                  </div>
                  <div className="input-blocks mt-3">
                    <button
                      className="btn btn-reset  rounded-2 "
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                  <TabPane tab="Detailed View" key="1">
                    <Table
                      className="table datanew dataTable no-footer custom-table"
                      columns={detailColumns}
                      dataSource={reportData}
                      summary={() => (
                        <Table.Summary.Row>
                          {detailColumns?.map((col) => (
                            <Table.Summary.Cell key={col.dataIndex}>
                              <b>{footerTotals[col.dataIndex] || ""}</b>
                            </Table.Summary.Cell>
                          ))}
                        </Table.Summary.Row>
                      )}
                    />
                  </TabPane>
                  <TabPane tab="Summary View" key="2">
                    <Table
                      className="table datanew dataTable no-footer custom-table"
                      columns={detailColumns1}
                      dataSource={detailData}
                      summary={() => (
                        <Table.Summary.Row>
                          {detailColumns1?.map((col) => (
                            <Table.Summary.Cell key={col.dataIndex}>
                              <b>{footerTotals[col.dataIndex] || ""}</b>
                            </Table.Summary.Cell>
                          ))}
                        </Table.Summary.Row>
                      )}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ItemsReport;
