import React, { useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Select from "react-select";
import { addNewPromoProduct } from "../../../services/MasterApiServices";
import { useSelector } from "react-redux";
import { PromoProductsClass } from "../../../core/json/PromoProductsClass";
import toast from "react-hot-toast";
import { TaxClass } from "../../../core/json/TaxClass";
const AddPromoProduct = ({ Promotionlist, handlePromoProductList }) => {
  const [Data, setData] = useState([]);
  const [promoName, setpromoName] = useState();
  const [section, setsection] = useState();
  const [productId, setproductId] = useState(1);
  const [isaddActive, setisaddActive] = useState(false);
  const { branch } = useSelector((state) => state.branchlist);
  const { sectionList, productList } = useSelector(
    (state) => state.usersetting
  );
  const { userId } = useSelector((state) => state.userauth);
  const { register, handleSubmit, setValue, getValues, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "ProductData",
  });
  const modifiedSection = sectionList?.map((e) => ({
    value: e.guid,
    label: e.name,
  }));
  const modifiedPromotion = Promotionlist?.map((e) => ({
    value: e.guid,
    label: e.name,
  }));
  const modifiedProduct = productList?.map((e) => ({
    value: e.guid,
    label: e.name,
  }));

  const handleAddPromo = () => {
    append({ product: "", rate: "" });
  };

  const handleSection = (e) => {
    setsection(e);
    append({ product: "", rate: "" });
    setisaddActive(true);
  };

  const handlesubmitPromoProduct = async (formData) => {
    const objtax = new TaxClass();
    const ProductDetail = formData?.ProductData?.map((e) => ({
      product: productList?.find((item) => item.guid == e.product?.value),
      price: e.rate,
      tax: objtax,
    }));
    const objpromoproduct = new PromoProductsClass();
    objpromoproduct.promotion = Promotionlist?.find(
      (item) => item.guid == promoName.value
    );
    objpromoproduct.branch = branch;
    objpromoproduct.section = sectionList?.find(
      (item) => item.guid == section.value
    );
    objpromoproduct.promoProductsDetail = ProductDetail;
    objpromoproduct.updatedUser = userId.id;
    objpromoproduct.createdUser = userId.id;
    try {
      const response = await addNewPromoProduct(objpromoproduct);
      if (response.data == true) {
        handlePromoProductList();
        closemodal();
        fields.forEach((_, index) => remove(index));
        toast.success("success");
      }
    } catch (error) {
      console.log();
    }
  };
  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <div>
      <div className="modal fade" id="add-promo-product">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Promo Products</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handlesubmitPromoProduct)}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Promotion name<span className="text-danger">*</span>
                          </label>

                          <Select
                            options={modifiedPromotion}
                            classNamePrefix="react-select"
                            value={promoName}
                            onChange={setpromoName}
                            placeholder="Choose Promotion"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Section<span className="text-danger">*</span>
                          </label>
                          <Select
                            options={modifiedSection}
                            classNamePrefix="react-select"
                            placeholder="Choose Section"
                            value={section}
                            onChange={handleSection}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="row p-2"
                      style={{
                        maxHeight: "300px",
                        height: "auto",
                        overflow: "scroll",
                        position: "relative",
                      }}
                    >
                      <div className="row">
                        <div className="col-4">
                          Product<span className="text-danger">*</span>
                        </div>
                        <div className="col-4">
                          Rate<span className="text-danger">*</span>
                        </div>
                        <div className="col-4">Action</div>
                      </div>
                      {fields?.map((obj, index) => {
                        return (
                          <React.Fragment key={index}>
                            <hr />
                            <div className="row">
                              <div className="col-4">
                                <div className="input-blocks">
                                  <Controller
                                    {...register(
                                      `ProductData[${index}].product`,
                                      { required: true }
                                    )}
                                    name={`ProductData[${index}].product`}
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        classNamePrefix="Choose Product"
                                        isClearable={true}
                                        isSearchable={true}
                                        options={modifiedProduct}
                                        styles={{
                                          menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                          }),
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="input-blocks">
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register(`ProductData[${index}].rate`, {
                                      required: true,
                                    })}
                                    name={`ProductData[${index}].rate`}
                                  />
                                </div>
                              </div>
                              <div className="col-4 promo-delete">
                                <div className="edit-delete-action">
                                  <Link
                                    className="confirm-text p-2"
                                    to="#"
                                    onClick={() => remove(index)} // Deleting the item at this index
                                  >
                                    <i
                                      data-feather="trash-2"
                                      className="feather-trash-2"
                                    ></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <button
                      className="btn btn-success mt-4"
                      onClick={handleAddPromo}
                      type="button"
                      disabled={!isaddActive}
                    >
                      Add
                    </button>
                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPromoProduct;
