import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  PlusCircle,
  RotateCcw,
  ChevronUp,
  Calendar,
  Layers,
  User,
  Smile,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import Table from "../../core/pagination/datatable";
import {
  deletebillpayment,
  getbillwisepayment,
  getcashorbank,
} from "../../services/AccountApiServices";
import { DatePicker, Tabs } from "antd";
import Select from "react-select";
import {
  printReport,
  exportExcel,
  exportPDF,
} from "../../core/utils/AccountingUtils";
import moment from "moment";
import Billpaymentmodal from "../../components/accounting/Billpaymentmodal";
import { fetchSupplier } from "../../redux/userSetting";
import { BillPaymentEntry } from "../../core/json/BillPaymentEntry";
const BillwisePayment = () => {
  const navigate = useNavigate();
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("1");
  const [searchText, setSearchText] = useState("");
  const [billpayment, setbillpayment] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [modalMode, setModalMode] = useState("add");
  const [modalOpen, setModalOpen] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const { togglehead } = useSelector((state) => state.headerToggle);
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const [filteredData, setFilteredData] = useState([]);
  const [itemwiseData, setItemwiseData] = useState([]);
  const { menuItems } = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const MySwal = withReactContent(Swal);
  const [selectedledger, setSelectedledger] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const { formatedsupplierList } = useSelector((state) => state.usersetting);
  const [bankorcash, setbankorcash] = useState([]);
  const renderTooltip = (message) => (
    <Tooltip id="pdf-tooltip">{message}</Tooltip>
  );
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  useEffect(() => {
    setpermit(
      menuItems?.menuPermissions?.find((e) => e.menu.name == "Bill Payment")
    );
    handlegetCashorbank();

    dispatch(fetchSupplier());
  }, []);
  useEffect(() => {
    handlebillpayment();
  }, [branch]);
  useEffect(() => {
    handleFilter();
  }, [billpayment]);
  const handlebillpayment = async () => {
    try {
      const response = await getbillwisepayment(branch);
      setbillpayment(response.data);
    } catch (error) {
      console.error(
        "Failed to fetch sales invoices",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  const handlegetCashorbank = async () => {
    try {
      const response = await getcashorbank();
      if (Array.isArray(response.data)) {
        const formated = response.data?.map((e) => ({
          label: e.name,
          value: e.guid,
        }));
        setbankorcash(formated);
      } else {
        setbankorcash([]);
      }
    } catch (error) {
      setbankorcash([]);
    }
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleEditClick = async (guid) => {
    try {
      const response = billpayment?.find((sales) => sales.guid === guid);
      setUpdateData(response);
      setModalMode("edit");
      setModalOpen(true);
    } catch (error) {
      console.error(
        "Error fetching sales invoice by GUID",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  const handleDeleteClick = (obj) => {
    const objpayment = new BillPaymentEntry();
    objpayment.Branch = branch;
    objpayment.guid = obj.guid;
    objpayment.id = obj.id;
    objpayment.updatedUser = userId?.id;
    objpayment.createdUser = userId?.id;
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deletebillpayment(objpayment);
          if (response.data == true) {
            MySwal.fire({
              title: "Deleted!",
              text: "The Bill payment has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handlebillpayment();
              setSearchText("");
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting sales quotation:", error);
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      defaultSortOrder: "descend",
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Entry No",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      render: (_, record) =>
        formatedsupplierList?.find((e) => e.value == record?.supplier.guid)
          ?.label,
    },
    {
      title: "Ledger",
      dataIndex: "ledgerId",
      render: (_, record) =>
        bankorcash?.find((e) => e.value == record?.ledgerID)?.label,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      // render: (text) => text.name,
    },
    {
      title: "Discount",
      dataIndex: "paymentDiscount",
      sorter: (a, b) => {
        const getTotalDiscount = (record) =>
          record?.billPaymentDetail
            ? record.billPaymentDetail.reduce(
                (sum, detail) => sum + (detail?.discount || 0),
                0
              )
            : 0;

        const discountA = getTotalDiscount(a);
        const discountB = getTotalDiscount(b);

        return discountA - discountB;
      },
      render: (_, record) =>
        record?.billPaymentDetail
          ? record?.billPaymentDetail
              ?.reduce((sum, detail) => sum + (detail?.discount || 0), 0)
              .toFixed(2)
          : 0,
    },
    {
      title: "Narration",
      dataIndex: "narration",
      sorter: (a, b) => a.narration - b.narration,
      // render: (text) => text.name,
    },

    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                  {permit?.edit && (
                    <Link
                      className="me-2 p-2"
                      to="#"
                      onClick={() => handleEditClick(obj.guid)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                  {permit?.deletion && (
                    <Link
                      className="confirm-text p-2"
                      to="#"
                      onClick={() => handleDeleteClick(obj)}
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];
  const reportcolumns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.invdate).isAfter(dayjs(b.invdate)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Entry No",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      render: (_, record) =>
        formatedsupplierList?.find((e) => e.value == record?.supplier.guid)
          ?.label,
    },
    {
      title: "Ledger",
      dataIndex: "ledgerId",
      render: (_, record) =>
        bankorcash?.find((e) => e.value == record?.ledgerID)?.label,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      // render: (text) => text.name,
    },
    {
      title: "Discount",
      dataIndex: "paymentDiscount",
      sorter: (a, b) => {
        const getTotalDiscount = (record) =>
          record?.billPaymentDetail
            ? record.billPaymentDetail.reduce(
                (sum, detail) => sum + (detail?.discount || 0),
                0
              )
            : 0;

        const discountA = getTotalDiscount(a);
        const discountB = getTotalDiscount(b);

        return discountA - discountB;
      },
      render: (_, record) =>
        record?.billPaymentDetail
          ? record?.billPaymentDetail
              ?.reduce((sum, detail) => sum + (detail?.discount || 0), 0)
              .toFixed(2)
          : 0,
    },
    {
      title: "Narration",
      dataIndex: "narration",
      sorter: (a, b) => a.discount - b.discount,
      // render: (text) => text.name,
    },
  ];

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);

    setToDate(null); // reset to date when from date changes
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  function handleFilter() {
    const filteredData = billpayment.filter((entry) => {
      const supplierMatch =
        !selectedSupplier || entry.supplier?.guid === selectedSupplier?.value;

      const ledgerMatch =
        !selectedledger || entry.ledgerID === selectedledger?.value;

      const fromDateMatch =
        !fromDate || new Date(entry.date) >= new Date(fromDate);
      const toDateMatch = !toDate || new Date(entry.date) <= new Date(toDate);

      // const searchTextMatch =
      // entry?.supplier?.name
      // ?.toLowerCase()
      // .includes(searchText?.toLowerCase()) ||
      // entry?.invdate?.includes(searchText) ||
      // entry?.amount?.includes(searchText) ||
      // entry?.discount?.toString().includes(searchText);

      return supplierMatch && ledgerMatch && fromDateMatch && toDateMatch;
    });

    setFilteredData(filteredData);

    let serialCounter = 1;
    const itemwiseData = (filteredData) => {
      return filteredData?.invSalesDetails?.map((item, index) => {
        return {
          key: serialCounter,
          slno: serialCounter++,
          date: filteredData.invdate,
          entrynumber: filteredData.entrynumber,
          customer: filteredData.customer?.name || "Unknown Customer",
          itemName: item.product?.name || "Unknown Item",
          quantity: item.quantity,
          rate: item.price,
          total: item.amount,
          discountTotal: item.discAmt,
          tax: item.taxamnt,
          netAmount: item.netAmt,
        };
      });
    };
    const allItemwiseData = filteredData.flatMap((invoice) =>
      itemwiseData(invoice)
    );
    setItemwiseData(allItemwiseData);
  }

  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create a column mapping (column title to dataIndex)
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map column title to its dataIndex
      });
      return mappings;
    };
    switch (activeTab) {
      case "1":
        fieldMapping = createfieldMapping(columns);

        return {
          data: filteredData,
          columns: columns,
          fieldMapping,
          filename: "Billwise Payment Form",
        };
      case "2":
        fieldMapping = createfieldMapping(reportcolumns);

        return {
          data: filteredData,
          columns: reportcolumns,
          fieldMapping,
          filename: "Billwise Detailed Report ",
        };

      default:
        return { data: [], columns: [], filename: [] };
    }
  };

  function handleReset() {
    setFromDate(null);
    setToDate(null);
    setSelectedledger(null);
    setSelectedSupplier(null);
    setSearchText("");
    setFilteredData(billpayment);
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Bills Wise Payment</h4>
                <h6>Manage bill wise payment here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Pdf")}
                  >
                    <Link
                      onClick={() =>
                        exportPDF(
                          getActiveTabData,
                          "Bill wise payment",
                          bankorcash,
                          formatedsupplierList
                        )
                      }
                    >
                      <ImageWithBasePath
                        src="assets/img/icons/pdf.svg"
                        alt="img"
                      />
                    </Link>
                  </OverlayTrigger>
                </li>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() =>
                      exportExcel(
                        getActiveTabData,
                        "Bill wise payment",
                        bankorcash,
                        formatedsupplierList
                      )
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() =>
                      printReport(
                        getActiveTabData,
                        "Bill wise payment",
                        bankorcash,
                        formatedsupplierList
                      )
                    }
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link onClick={() => handlebillpayment()}>
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>

            {/* {permit?.add && ( */}
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add"); // Set the modal mode to 'add'
                  setModalOpen(true); // Open the modal
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>
            {/* )} */}
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="d-flex flex-wrap align-items-center gap-3 m-2">
                {/* Search Input */}
                <div className="flex-grow-1">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm"
                    value={searchText}
                    onChange={handleSearch}
                    style={{ height: "38px" }}
                  />
                </div>

                {/* From Date */}
                <div className="flex-grow-1">
                  <div className="input-blocks ms-4">
                    <label>From Date</label>
                    <div className="input-groupicon calender-input">
                      <Calendar className="info-img" />
                      <DatePicker
                        className="datetimepicker"
                        id="from-date"
                        placeholderText="From Date"
                        selected={fromDate}
                        value={fromDate}
                        onChange={handleFromDateChange}
                        startDate={fromDate}
                        selectsStart
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </div>

                {/* To Date */}
                <div className="flex-grow-1">
                  <div className="input-blocks">
                    <label>To Date</label>
                    <div className="input-groupicon calender-input">
                      <Calendar className="info-img" />
                      <DatePicker
                        id="to-date"
                        placeholderText="To Date"
                        value={toDate}
                        onChange={handleToDateChange}
                        minDate={fromDate}
                        startDate={fromDate}
                        selectsEnd
                        disabled={!fromDate}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                {/* Product Dropdown */}
                <div className="flex-grow-1">
                  <div className="input-blocks ">
                    <label>Ledger</label>
                    <div className="input-groupicon calender-input">
                      <Layers className="info-img" />
                      <Select
                        className="img-select"
                        options={bankorcash}
                        value={selectedledger}
                        onChange={setSelectedledger}
                        classNamePrefix="react-select"
                        placeholder="Ledger"
                      />
                    </div>
                  </div>
                </div>

                {/* Supplier Dropdown */}
                <div className="flex-grow-1">
                  <div className="input-blocks">
                    <label>Supplier</label>
                    <div className="input-groupicon calender-input">
                      <Smile className="info-img" />
                      <Select
                        className="img-select"
                        options={formatedsupplierList}
                        value={selectedSupplier}
                        onChange={setSelectedSupplier}
                        classNamePrefix="react-select"
                        placeholder="Supplier"
                      />
                    </div>
                  </div>
                </div>

                {/* Buttons */}
                <div className="d-flex gap-2">
                  <button className="btn btn-primary" onClick={handleFilter}>
                    Search
                  </button>
                  <button className="btn btn-secondary" onClick={handleReset}>
                    Reset
                  </button>
                </div>
              </div>

              <div className="table-responsive">
                <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                  <TabPane tab="Billwise Payment" key="1">
                    <Table columns={columns} dataSource={filteredData} />
                  </TabPane>
                  <TabPane tab="Detailed Report" key="2">
                    <Table columns={reportcolumns} dataSource={filteredData} />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Reason Modal */}
      {modalOpen && (
        <Billpaymentmodal
          mode={modalMode}
          bankorcash={bankorcash}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handlebillpayment();
            setSearchText("");
          }}
        />
      )}
    </div>
  );
};

export default BillwisePayment;
