import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Table from "../../../core/pagination/datatable";
import { useState } from "react";
import toast from "react-hot-toast";
import { useFieldArray, useForm } from "react-hook-form";
import { SaveCurrencyDenomination } from "../../../services/MasterApiServices";
import { CurrencyDenominationAssignment } from "../../../core/json/CurrencyDenominationAssignment";
import { CurrencyDenomination } from "../../../core/json/CurrencyDenomination";
import { useSelector } from "react-redux";
import { guid } from "rsuite/esm/internals/utils";

const AddCurrencDenom = ({ data, Currency }) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    control,
    setValue,
    reset,
    watch,
    setError,
    clearErrors,
    values,
    trigger,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "CurrencyDenominations",
  });

  useEffect(() => {
    // Clear existing fields before appending new ones
    fields.forEach((_, index) => remove(index));

    if (data?.currencyDenominations?.length > 0) {
      // Map over the data and append the denominations
      const Denominations = data.currencyDenominations?.map((e) => ({
        Denominations: e.denomination,
      }));
      append(Denominations);
    }
  }, [data, append]);

  const [Dvalue, setDvalue] = useState();
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);

  const handleAddDenom = (e) => {
    e.preventDefault();

    if (Dvalue) {
      setDvalue("");

      append({ Denominations: Dvalue });
    } else {
      toast.error("Please enter Denomination");
    }
  };

  const columns = [
    {
      title: "Denominations",
      dataIndex: "Denominations",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, __, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => remove(index)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  const handleAddDenomination = async (formData) => {
    const objcurrency = new CurrencyDenominationAssignment();
    objcurrency.Branch = branch;
    objcurrency.Currency = Currency;
    objcurrency.updatedUser = `{${userId?.id}}`;
    objcurrency.createdUser = `{${userId?.id}}`;
    objcurrency.guid = null;

    for (const row of formData.CurrencyDenominations) {
      const objcurrencydetail = new CurrencyDenomination();
      objcurrencydetail.Denomination = row.Denominations;
      objcurrencydetail.guid = null;
      objcurrency.CurrencyDenominations.push(objcurrencydetail);
    }
    try {
      const response = await SaveCurrencyDenomination(objcurrency);
      if (response.data == true) {
        toast.success("Success");
        closemodal();
        fields.forEach((_, index) => remove(index));
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <div>
      <div className="modal fade" id="denomination">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Denomination</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                    onClick={() => fields.forEach((_, index) => remove(index))}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleAddDenomination)}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Denomination</label>
                          <input
                            type="number"
                            step="any"
                            value={Dvalue}
                            onChange={(e) => setDvalue(e.target.value)}
                            placeholder="Denomination"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <button
                          className="btn btn-success"
                          onClick={handleAddDenom}
                          style={{ marginTop: "32px" }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="table-responsive product-list">
                        <Table
                          columns={columns}
                          dataSource={fields}
                          rowKey={(record, index) => index}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                          onClick={() =>
                            fields.forEach((_, index) => remove(index))
                          }
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCurrencDenom;
