import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ProductClass } from "../../../../core/json/ProductClass";
import {
  addNewProduct,
  getGroupEntryByGuid,
  getProductTypeguid,
  getUnitguid,
} from "../../../../services/MasterApiServices";
import toast from "react-hot-toast";

function AddOtherDescription({ register, errors }) {
  const { branch } = useSelector((state) => state.branchlist);
  const { product, productsection } = useSelector((state) => state.productdata);

  return (
    <div>
      <div>
        <div className="modal-body custom-modal-body">
          <div className="branch-tabs col-12 align-items-center justify-content-between">
            {Object.keys(errors || {})
              .map((key) => errors[key]?.message)
              .find(Boolean) && (
              <span className="error text-danger text-sm">
                {Object.keys(errors || {})
                  .map((key) => errors[key]?.message)
                  .find(Boolean)}
              </span>
            )}
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="col-auto input-blocks">
                <label className="col-form-label">Arabic Description</label>
              </div>
              <div className="col input-blocks mb-3">
                <input
                  type="text"
                  id=""
                  className="form-control"
                  {...register("arabicDescription")}
                />
              </div>
            </div>
            <div className=" col-md-6 col-sm-12">
              <div className="col-auto input-blocks ">
                <label className="col-form-label">HSN code</label>
              </div>
              <div className="col input-blocks mb-3">
                <input
                  type="text"
                  id=""
                  className="form-control"
                  {...register("hsnCode")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-12">
              <input
                type="checkbox"
                className=""
                {...register("isRateVaryProduct")}
              />{" "}
              Is product price variable?
            </div>
            <div className="col-lg-9  col-sm-12 general_decription">
              When this setting enabled, we can change the price of product
              while billing, a popup box will show in sales screen
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-12">
              <input
                type="checkbox"
                className=""
                {...register("isDailyStockItem")}
              />
              Daily Stock Item
            </div>
            <div className="col-lg-9  col-sm-12 general_decription">
              When this setting enabled, this product is considered as a stock
              product which will be shown in stock product
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-12">
              <input type="checkbox" className="" {...register("isMenuItem")} />
              Menu Item
            </div>
            <div className="col-lg-9  col-sm-12 general_decription">
              When this setting enabled, this product is considered as a Menu
              Item that shows in BOQ
            </div>
          </div>
          <div className="col-lg-12"></div>
        </div>
      </div>
    </div>
  );
}

export default AddOtherDescription;
