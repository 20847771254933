import React from 'react'
import expire from '../../core/img/expire.png'
import { useSelector } from 'react-redux';
import {AlertTriangle } from "feather-icons-react/build/IconComponents";
import { useNavigate } from 'react-router-dom';

const DefaultValueSettings = () => {
    const { branchsettings } = useSelector((state) => state.settings);
    const navigate = useNavigate()
  return (
    <div>
        {!branchsettings?.defaultFinancialYearGuID && !branchsettings?.defaultBankGuID && !branchsettings?.primaryCurrencyGUID
&&
    <div 
    onClick={()=>{navigate('/Administration/Branch/Index')}}
    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,paddingTop:"100px" , color:'#ff9f43',fontWeight:'bold', marginBottom:"-70px"}}>
      <AlertTriangle size={18}/> &nbsp; To please set primary currency financial year and bank to continue !
  </div>
        }
    </div>
  )
}

export default DefaultValueSettings