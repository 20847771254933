import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Link, useNavigate } from "react-router-dom";
import HorizontalSidebar from "./horizontalSidebar";
import CollapsedSidebar from "./collapsedSidebar";
import { useSelector } from "react-redux";

import * as Icon from "react-feather";
import AllBranchTransfer from "../../components/adminsitration/AllBranchTransfer";

const Sidebar = () => {
  const navigate = useNavigate();
  const { MenuServicelist } = useSelector((state) => state.menuservice);
  const [subOpen, setSubopen] = useState("");
  const [subsidebar, setSubsidebar] = useState("");
  const [mainsidebar, setmainsidebar] = useState("");
  const [branchModal, setbranchModal] = useState(false);

  const toggleMainSidebar = (title) => {
    if (title == mainsidebar) {
      setmainsidebar("");
    } else {
      setmainsidebar(title);
    }
  };
  const toggleSidebar = (id, title, url, child, click) => {
    if (click == "Branch Transfer") {
      setbranchModal(true);
    }
    if (child?.length > 0) {
      if (title == subOpen) {
        setSubopen("");
      } else {
        setSubopen(title);
      }
    } else {
      navigate(url);
    }
  };

  const toggleSubsidebar = (subitem, click) => {
    if (subitem == subsidebar) {
      setSubsidebar("");
    } else {
      setSubsidebar(subitem);
    }
  };

  return (
    <div>
      <div className="sidebar" id="sidebar">
        <Scrollbars>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                {MenuServicelist?.map((mainLabel, index) => (
                  <li className="submenu-open" key={index}>
                    <h6 className="submenu-hdr" style={{ cursor: "pointer" }}>
                      {" "}
                      <i
                        className={mainLabel?.icon}
                        aria-hidden="true"
                      ></i>{" "}
                      &nbsp;{mainLabel?.title}
                    </h6>
                    <ul>
                      {mainLabel?.children?.map((title, i) => {
                        //  let link_array = [];
                        //  title?.children?.map((link) => {
                        //    link_array.push(link?.link);
                        //    if (link?.submenu) {
                        //      link?.submenuItems?.map((item) => {
                        //        link_array.push(item?.url);
                        //      });
                        //    }
                        //    return link_array;
                        //  });
                        //  title.links = link_array;

                        const IconComponent = Icon[title.icon];
                        return (
                          <React.Fragment key={i}>
                            {" "}
                            <li
                            //  className={`submenu ${
                            //    !title?.submenu &&
                            //    Location.pathname === title?.link
                            //      ? "custom-active-hassubroute-false"
                            //      : ""
                            //  }`}
                            >
                              <Link
                                to={title?.url ? title?.url : "#"}
                                onClick={() =>
                                  toggleSidebar(
                                    title?.id,
                                    title?.title,
                                    title?.url,
                                    title.children,
                                    title.onClick
                                  )
                                }
                                //  style={{
                                //   display:
                                //     mainsidebar === mainLabel?.title ? "block" : "none",
                                // }}
                                className={`${
                                  subOpen === title?.title ? "subdrop" : ""
                                } ${
                                  title?.url?.includes(Location.pathname)
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {/* {title?.icon} */}

                                {/* <i className={title?.icon} aria-hidden="true"></i> &nbsp; */}
                                {IconComponent && <IconComponent />}
                                <span className="custom-active-span">
                                  {title?.title}
                                </span>
                                {title?.children[0] && (
                                  <span className="menu-arrow" />
                                )}
                              </Link>
                              <ul
                                style={{
                                  display:
                                    subOpen === title?.title ? "block" : "none",
                                }}
                                className={`${
                                  title?.url?.includes(Location.pathname)
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {title?.children?.map((item, titleIndex) => {
                                  const IconComponent1 = Icon[item.icon];
                                  return (
                                    <li
                                      className="submenu submenu-two"
                                      key={titleIndex}
                                    >
                                      <Link
                                        to={item?.url ? item?.url : "#"}
                                        //    item?.submenuItems
                                        //      ?.map((link) => link.link)
                                        //      .includes(Location.pathname) ||
                                        //    item?.link === Location.pathname
                                        //      ? "active"
                                        //      : ""
                                        //  } ${
                                        //    subsidebar === item?.label
                                        //      ? "subdrop"
                                        //      : ""
                                        //  }`}

                                        className={`${
                                          title?.url?.includes(
                                            Location.pathname
                                          )
                                            ? "active ml-3"
                                            : "ml-3"
                                        }`}
                                        onClick={() =>
                                          toggleSubsidebar(
                                            item?.title,
                                            item.onClick
                                          )
                                        }
                                      >
                                        {IconComponent1 && <IconComponent1 />}
                                        {item?.title}
                                        {item?.children && (
                                          <span className="menu-arrow inside-submenu" />
                                        )}
                                      </Link>
                                      <ul
                                        style={{
                                          display:
                                            subsidebar === item?.label
                                              ? "block"
                                              : "none",
                                        }}
                                      >
                                        {item?.children?.map(
                                          (items, subIndex) => {
                                            return (
                                              <li key={subIndex}>
                                                <Link
                                                  to={
                                                    items?.url
                                                      ? items?.url
                                                      : "#"
                                                  }
                                                >
                                                  {items?.title}
                                                </Link>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
      <HorizontalSidebar />
      <CollapsedSidebar />
      {branchModal && (
        <AllBranchTransfer handleClose={() => setbranchModal(false)} />
      )}
    </div>
  );
};

export default Sidebar;
