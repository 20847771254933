import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AdminTable from "../../components/adminsitration/admintables/AdminTable";
import CompanyUpdate from "../../components/adminsitration/CompanyUpdate";
import AdminProductTable from "../../components/adminsitration/admintables/AdminProductTable";
import { deletecompany } from "../../services/AdminApiServices";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
  Send,
} from "feather-icons-react/build/IconComponents";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { setheadertoggle } from "../../redux/toggle";
import { useForm } from "react-hook-form";
import LoadingUtils from "../../core/utils/LoadingUtils";
import { fetchCompanyDetail } from "../../redux/companyDetail";
const Company = () => {
  const { companyInfolist, loading } = useSelector(
    (state) => state.companyInfo
  );
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    setValue,
    setError,
    values,
    reset,
    watch,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
    },
    {
      title: "Address 1",
      dataIndex: "address1",
    },
    {
      title: "Address 2",
      dataIndex: "address2",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Website",
      dataIndex: "website",
    },
    // {
    //   title: "Actions",
    //   dataIndex: "actions",
    //   key: "actions",
    //   render: (_, obj) => (
    //     <div className="action-table-data">
    //       <div className="edit-delete-action">
    //         <Link className="me-2 p-2"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit-company"
    //           onClick={() => handleEditClick(obj.guid)}
    //         >
    //           <i data-feather="edit" className="feather-edit"></i>
    //         </Link>
    //         <Link className="confirm-text p-2"
    //           to="#"
    //           onClick={() => handleDeleteClick(obj.id)}
    //         >
    //           <i data-feather="trash-2" className="feather-trash-2"></i>
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const handleEditClick = (id) => {};

  const MySwal = withReactContent(Swal);
  const handleDeleteClick = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deletecompany(id);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The User has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting Reasontype:", error);
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong.",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const { togglehead } = useSelector((state) => state.headerToggle);

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  useEffect(() => {
    setValue("company", companyInfolist[0]);
  }, [companyInfolist]);
  return (
    <div>
      <LoadingUtils loading={loading} />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Company</h4>
              </div>
            </div>
            <ul className="table-top-head">
              {/* <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li> */}
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => dispatch(fetchCompanyDetail())}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              {/* <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line
                          x1={21}
                          y1={21}
                          x2="16.65"
                          y2="16.65"
                        />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>

              </div> */}

              <div className="table-responsive product-list">
                {/* <AdminProductTable columns={columns} dataSource={modifiedList} /> */}
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-blocks">
                      <label>Name</label>
                      <input
                        type="text"
                        id="name"
                        {...register("company.name")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-blocks">
                      <label>Full Name</label>
                      <input
                        type="text"
                        id="name"
                        {...register("company.fullName")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-blocks d-flex mt-lg-4">
                      <input
                        type="checkbox"
                        id="name"
                        disabled
                        {...register("company.isMultiBranchEnabled")}
                      />
                      <label className="m-2">Multi Branch Enable</label>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-blocks">
                      <label>Address 1</label>
                      <input
                        type="text"
                        id="name"
                        {...register("company.address1")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-blocks">
                      <label>Address 2</label>
                      <input
                        type="text"
                        id="name"
                        {...register("company.address2")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-blocks">
                      <label>Address 3</label>
                      <input
                        disabled
                        type="text"
                        id="name"
                        {...register("company.address3")}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-blocks">
                      <label>Phone</label>
                      <input
                        type="text"
                        id="name"
                        {...register("company.phone")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-blocks">
                      <label>Email</label>
                      <input
                        type="text"
                        id="name"
                        {...register("company.email", {
                          pattern:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                        disabled
                      />
                      {errors?.company?.email?.type === "pattern" && (
                        <span className="text-danger">Enter valid email</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-blocks">
                      <label>Website</label>
                      <input
                        type="text"
                        disabled
                        id="name"
                        {...register("company.website", {
                          pattern:
                            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/,
                        })}
                      />
                      {errors?.company?.website?.type === "pattern" && (
                        <span className="text-danger">Enter valid website</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-blocks">
                      <label>Tax No</label>
                      <input
                        type="text"
                        id="name"
                        {...register("company.tin")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-blocks">
                      <label>Sales Tax No</label>
                      <input
                        type="text"
                        id="name"
                        {...register("company.cst")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="input-blocks">
                      <label>Location</label>
                      <input
                        type="text"
                        id="name"
                        {...register("company.locationName")}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CompanyUpdate />
    </div>
  );
};

export default Company;
