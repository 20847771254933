import React, { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { TaxClass } from "../../../core/json/TaxClass";
import { PromoProductsClass } from "../../../core/json/PromoProductsClass";
import toast from "react-hot-toast";
import { editPromoProduct } from "../../../services/MasterApiServices";

const EditPromoProduct = ({ data, Promotionlist, handlePromoProductList }) => {
  const { register, handleSubmit, setValue, getValues, watch, reset, control } =
    useForm({
      mode: "onChange",
      reValidateMode: "onChange",
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "promoProductDetail",
  });
  const [Data, setData] = useState([]);

  const { sectionList, productList } = useSelector(
    (state) => state.usersetting
  );
  const { userId } = useSelector((state) => state.userauth);
  const { branch } = useSelector((state) => state.branchlist);

  function resetmodal() {
    if (data) {
      setValue("promotion", data?.promotion?.guid);
      setValue("section", data?.section?.guid);
      setValue(
        "promoProductDetail",
        data?.promoProductsDetail?.map((obj, i) => ({
          product: obj?.product?.guid,
          rate: obj?.price,
        }))
      ); // Populate form with data for editing
    } else {
      reset();
    }
  }

  useEffect(() => {
    resetmodal();
  }, [data, setValue]);

  const handleAddPromo = () => {
    append({ product: "", rate: "" });
  };
  const handleUpdatePromoProduct = async (formData) => {
    const objtax = new TaxClass();
    const ProductDetail = formData?.promoProductDetail?.map((e) => ({
      product: productList?.find((item) => item.guid == e.product),
      price: e.rate,
      tax: objtax,
    }));
    const objpromoproduct = new PromoProductsClass();
    objpromoproduct.promotion = Promotionlist?.find(
      (item) => item.guid == getValues().promotion
    );
    objpromoproduct.branch = branch;
    objpromoproduct.guid = data.guid;
    objpromoproduct.id = data.id;
    objpromoproduct.section = sectionList?.find(
      (item) => item.guid == getValues().section
    );
    objpromoproduct.promoProductsDetail = ProductDetail;
    objpromoproduct.updatedUser = userId.id;
    objpromoproduct.createdUser = userId.id;
    try {
      const response = await editPromoProduct(objpromoproduct);
      if (response.data == true) {
        closemodal();
        handlePromoProductList();
        toast.success("success");
      }
    } catch (error) {
      console.log();
    }
  };
  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <div>
      <div className="modal fade" id="edit-promo-product">
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Promo Products</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleUpdatePromoProduct)}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Promotion name</label>
                          {/* <Select
                            options={productlist}
                            classNamePrefix="react-select"
                            placeholder="Choose"
                          /> */}
                          <select
                            className="form-control"
                            {...register("promotion")}
                          >
                            <option value="">--select--</option>
                            {Promotionlist?.map((e, i) => {
                              return (
                                <option value={e.guid} key={i}>
                                  {e.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Section</label>
                          <select
                            className="form-control"
                            {...register("section")}
                          >
                            <option value="">--select--</option>
                            {sectionList?.map((e, i) => {
                              return (
                                <option value={e.guid} key={i}>
                                  {e.name}
                                </option>
                              );
                            })}
                          </select>
                          {/* <Select
                            options={productlist}
                            classNamePrefix="react-select"
                            placeholder="Choose"
                          /> */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4">Product</div>
                      <div className="col-4">Rate</div>
                      <div className="col-4">Action</div>
                    </div>
                    {fields.map((obj, index) => {
                      return (
                        <>
                          <hr />
                          <div className="row">
                            <div className="col-4">
                              <div className="input-blocks">
                                <select
                                  className="form-control"
                                  {...register(
                                    `promoProductDetail[${index}].product`
                                  )}
                                >
                                  <option value="">--select--</option>
                                  {productList?.map((e, i) => {
                                    return (
                                      <option value={e.guid} key={i}>
                                        {e.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="input-blocks">
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register(
                                    `promoProductDetail[${index}].rate`
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="edit-delete-action">
                                <Link
                                  className="confirm-text p-2"
                                  to="#"
                                  // onClick={() => handleDelete(index)}
                                  onClick={() => remove(index)}
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  ></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}

                    <button
                      className="btn btn-success mt-4"
                      onClick={handleAddPromo}
                      type="button"
                    >
                      Add
                    </button>
                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                        >
                          Reset
                        </button>
                        <button type="submit" to="#" className="btn btn-submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Purchase */}
    </div>
  );
};

export default EditPromoProduct;
