import { useEffect } from "react";

const EnterKeyUtils = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the Enter key was pressed
      if (event.key === "Enter") {
        const formElements = [
          ...document.querySelectorAll(
            'input, select, textarea, button, [tabindex]:not([tabindex="-1"])'
          ),
        ];

        // Find the current focused element
        const index = formElements.indexOf(document.activeElement);

        // If Enter is pressed on an input, move focus to the next enabled element
        if (index > -1 && !event.shiftKey) {
          let nextElement = formElements[index + 1];

          // Loop until the next element is enabled or there are no more elements
          while (nextElement && nextElement.disabled) {
            nextElement = formElements[formElements.indexOf(nextElement) + 1];
          }

          // Focus the next enabled element
          if (nextElement) {
            event.preventDefault(); // Prevent default Enter key action
            nextElement.focus(); // Focus the next enabled element
          }
        }
      }
    };

    // Attach the event listener
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
};

export default EnterKeyUtils;
