import React, { useEffect, useState } from "react";
import { getSalesReport } from "../../services/ReportApiService";
import { RefreshCw } from "feather-icons-react/build/IconComponents";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import Table from "../../core/pagination/datatable";
function PreOverSalesReport() {
  const [saleData, setSaleData] = useState([]);
  useEffect(() => {
    handleSaleReportList();
  }, []);

  const handleSaleReportList = async () => {
    try {
      const response = await getSalesReport();
      setSaleData(response);
    } catch (error) {
      console.log();
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.Name?.length - b.Name?.length,
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "stock Qty",
      dataIndex: "stockQty",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },

    {
      title: "Sales Qty",
      dataIndex: "salesQty",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "PreOver Qty",
      dataIndex: "preOverQty",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Sales Prtice",
      dataIndex: "salePrice",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Total Sale Price",
      dataIndex: "paymentType",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Cost Price",
      dataIndex: "costPrice",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Total",
      dataIndex: "total",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>PreOver Sale Report</h4>
              </div>
            </div>
            <ul className="table-top-head"></ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn"></div>
              <div className="page-btn import">
                <Link
                  to="#"
                  className="btn btn-added color"
                  data-bs-toggle="modal"
                  data-bs-target="#view-notes"
                >
                  <RefreshCw className="me-2" />
                  Refresh
                </Link>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              {/* /Filter */}

              {/* /Filter */}
              <div className="table-responsive product-list">
                <Table columns={columns} dataSource={saleData} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <Pagination />
    </div>
  );
}

export default PreOverSalesReport;
