import axios from "axios";

// const base_path = getBaseUrl();
const base_path = window.APP_CONFIG?.BASE_URL;
// const base_path = process.env.REACT_APP_API_URL;

//---------stock adjustment apis-----------------

export const getStockByBranch = async (branch) => {
  const response = axios.get(
    `${base_path}/Api/InvStockAdjustment/GetAllByBranchID?globalBranchID=${branch?.guid}`
  );
  return response;
};

// Create a new or edit stock adjustment
export const addEditStock = async (stockData) => {
  const response = await axios.post(
    `${base_path}/Api/InvStockAdjustment/SaveOrUpdate`,
    JSON.stringify(stockData)
  );
  return response;
};

//delete stock adjustment
export const deleteStock = async (stockData) => {
  const response = await axios.post(
    `${base_path}/Api/InvStockAdjustment/Delete`,
    stockData
  );
  return response;
};
//---------------stock transfer----------------
export const getStockTransferByBranch = async (branch) => {
  const response = await axios.get(
    `${base_path}/Api/InvStockTransfer/GetAllByBranchID?globalBranchID=${branch?.guid}`
  );

  return response.data;
};
export const addEditStockTransfer = async (stockData) => {
  const response = await axios.post(
    `${base_path}/Api/InvStockTransfer/SaveOrUpdate`,
    JSON.stringify(stockData)
  );
  return response;
};

export const deleteStockTransfer = async (stockData) => {
  const response = await axios.post(
    `${base_path}/Api/InvStockTransfer/Delete`,
    stockData
  );
  return response;
};
//--------------production -------------------

export const getproductionbybranch = async (branch) => {
  const response = axios.get(
    `${base_path}/Api/InvProduction/GetAllByBranchID?globalBranchID=${branch?.guid}`
  );
  return response;
};
export const saveproduction = async (data) => {
  const response = await axios.post(
    `${base_path}/Api/InvProduction/SaveOrUpdate`,
    data
  );
  return response.data;
};
