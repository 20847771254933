import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Download,
  File,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
// import { setToogleHeader } from "../../core/redux/action";
// import { useDispatch, useSelector } from "react-redux";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable";
import { useSelector } from "react-redux";
import { getSalesByTransType } from "../../../services/SalesApiServices";
import * as XLSX from "xlsx"; // for excel export
import jsPDF from "jspdf"; // for pdf generation
import moment from "moment";

const SalesSupplierReturn = ({ data, supplierColumns }) => {
  const [searchText, setSearchText] = useState("");
  const [salesData, setsalesData] = useState("");
  const { branch } = useSelector((state) => state.branchlist);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    handlesalesData();
  }, [branch]);

  const handlesalesData = async () => {
    try {
      const response = await getSalesByTransType(branch, 3);

      setsalesData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error(
        "Failed to fetch sales invoices",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  // const filteredSalesData = salesData?.filter(item => {
  //   const customerName = item.customerName.toLowerCase();
  //   return customerName.includes(searchText.toLowerCase());
  // });

  // const dispatch = useDispatch();
  // const data = useSelector((state) => state.toggle_header);

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <>
      <div id="salesTable">
        <Table columns={supplierColumns} dataSource={data} />
      </div>
    </>
  );
};

export default SalesSupplierReturn;
